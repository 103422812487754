import { useTranslation } from "react-i18next";
import "./ReviewSummary.scss";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { useContext, useEffect } from "react";
import { systemPreferenceContext } from "../../../../../context/SystemPreference/SystemPreference";

const ReviewSummary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { preferenceData } = useContext(systemPreferenceContext);
  // Run this effect whenever the session storage is updated
  const questionCount = getSessionStorageItem("totalQuestionCount");
  // Check if the items exist before accessing their properties
  const questionCounted = questionCount > 0 ? questionCount : 0;
  useEffect(() => {}, [questionCounted]);

  return (
    <>
      <div className="totalQuesSet">
        <div>
          <h5>{t("CREATE_ASSESSMENT.TOTAL_QUESTION")}</h5>
          <p className="boldText">
            {getSessionStorageItem("totalQuestionCount") ?? 0}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.EXPERIENCE_YEAR")}</h5>
          <p>
            <b className="minMax">{t("GENERAL.MIN")}</b> :&nbsp;
            <span className="boldText">
              {getSessionStorageItem("minimumExperience") === null
                ? preferenceData?.createAssessment?.minimumExperience
                : getSessionStorageItem("minimumExperience")}
            </span>
          </p>
          <p>
            <b className="minMax">{t("GENERAL.MAX")}</b> :&nbsp;
            <span className="boldText">
              {getSessionStorageItem("maximumExperience") ||
                preferenceData?.createAssessment?.maximumExperience}
            </span>
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.POSITION")}</h5>
          <p className="boldText">
            {getSessionStorageItem("noOfPositions") ||
              preferenceData?.createAssessment?.noOfPositions}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.CUTOFF_PERCENTAGE")}</h5>
          <p className="boldText">
            {getSessionStorageItem("cutOff") ||
              preferenceData?.createAssessment?.cutOff}
            %
          </p>
        </div>

        <div>
          <h5>{t("CREATE_ASSESSMENT.MAXIMUM_RESPONSES")}</h5>
          <p className="boldText">
            {getSessionStorageItem("maximumResponse") ||
              preferenceData?.createAssessment?.limitResponses}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.DURATION")}</h5>
          <p className="boldText">
            {getSessionStorageItem("totalQuestionTime") ?? 0}{" "}
            {t("GENERAL.MINS")}
          </p>
        </div>
      </div>
      <div className="horizontalLine"></div>
    </>
  );
};

export default ReviewSummary;
