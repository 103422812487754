const ProfileIcon = () => {
  return (
    <svg
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.76744 1.11628C4.22618 1.11628 2.97674 2.36572 2.97674 3.90698C2.97674 5.44824 4.22618 6.69767 5.76744 6.69767C7.30873 6.69767 8.55814 5.44824 8.55814 3.90698C8.55814 2.36572 7.30873 1.11628 5.76744 1.11628ZM1.86047 3.90698C1.86047 1.74921 3.60967 0 5.76744 0C7.92521 0 9.67442 1.74921 9.67442 3.90698C9.67442 6.06474 7.92521 7.81395 5.76744 7.81395C3.60967 7.81395 1.86047 6.06474 1.86047 3.90698Z"
        fill="#5A5A5A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.8903C0 10.7033 1.77302 8.93023 3.96013 8.93023H7.57477C9.76186 8.93023 11.5349 10.7033 11.5349 12.8903C11.5349 14.6078 10.1427 16 8.42523 16H3.10963C1.39223 16 0 14.6078 0 12.8903ZM3.96013 10.0465C2.38951 10.0465 1.11628 11.3197 1.11628 12.8903C1.11628 13.9913 2.00874 14.8837 3.10963 14.8837H8.42523C9.52618 14.8837 10.4186 13.9913 10.4186 12.8903C10.4186 11.3197 9.14538 10.0465 7.57477 10.0465H3.96013Z"
        fill="#5A5A5A"
      />
    </svg>
  );
};

export default ProfileIcon;
