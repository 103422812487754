import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  checkUniqueIdMatch,
  determinePageType,
  editedformData,
  getAssessmentPath,
  initialScopeData,
} from "../../../../utils/Common/Constant";
import Head from "../AssessmentScope/Head/Head";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { Form, Formik } from "formik";
import {
  createAssessmentDraftJson,
  getCreateAssessmentDraftJson,
} from "../../../../service/DraftJson/DraftJson";
import { assessmentDraftJson } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import { useEffect, useRef, useState } from "react";
import { getSessionStorageItem } from "../../../../utils/SessionStorageMethod/getSessionStorageItem";
import TemplateBottomLeftSide from "../AssessmentScope/TemplateBottomLeftSide/TemplateBottomLeftSide";
import TemplateBottomRightSide from "../AssessmentScope/TemplateBottomRightSide/TemplateBottomRightSide";
import { checkKeysInSessionStorage } from "../../../../utils/Common/checkKeysInSessionStorage";
import SetDraftJsonInSession from "../../AssessmentDraftJson/SetDraftJsonInSession";
import { getAssessmentDetailsByAssessementId } from "../../../../service/Scope/Scope";
const defaultAnswerOption = {
  Single: [{ id: "one", label: "", selected: true, image: null }],
  Multiple: [{ id: "checkbox one", label: "", selected: true }],
  Essay: "",
  Video: "",
};
const AssessmentCustom = () => {
  const { tempUniqueId } = useParams();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const scrollRef = useRef<HTMLDivElement>(null);

  const editedParams = new URLSearchParams(location.search).get("edited");

  // Get current language
  const currentLanguage = i18n.language;
  const activeStep = determinePageType(pathname);
  const [textEditorQuestion, setTextEditorQuestion] = useState("");
  const [attachedQuestionImage, setAttachedQuestionImage] = useState();
  const [answerOptionState, answerOptionSetState] = useState(
    getSessionStorageItem("answerOptionState") || defaultAnswerOption,
  );
  const [createdQuestionData, setCreatedQuestionData] = useState<any>(
    getSessionStorageItem("createQuestionData") || [],
  );
  const [selectedType, setSelectedType] = useState({ id: "", type: "Single" }); // create question ans type
  const [timeLimit, setTimeLimit] = useState(2); // create question time
  const [isQuestionAnsEdited, setIsQuestionAnsEdited] = useState(false);
  const userId = localStorage.getItem("companyId") ?? "";
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  useEffect(() => {
    const isKeyPresent = checkKeysInSessionStorage(
      [
        "existingPreview",
        "existingData",
        "titleHeadContent",
        "totalQuestionTime",
        "ownPreview",
      ],
      "any",
    );
    if (
      (!isKeyPresent && !editedParams) ||
      checkUniqueIdMatch(tempUniqueId, tempUniqueIdBySession)
    ) {
      // api call to set store value in session
      SetDraftJsonInSession(userId, tempUniqueId, pathname);
    }
  }, []);
  // edited assessment work flow
  useEffect(() => {
    (async () => {
      if (editedParams) {
        const draftRes = await getCreateAssessmentDraftJson(
          userId,
          tempUniqueId,
        );
        if (
          Object.keys(draftRes?.data?.data).length > 0 &&
          draftRes?.data?.data?.createAssessmentJson?.custom
            ?.AssessmentCustomQuestions
        ) {
          setCreatedQuestionData(
            draftRes?.data?.data?.createAssessmentJson?.custom
              ?.AssessmentCustomQuestions,
          );
          setSessionStorageItem(
            "createQuestionData",
            draftRes?.data?.data?.createAssessmentJson?.custom
              ?.AssessmentCustomQuestions,
          );
        } else {
          const editedData = await getAssessmentDetailsByAssessementId(
            tempUniqueId ?? "",
          );
          setCreatedQuestionData(editedData?.data?.ssessmentCustomQuestions);
          setSessionStorageItem(
            "createQuestionData",
            editedData?.data?.ssessmentCustomQuestions,
          );
          editedformData(editedData);
        }
      }
    })();
  }, []);
  // to move to next page
  const handleNext = async (count: number) => {
    let nextStep;
    if (count != null && count != undefined && typeof count === "number") {
      nextStep = activeStep < 5 ? count : activeStep;
    } else {
      nextStep = activeStep < 5 ? activeStep + 1 : activeStep;
    }
    if (activeStep < count) {
      await createAssessmentDraftJson(assessmentDraftJson(tempUniqueId));
    }
    setSessionStorageItem("currentStep", nextStep);
    navigate(
      getAssessmentPath(nextStep, tempUniqueId, currentLanguage, editedParams),
    );
  };
  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      setSessionStorageItem("currentStep", count);
      navigate(
        getAssessmentPath(count, tempUniqueId, currentLanguage, editedParams),
      );
    } else {
      const prevStep = activeStep > 1 ? activeStep - 1 : activeStep;
      setSessionStorageItem("currentStep", prevStep);
      navigate(
        getAssessmentPath(
          prevStep,
          tempUniqueId,
          currentLanguage,
          editedParams,
        ),
      );
    }
  };
  return (
    <>
      <Formik
        initialValues={initialScopeData}
        onSubmit={() => {
          // handleNext();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handlePrevious={handlePrevious}
                    handleNext={handleNext}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
              </div>
              <div className="textareaSec" ref={scrollRef}>
                <div className="row">
                  <div className="col-md-6">
                    <TemplateBottomLeftSide
                      textEditorQuestion={textEditorQuestion}
                      setTextEditorQuestion={setTextEditorQuestion}
                      attachedQuestionImage={attachedQuestionImage}
                      setAttachedQuestionImage={setAttachedQuestionImage}
                      answerOptionState={answerOptionState}
                      answerOptionSetState={answerOptionSetState}
                      setCreatedQuestionData={setCreatedQuestionData}
                      defaultAnswerOption={defaultAnswerOption}
                      selectedType={selectedType}
                      setSelectedType={setSelectedType}
                      timeLimit={timeLimit}
                      setTimeLimit={setTimeLimit}
                      isQuestionAnsEdited={isQuestionAnsEdited}
                      setIsQuestionAnsEdited={setIsQuestionAnsEdited}
                    />
                  </div>
                  <div className="col-md-6">
                    <TemplateBottomRightSide
                      createdQuestionData={createdQuestionData}
                      setCreatedQuestionData={setCreatedQuestionData}
                      attachedQuestionImage={attachedQuestionImage}
                      setAttachedQuestionImage={setAttachedQuestionImage}
                      setTextEditorQuestion={setTextEditorQuestion}
                      answerOptionSetState={answerOptionSetState}
                      defaultAnswerOption={defaultAnswerOption}
                      setSelectedType={setSelectedType}
                      setTimeLimit={setTimeLimit}
                      setIsQuestionAnsEdited={setIsQuestionAnsEdited}
                      scrollRef={scrollRef}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentCustom;
