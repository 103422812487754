const PhoneIcon = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.73585 1.03896C1.7459 1.03896 0.943396 1.92276 0.943396 3.01299V12.987C0.943396 14.0772 1.7459 14.961 2.73585 14.961H7.26415C8.2541 14.961 9.0566 14.0772 9.0566 12.987V3.01299C9.0566 1.92276 8.2541 1.03896 7.26415 1.03896H2.73585ZM0 3.01299C0 1.34896 1.22488 0 2.73585 0H7.26415C8.77512 0 10 1.34896 10 3.01299V12.987C10 14.651 8.77512 16 7.26415 16H2.73585C1.22488 16 0 14.651 0 12.987V3.01299Z"
        fill="black"
      />
      <path
        d="M5 13.8182C4.79985 13.8182 4.60785 13.7306 4.46634 13.5748C4.32483 13.4189 4.24528 13.2075 4.24528 12.9871C4.24528 12.8227 4.28951 12.662 4.37245 12.5253C4.4554 12.3886 4.57328 12.2821 4.71117 12.2192C4.84906 12.1562 5.00083 12.1398 5.14725 12.1719C5.29366 12.2039 5.42815 12.2831 5.53366 12.3993C5.63925 12.5155 5.71109 12.6637 5.74023 12.8249C5.76936 12.9862 5.75441 13.1533 5.69728 13.3052C5.64015 13.457 5.5434 13.5868 5.41932 13.6782C5.29517 13.7695 5.14928 13.8182 5 13.8182Z"
        fill="black"
      />
    </svg>
  );
};

export default PhoneIcon;
