import React from "react";

const UserImage = () => {
  return (
    <svg
      width="120"
      height="149"
      viewBox="0 0 120 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M60 59.6C76.5685 59.6 90 46.2581 90 29.8C90 13.3419 76.5685 0 60 0C43.4315 0 30 13.3419 30 29.8C30 46.2581 43.4315 59.6 60 59.6Z"
        fill="#D9D9D9"
      />
      <path
        d="M120 115.475C120 133.99 120 149 60 149C0 149 0 133.99 0 115.475C0 96.9595 26.8629 81.95 60 81.95C93.1373 81.95 120 96.9595 120 115.475Z"
        fill="#D9D9D9"
      />
    </svg>
  );
};

export default UserImage;
