import { useTranslation } from "react-i18next";
import "./CreateProfile.scss";
import "react-phone-input-2/lib/bootstrap.css";
import {
  AssessmentButton,
  InputField,
  SearchTypeSelect,
  showToast,
  useOnlineStatus,
} from "ui-components";
import ProfilePlaceholderIcon from "../../../../utils/Images/ProfilePlaceholderIcon";
import LinkedinIcon from "../../../../utils/Images/LinkedinIcon";
import TwitterIcon from "../../../../utils/Images/TwitterIcon";
import FacebookIcon from "../../../../utils/Images/FacebookIcon";
import InstagramIcon from "../../../../utils/Images/InstagramIcon";
import { Formik, Form, Field } from "formik";
import {
  completeProfileApi,
  getCompanyProfileDetails,
  getIndustries,
} from "../../../../service/Scope/Scope";
import {
  capitalizeFirstLetter,
  completeProfileData,
  refKeys,
} from "../../../../utils/Common/Constant";
import { useEffect, useRef, useState } from "react";
import ImageCropPopup from "../../../../components/CropModal/CropModal";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../../../../i18nConfig";
import { ImageUploader } from "../../../../utils/Common/MediaUploader";
import { videoUploader } from "../../../../utils/Common/VideoUploader";
import useDebounce from "../../../../utils/Hooks/useDebounce";
import ChooseImageIcon from "../../../../utils/Images/ChooseImageIcon";
import { signupUserSchema } from "./SignupUserSchema";
import {
  countries,
  states,
} from "../../../../utils/CountriesStatesData/CountriesStatesData";
import Footer from "../../../../components/Footer/Footer";
import Header from "../../../../components/Assessment/Header/Header";
// import Poppers from "../../../../components/Poppers/Poppers";
const CreateProfileScreen = () => {
  const { t } = useTranslation();
  const [editDetails, setEditDetails] = useState<any>(null);
  const [industryList, setIndustryList] = useState<any>();
  const companyEmail: any = localStorage.getItem("email");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [, setUploadedImg] = useState<any>("");
  const splitUrl = editDetails?.logo.split("/profile/");
  const profileString = splitUrl?.length > 1 ? `profile/${splitUrl[1]}` : "";
  const [croppedUploadedImg, setCroppedUploadedImg] = useState<any>(
    editDetails?.logo || "",
  );
  const [displayNameEdit, SetDisplayNameEdit] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const editProfile = location?.state?.editProfile;
  const mediaPath = localStorage.getItem("mediaPath");
  const currentLanguage = i18n.language;
  const companyUserId = localStorage.getItem("companyUserId");
  const isOnline = useOnlineStatus();
  const websiteUrlLen = "100";
  const socialLinksLen = "250";
  const defaultCountry = "us";
  const getProfileDetails = async () => {
    const res = await getCompanyProfileDetails(companyUserId);
    setEditDetails(res?.data?.data);
  };
  useEffect(() => {
    if (editProfile) {
      getProfileDetails();
    }
  }, []);
  const handleApplicantSignup = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    localStorage.setItem(
      "companyAddress",
      value?.address
        ? value?.address
        : `${value?.country}, ${value?.state}, ${value?.city}`,
    );
    const data = {
      industries: value?.industryType,
      companyUserId: localStorage.getItem("companyUserId"),
      companyId: localStorage.getItem("companyId"),
      companyName: value?.companyName.trim(),
      displayName: value?.displayName.trim(),
      website: value?.websiteUrl,
      companyLogo: croppedUploadedImg?.fileName || croppedUploadedImg,
      companyEmail: companyEmail,
      companyMobile: value?.mobile,
      zipCode: value?.zipcode?.trim(),
      country: value?.country,
      state: value?.state,
      city: value?.city,
      instagramURL: value?.instagram,
      linkedInURL: value?.linkedin,
      facebookURL: value?.facebook,
      twitterURL: value?.twitter,
      address: value?.address,
      contactPersonDetails: {
        firstName: value?.ownerFirstName.trim(),
        lastName: value?.ownerLastName.trim(),
        mobile: value?.ownerMobile,
        email: value?.ownerEmail?.toLowerCase(),
        designation: value?.ownerDesignation.trim(),
      },
      isProfileExist: editProfile,
    };
    try {
      setSubmitting && setSubmitting(true);
      const getCompanyOtp = await completeProfileApi(data);
      if (getCompanyOtp?.status === 200) {
        showToast(getCompanyOtp?.customMessage, "success");
        resetForm && resetForm();
        localStorage.setItem("industryId", value?.industryType);
        navigate(`/${currentLanguage}/assessment/list`, {
          state: { showPoppers: editProfile ? false : true },
        });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting && setSubmitting(false);
    }
  };
  const getIndustryType = async (search: string) => {
    try {
      const industries: any = await getIndustries(search);
      if (industries?.status === 200) {
        const updateData = industries?.data
          ?.filter((item: any) => item?.isActive)
          ?.map((item: any) => ({
            value: item?.industriesId,
            label: item?.name,
          }));

        setIndustryList(updateData);
      }
    } catch (error) {
      console.error("Error in getting industru type");
    }
  };
  const handleSelectCompanyLogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e && e.target && e.target.files && e.target.files[0];
    if (file) {
      setFileInfo(file);
      setIsCropping(true);
      const ImageData = await ImageUploader(e, `${mediaPath + file?.name}`);
      setUploadedImg(ImageData);
    }
  };
  const handleCropComplete = async (croppedFile: Blob) => {
    const croppedUrl = URL.createObjectURL(croppedFile);
    const imageData = await videoUploader(
      croppedFile,
      `${mediaPath}croppedImage${Math.random() * 10}${fileInfo?.name}`,
    );
    setCroppedUploadedImg(imageData);
    setCroppedImageUrl(croppedUrl);
    setIsCropping(false);
  };
  return (
    <div className={`container-fluid main-container ${!isOnline && "mt-5"}`}>
      <div className="internet-banner">
        <div className={`banner ${!isOnline ? "show zIndexTop" : ""}`}>
          {t("GENERAL.INTERNET_CONNECTION")}
        </div>
      </div>
      <Header />
      <Formik
        initialValues={completeProfileData}
        validationSchema={signupUserSchema(t)}
        onSubmit={(values, { setSubmitting, setValues }) => {
          setValues(values);
          setSubmitting(false);
        }}
        validateOnMount
      >
        {({
          errors,
          touched,
          values,
          setFieldValue,
          isSubmitting,
          validateForm,
          setTouched,
          resetForm,
          setSubmitting,
        }) => {
          //setting data when it comes to edit
          const setProfileData = () => {
            setFieldValue("logo", editDetails?.logo);
            setFieldValue("companyName", editDetails?.companyName);
            setFieldValue("displayName", editDetails?.displayName);
            setFieldValue("industryType", editDetails?.industries);
            setSkillIdFromSelect({
              value: editDetails?.industries,
              label: editDetails?.["industry.name"],
            });
            setFieldValue("websiteUrl", editDetails?.website);
            setFieldValue("mobile", editDetails?.mobile);
            setFieldValue("address", editDetails?.address);
            setFieldValue("country", editDetails?.country);
            setCountryName({
              value: editDetails?.country,
              label: editDetails?.country,
            });
            setFieldValue("state", editDetails?.state);
            setStateName({
              value: editDetails?.state,
              label: editDetails?.state,
            });
            setFieldValue("city", editDetails?.city);
            setFieldValue("zipcode", editDetails?.zipCode);
            setFieldValue("linkedin", editDetails?.linkedIn);
            setFieldValue("twitter", editDetails?.twitter);
            setFieldValue("facebook", editDetails?.facebook);
            setFieldValue("instagram", editDetails?.instagram);
            setFieldValue(
              "ownerFirstName",
              editDetails?.contactPersonDetails?.firstName,
            );
            setFieldValue(
              "ownerLastName",
              editDetails?.contactPersonDetails?.lastName,
            );
            setFieldValue(
              "ownerDesignation",
              editDetails?.contactPersonDetails?.designation,
            );
            setFieldValue(
              "ownerEmail",
              editDetails?.contactPersonDetails?.email,
            );
            setFieldValue(
              "ownerMobile",
              editDetails?.contactPersonDetails?.mobile,
            );
            setCroppedUploadedImg(profileString);
          };
          useEffect(() => {
            editDetails !== null && setProfileData();
          }, [editDetails]);
          const defaultSelectSkills = { value: "", label: "" };
          const [skillIdFromSelect, setSkillIdFromSelect] =
            useState<any>(defaultSelectSkills);
          const debounceSkillSearchValue = useDebounce(
            skillIdFromSelect?.label?.trim(),
            400,
          );
          const defaultCountryName = { value: "", label: "" };
          const [countryName, setCountryName] =
            useState<any>(defaultCountryName);
          const defaultStateName = { value: "", label: "" };
          const [stateName, setStateName] = useState<any>(defaultStateName);
          useEffect(() => {
            if (!countryName?.value) {
              setFieldValue("country", "");
              setFieldValue("state", "");
              setStateName(defaultStateName);
            } else if (!stateName?.value) {
              setFieldValue("state", "");
            } else if (!skillIdFromSelect?.value) {
              setFieldValue("industryType", "");
            }
          }, [countryName.value, stateName.value, skillIdFromSelect.value]);
          useEffect(() => {
            getIndustryType(skillIdFromSelect?.label);
          }, [debounceSkillSearchValue]);
          // Create refs dynamically using a refKeys array
          const refs = refKeys.reduce(
            (acc, key) => {
              acc[key] = useRef<any>(null);
              return acc;
            },
            {} as Record<string, React.RefObject<any>>,
          );
          const scrollToError = (errors: any) => {
            for (const key of refKeys) {
              if (errors[key] && refs[key]?.current) {
                refs[key].current.scrollIntoView({ behavior: "smooth" });
                const offset = 50;
                const elementPosition =
                  refs[key].current.getBoundingClientRect().top +
                  window.scrollY;
                const offsetPosition = elementPosition - offset;
                window.scrollTo({
                  top: offsetPosition,
                  behavior: "smooth",
                });
                return;
              }
            }
          };
          return (
            <Form
              onSubmit={async (e) => {
                e.preventDefault();
                const formErrors = await validateForm();
                setTouched({
                  logo: true,
                  companyName: true,
                  industryType: true,
                  displayName: true,
                  websiteUrl: true,
                  mobile: true,
                  address: true,
                  country: true,
                  state: true,
                  city: true,
                  zipcode: true,
                  linkedin: true,
                  twitter: true,
                  facebook: true,
                  instagram: true,
                  ownerFirstName: true,
                  ownerLastName: true,
                  ownerDesignation: true,
                  ownerEmail: true,
                  ownerMobile: true,
                });
                if (Object.keys(formErrors).length > 0) {
                  scrollToError(formErrors);
                } else {
                  // No errors, submit the form
                  handleApplicantSignup(values, resetForm, setSubmitting);
                }
              }}
            >
              <div className="createProfile-container ">
                <div className={`backArrow ${!isOnline && "mt-5"}`}>
                  <span>{t("GENERAL.COMPLETE_PROFILE")}</span>
                </div>
                <div className="company-logo-name">
                  <div>
                    <div className="company-profile-image">
                      <div
                        className="image-circle"
                        onClick={handleSelectCompanyLogo}
                        ref={refs.logo}
                      >
                        {croppedUploadedImg || croppedImageUrl ? (
                          <div>
                            <img
                              src={
                                croppedUploadedImg?.ImageUrl ||
                                editDetails?.logo
                              }
                            />
                          </div>
                        ) : (
                          <ProfilePlaceholderIcon />
                        )}
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            setFieldValue("logo", e.target.files[0]);
                            onFileSelect(e);
                          }}
                          accept="image/*"
                          name="logo"
                        />
                        <div className="ChooseImageIcon">
                          <ChooseImageIcon />
                        </div>
                      </div>
                      <div className="select-attachment">
                        {isCropping && fileInfo && (
                          <ImageCropPopup
                            show={isCropping}
                            file={fileInfo}
                            onClose={() => setIsCropping(false)}
                            onCropComplete={handleCropComplete}
                          />
                        )}
                      </div>
                    </div>
                    {!croppedUploadedImg && touched.logo ? (
                      <div className="errorMessage">
                        {t("ERROR.LOGO_REQUIRED")}
                      </div>
                    ) : null}
                  </div>
                  <div className="company-name-input" ref={refs.companyName}>
                    <Field name="companyName">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.COMPANY_NAME")} *`}
                          onChange={(newValue: any) => {
                            setFieldValue(
                              "companyName",
                              capitalizeFirstLetter(newValue),
                            );
                            if (!displayNameEdit) {
                              setFieldValue(
                                "displayName",
                                capitalizeFirstLetter(newValue),
                              );
                            }
                          }}
                          value={values.companyName}
                          additionalProps={{
                            className: "textInput fullWidthInput",
                          }}
                        />
                      )}
                    </Field>
                    {errors?.companyName && touched?.companyName ? (
                      <div className="errorMessage">{errors?.companyName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="details-container">
                  <div className="details">
                    <span>{t("GENERAL.COMPANY_DETAILS")}</span>
                  </div>
                  {/* row 1 */}
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <div className="detailsDropdown" ref={refs.industryType}>
                        <Field name="industryType">
                          {() => (
                            <>
                              <SearchTypeSelect
                                options={
                                  industryList?.length ? industryList : []
                                }
                                placeholder={`${t("GENERAL.INDUSTRY_TYPE")} *`}
                                value={skillIdFromSelect}
                                onChange={(getValue: any) => {
                                  setSkillIdFromSelect(getValue);
                                  setFieldValue(
                                    "industryType",
                                    getValue?.value,
                                  );
                                }}
                                loadingText={t("GENERAL.LOADING")}
                                className="typeSelectDropdown industryTypePlaceholder"
                              />
                            </>
                          )}
                        </Field>
                        {errors?.industryType && touched?.industryType ? (
                          <div className="errorMessage">
                            {errors?.industryType}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4" ref={refs.displayName}>
                      <Field name="displayName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.DISPLAY_NAME")} *`}
                            onChange={(newValue: any) => {
                              SetDisplayNameEdit(true);
                              setFieldValue(
                                "displayName",
                                capitalizeFirstLetter(newValue),
                              );
                            }}
                            value={values.displayName}
                            additionalProps={{
                              className: "textInput displayNamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.displayName && touched?.displayName ? (
                        <div className="errorMessage">
                          {errors?.displayName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4" ref={refs.websiteUrl}>
                      <Field name="websiteUrl">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.WEBSITE_URL")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("websiteUrl", newValue.trim())
                            }
                            value={values.websiteUrl}
                            additionalProps={{
                              className: "textInput websiteUrlPlaceholder",
                              maxlength: { websiteUrlLen },
                            }}
                          />
                        )}
                      </Field>
                      {errors?.websiteUrl && touched?.websiteUrl ? (
                        <div className="errorMessage">{errors?.websiteUrl}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 2 */}
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="email">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.EMAIL")} *`}
                            value={companyEmail}
                            additionalProps={{
                              className: "textInput",
                              disabled: true,
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-2">
                        <div className="col-md-12 flex-fill" ref={refs.mobile}>
                          <Field name="mobile">
                            {() => (
                              <div className="detailsDropdown mobileCodeDropdown">
                                <PhoneInput
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                  }}
                                  placeholder={t("GENERAL.MOBILE")}
                                  country={defaultCountry}
                                  enableSearch={true}
                                  value={values.mobile}
                                  onChange={(newValue: any) =>
                                    setFieldValue("mobile", newValue)
                                  }
                                  // disableCountryGuess={true}
                                  countryCodeEditable={false}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.mobile && touched?.mobile ? (
                        <div className="errorMessage">{errors?.mobile}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 3 */}
                  <div className="details">
                    <span>{t("GENERAL.COMPANY_LOCATION")}</span>
                  </div>
                  <div className="row">
                    <div className="col-md-12" ref={refs.address}>
                      <Field name="address">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.ADDRESS")}`}
                            value={values.address}
                            onChange={(newValue: any) =>
                              setFieldValue(
                                "address",
                                capitalizeFirstLetter(newValue),
                              )
                            }
                            additionalProps={{
                              className: "textInput fullWidthInput",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.address && touched?.address ? (
                        <div className="errorMessage">{errors?.address}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 3 */}
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <div className="detailsDropdown" ref={refs.country}>
                        <Field name="country">
                          {() => (
                            <SearchTypeSelect
                              options={countries}
                              placeholder={`${t("GENERAL.COUNTRY")} *`}
                              value={countryName}
                              onChange={(getValue: any) => {
                                setCountryName(getValue);
                                setFieldValue("country", getValue?.value);
                                setFieldValue("state", "");
                                setStateName(defaultStateName);
                              }}
                              loadingText={t("GENERAL.LOADING")}
                              className="typeSelectDropdown industryTypePlaceholder removeInput"
                            />
                          )}
                        </Field>
                        {errors?.country && touched?.country ? (
                          <div className="errorMessage">{errors?.country}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className={`detailsDropdown ${!countryName?.value && "addDisableCss"}`}
                        ref={refs.state}
                      >
                        <Field name="state">
                          {() => (
                            <SearchTypeSelect
                              options={states[countryName?.value] || []}
                              placeholder={`${t("GENERAL.STATE")} *`}
                              value={stateName}
                              onChange={(getValue: any) => {
                                setStateName(getValue);
                                setFieldValue("state", getValue?.value);
                              }}
                              loadingText={t("GENERAL.LOADING")}
                              className="typeSelectDropdown industryTypePlaceholder"
                            />
                          )}
                        </Field>
                        {errors?.state && touched?.state ? (
                          <div className="errorMessage">{errors?.state}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4" ref={refs.city}>
                      <Field name="city">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.CITY")} *`}
                            value={values.city}
                            onChange={(newValue: any) =>
                              setFieldValue(
                                "city",
                                capitalizeFirstLetter(newValue),
                              )
                            }
                            additionalProps={{
                              className: "textInput cityPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.city && touched?.city ? (
                        <div className="errorMessage">{errors?.city}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 4 */}
                  <div className="row inputsRow">
                    <div className="col-md-4" ref={refs.zipcode}>
                      <Field name="zipcode">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.ZIPCODE")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("zipcode", newValue)
                            }
                            value={values.zipcode}
                            additionalProps={{
                              className: "textInput addressConuntryPlaceholder",
                              maxLength: 12,
                            }}
                          />
                        )}
                      </Field>
                      {errors?.zipcode && touched?.zipcode ? (
                        <div className="errorMessage">{errors?.zipcode}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 5 Social links*/}
                  <div className="details">
                    <span>{t("GENERAL.SOCIAL_LINKS")}</span>
                  </div>
                  <div className="d-flex row inputsRow">
                    <div className="col-md-4 ">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <LinkedinIcon />
                        </div>
                        <div className="col-md full-width" ref={refs.linkedin}>
                          <Field name="linkedin">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.LINKEDIN")}
                                onChange={(newValue: any) =>
                                  setFieldValue("linkedin", newValue.trim())
                                }
                                value={values.linkedin}
                                additionalProps={{
                                  className: "textInput",
                                  maxlength: socialLinksLen,
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.linkedin && touched?.linkedin ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.linkedin}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <TwitterIcon />
                        </div>
                        <div className="col-md full-width" ref={refs.twitter}>
                          <Field name="twitter">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.TWITTER")}
                                onChange={(newValue: any) =>
                                  setFieldValue("twitter", newValue.trim())
                                }
                                value={values.twitter}
                                additionalProps={{
                                  className: "textInput",
                                  maxlength: socialLinksLen,
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.twitter && touched?.twitter ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.twitter}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <FacebookIcon />
                        </div>
                        <div className="col-md full-width" ref={refs.facebook}>
                          <Field name="facebook">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.FACEBOOK")}
                                onChange={(newValue: any) =>
                                  setFieldValue("facebook", newValue.trim())
                                }
                                value={values.facebook}
                                additionalProps={{
                                  className: "textInput",
                                  maxlength: socialLinksLen,
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.facebook && touched?.facebook ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.facebook}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 6 */}
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <InstagramIcon />
                        </div>
                        <div className="col-md full-width" ref={refs.instagram}>
                          <Field name="instagram">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.INSTAGRAM")}
                                onChange={(newValue: any) =>
                                  setFieldValue("instagram", newValue.trim())
                                }
                                value={values.instagram}
                                additionalProps={{
                                  className: "textInput",
                                  maxlength: socialLinksLen,
                                }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.instagram && touched?.instagram ? (
                        <div className="col-12">
                          <div className="errorMessage">
                            {errors?.instagram}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                {/* owners details */}
                <div className="details-container">
                  <div className="details">
                    <span>{t("GENERAL.CONTACT_PERSON")}</span>
                  </div>
                  <div className="row inputsRow">
                    <div className="col-md-4" ref={refs.ownerFirstName}>
                      <Field name="ownerFirstName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.FNAME")}*`}
                            onChange={(newValue: any) =>
                              setFieldValue(
                                "ownerFirstName",
                                capitalizeFirstLetter(newValue),
                              )
                            }
                            value={values.ownerFirstName}
                            additionalProps={{
                              className: "textInput fnamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerFirstName && touched?.ownerFirstName ? (
                        <div className="errorMessage">
                          {errors?.ownerFirstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4" ref={refs.ownerLastName}>
                      <Field name="ownerLastName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.LNAME")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue(
                                "ownerLastName",
                                capitalizeFirstLetter(newValue),
                              )
                            }
                            value={values.ownerLastName}
                            additionalProps={{
                              className: "textInput lnamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerLastName && touched?.ownerLastName ? (
                        <div className="errorMessage">
                          {errors?.ownerLastName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4" ref={refs.ownerDesignation}>
                      <Field name="ownerDesignation">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.DESIGNATION")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue(
                                "ownerDesignation",
                                capitalizeFirstLetter(newValue),
                              )
                            }
                            value={values.ownerDesignation}
                            additionalProps={{
                              className: "textInput designationPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerDesignation && touched?.ownerDesignation ? (
                        <div className="errorMessage">
                          {errors?.ownerDesignation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row inputsRow">
                    <div className="col-md-4" ref={refs.ownerEmail}>
                      <Field name="ownerEmail">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.EMAIL")} *`}
                            onChange={(newValue: any) => {
                              setFieldValue("ownerEmail", newValue);
                            }}
                            value={values.ownerEmail}
                            additionalProps={{
                              className: "textInput emailPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      <div className="ownerEmailLink">
                        <span
                          className="copyLink"
                          onClick={() =>
                            setFieldValue("ownerEmail", companyEmail)
                          }
                        >
                          {t("GENERAL_SAME_AS_COMPANY")}
                        </span>
                      </div>
                      {errors?.ownerEmail && touched?.ownerEmail ? (
                        <div className="errorMessage">{errors?.ownerEmail}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div
                        className="col-md-12 flex-fill"
                        ref={refs.ownerMobile}
                      >
                        <Field name="ownerMobile">
                          {() => (
                            <div className="detailsDropdown mobileCodeDropdown">
                              <PhoneInput
                                inputProps={{
                                  name: "phone",
                                }}
                                placeholder={t("GENERAL.MOBILE")}
                                country={defaultCountry}
                                enableSearch={true}
                                value={values.ownerMobile}
                                onChange={(newValue) =>
                                  setFieldValue("ownerMobile", newValue.trim())
                                }
                              />
                            </div>
                          )}
                        </Field>
                        <div className="ownerEmailLink">
                          <span
                            className="copyLink"
                            onClick={() =>
                              setFieldValue("ownerMobile", values.mobile)
                            }
                          >
                            {t("GENERAL_SAME_AS_COMPANY")}
                          </span>
                        </div>
                        {errors?.ownerMobile && touched?.ownerMobile ? (
                          <div className="errorMessage">
                            {errors?.ownerMobile}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="saveBtn-Container">
                  <AssessmentButton type="submit" className={"saveBtn-enable"}>
                    {isSubmitting
                      ? t("GENERAL.LOADING")
                      : editProfile
                        ? t("GENERAL.UPDATE")
                        : t("GENERAL.SAVE")}
                  </AssessmentButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
      <Footer />
    </div>
  );
};
export default CreateProfileScreen;
