function AssessmentListingInviteIcon() {
  return (
    <svg
      className="shadowSvg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <path
        d="M41.5726 23.2276C41.5726 33.8467 32.9641 42.4552 22.345 42.4552C11.7259 42.4552 3.11743 33.8467 3.11743 23.2276C3.11743 12.6085 11.7259 4 22.345 4C32.9641 4 41.5726 12.6085 41.5726 23.2276Z"
        fill="white"
      />
      <path
        d="M41.0726 23.2276C41.0726 33.5705 32.688 41.9552 22.345 41.9552C12.0021 41.9552 3.61743 33.5705 3.61743 23.2276C3.61743 12.8846 12.0021 4.5 22.345 4.5C32.688 4.5 41.0726 12.8846 41.0726 23.2276Z"
        stroke="#35D4AE"
        strokeOpacity="0.2"
      />
      <path
        d="M22.26 15.9708H15.742C14.7934 15.9708 13.8838 16.3383 13.213 16.9925C12.5423 17.6467 12.1655 18.5341 12.1655 19.4593V28.1805C12.1655 29.1057 12.5423 29.9929 13.213 30.6472C13.8838 31.3014 14.7934 31.669 15.742 31.669H26.4713C27.4198 31.669 28.3295 31.3014 29.0003 30.6472C29.6709 29.9929 30.0477 29.1057 30.0477 28.1805V23.567"
        stroke="#4D4D4D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5665 17.1364C12.8941 16.9679 13.2997 17.0904 13.4724 17.41C15.9569 22.0067 18.6423 24.0313 21.1108 24.0379C22.4774 23.9868 23.7818 23.4664 24.7935 22.5686C25.0675 22.3255 25.4916 22.345 25.7408 22.6122C25.9901 22.8794 25.97 23.2931 25.6961 23.5362C24.4468 24.6449 22.8351 25.286 21.1472 25.3457C21.1391 25.3459 21.131 25.3461 21.1229 25.3461C17.8545 25.3461 14.8224 22.7127 12.286 18.0201C12.1133 17.7005 12.2389 17.3049 12.5665 17.1364Z"
        fill="#4D4D4D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.2768 13.5724C28.5087 13.5724 28.7241 13.6892 28.8465 13.8814L29.9632 15.635L32.0141 16.1294C32.2391 16.1836 32.4195 16.3473 32.4911 16.5623C32.5626 16.7773 32.5153 17.0132 32.3659 17.186L31.0037 18.762L31.1542 20.8167C31.1706 21.042 31.0669 21.2596 30.8796 21.3924C30.6924 21.5252 30.4482 21.5544 30.2336 21.4696L28.2768 20.6969L26.32 21.4696C26.1059 21.5542 25.8621 21.5253 25.675 21.3931C25.4879 21.2609 25.3837 21.044 25.3993 20.8192L25.542 18.762L24.1865 17.1846C24.0378 17.0116 23.9911 16.776 24.0629 16.5614C24.1347 16.3468 24.3148 16.1835 24.5395 16.1294L26.5904 15.635L27.7072 13.8814C27.8295 13.6892 28.0449 13.5724 28.2768 13.5724ZM28.2768 15.4661L27.5858 16.5513C27.4935 16.6963 27.3468 16.8003 27.177 16.8412L25.9049 17.1478L26.7432 18.1233C26.8545 18.2527 26.9101 18.4191 26.8984 18.5877L26.8098 19.8649L28.0252 19.3849C28.1866 19.3212 28.3671 19.3212 28.5285 19.3849L29.7397 19.8633L29.6465 18.5901C29.6341 18.4201 29.6901 18.2521 29.8027 18.1219L30.6454 17.147L29.3767 16.8412C29.2069 16.8003 29.0602 16.6963 28.9679 16.5513L28.2768 15.4661Z"
        fill="#4D4D4D"
      />
    </svg>
  );
}

export default AssessmentListingInviteIcon;
