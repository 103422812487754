import "./bargraph.scss";
interface props {
  score?: any;
}
const BarGraph = ({ score }: props) => {
  return (
    <div className="progress">
      <div
        className="progress-bar"
        role="progressbar"
        style={{ width: `${score}%` }}
        aria-valuenow={score}
        aria-valuemin={0}
        aria-valuemax={100}
      />
    </div>
  );
};
export default BarGraph;
