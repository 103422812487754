import { useTranslation } from "react-i18next";

interface FloatingPopupProps {
  isAiPending: boolean;
  overallScore: any;
}
const FloatingPopup = ({ isAiPending, overallScore }: FloatingPopupProps) => {
  const { t } = useTranslation();
  const onhovertext = () => {
    if (overallScore === null) {
      if (isAiPending) {
        return `${t("GENERAL.AI_ANALYSIS_PROGRESS")}`;
      } else {
        return `${t("GENERAL.MANUAL_PENDING")}`;
      }
    }
  };
  return (
    <div className="floating-popup">
      <p>{onhovertext()}</p>
    </div>
  );
};
export default FloatingPopup;
