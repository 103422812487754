function DeleteAssessmentListIcon() {
  return (
    <svg
      width="113"
      height="129"
      viewBox="0 0 113 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.561 77.2343C48.5808 80.9509 51.2149 83.3737 57.4816 84.9084C75.2297 89.2553 91.7551 80.1303 102.26 66.2159C105.49 61.9371 108.475 56.5031 110.211 51.4375C114.352 39.3484 114.277 25.5353 107.29 14.478C92.8949 -8.30249 62.0859 -1.55835 46.8971 16.4916C35.4425 14.8986 29.1212 14.1946 18.1937 21.9466C6.40987 30.3047 -1.04979 45.5533 0.12038 59.1858C1.69867 77.5826 18.5049 88.3478 35.9681 81.0767C38.4574 80.0403 40.9553 78.7194 43.561 77.2343Z"
        fill="#FFEBEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7595 110.239H110.89C112.016 110.239 112.937 111.16 112.937 112.286C112.937 113.411 112.016 114.333 110.89 114.333H14.7595C13.6337 114.333 12.7129 113.412 12.7129 112.286C12.7129 111.16 13.6337 110.239 14.7595 110.239ZM35.3166 14.7039H81.9534C83.5385 14.7039 84.9784 15.3521 86.0215 16.3951V16.402C87.064 17.4451 87.7116 18.8845 87.7116 20.4647V90.5464C87.7116 92.1267 87.064 93.5661 86.0215 94.6091V94.6161C84.9784 95.6591 83.5385 96.3073 81.9534 96.3073H35.3166C33.7315 96.3073 32.2916 95.6591 31.2486 94.6161V94.6091C30.2061 93.5661 29.5584 92.1267 29.5584 90.5464V20.4647C29.5584 18.8845 30.2061 17.4451 31.2486 16.402V16.3951C32.2916 15.3521 33.7315 14.7039 35.3166 14.7039ZM67.28 92.6933L84.0975 75.6907V20.4649C84.0975 19.8727 83.8569 19.3344 83.4706 18.946L83.4669 18.9497C83.0779 18.5608 82.5412 18.318 81.9533 18.318H35.3165C34.7286 18.318 34.1919 18.5608 33.8029 18.9497L33.7997 18.946C33.4135 19.3344 33.1723 19.8727 33.1723 20.4649V90.5465C33.1723 91.1387 33.4135 91.6765 33.7997 92.0649L33.8029 92.0617C34.1919 92.4507 34.7286 92.6934 35.3165 92.6934L67.28 92.6933ZM84.0975 80.8289L72.3623 92.6933H81.9533C82.5412 92.6933 83.078 92.4505 83.4669 92.0616L83.4706 92.0648C83.8569 91.6764 84.0975 91.1386 84.0975 90.5464V80.8289ZM35.4391 124.143H90.2809C91.4066 124.143 92.3275 125.064 92.3275 126.189C92.3275 127.315 91.4066 128.237 90.2809 128.237H35.4391C34.3134 128.237 33.3926 127.316 33.3926 126.19C33.3926 125.064 34.3134 124.143 35.4391 124.143Z"
        fill="#E41B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8372 50.8734H78.1628C78.6233 50.8734 79 51.238 79 51.6838C79 52.1295 78.6233 52.4944 78.1628 52.4944H38.8372C38.3767 52.4944 38 52.1297 38 51.684C38 51.2382 38.3767 50.8734 38.8372 50.8734ZM38.8372 56.379H61.2722C61.7327 56.379 62.1094 56.7437 62.1094 57.1894C62.1094 57.6352 61.7327 58 61.2722 58H38.8372C38.3767 58 38 57.6354 38 57.1896C38 56.7438 38.3767 56.379 38.8372 56.379Z"
        fill="#E41B1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.8372 45C38.8372 45 77.7023 45 78.1628 45C78.6233 45 79 45.3646 79 45.8104C79 46.2561 78.6233 46.621 78.1628 46.621H38.8372C38.3767 46.621 38 46.2563 38 45.8106C38 45.3648 38.3767 45 38.8372 45Z"
        fill="#E41B1B"
      />
      <rect x="38" y="24" width="15" height="15" fill="#FF8F8F" />
      <path
        d="M96.4704 88.4704C95.8432 89.0977 95.8432 90.1146 96.4704 90.7418L102.229 96.5L96.4704 102.258C95.8432 102.885 95.8432 103.902 96.4704 104.53C97.0977 105.157 98.1146 105.157 98.7418 104.53L104.5 98.7713L110.258 104.53C110.885 105.157 111.902 105.157 112.53 104.53C113.157 103.902 113.157 102.885 112.53 102.258L106.771 96.5L112.53 90.7418C113.157 90.1146 113.157 89.0977 112.53 88.4704C111.902 87.8432 110.885 87.8432 110.258 88.4704L104.5 94.2286L98.7418 88.4704C98.1146 87.8432 97.0977 87.8432 96.4704 88.4704Z"
        fill="#E41B1B"
      />
    </svg>
  );
}
export default DeleteAssessmentListIcon;
