import CustomModal from "../../../components/CustomModal/CustomModal";
import RechargeSlab from "./RechargeSlab/RechargeSlab";
import { AssessmentButton, showToast } from "ui-components";
import "./Index.scss";
import AddCard from "./AddCard/AddCard";
import Payment from "./Payment";
import { useEffect, useState } from "react";
import {
  calculatePayment,
  getPaymentMethod,
  rechargeAnAccount,
} from "../../../service/Account/Account";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CreditCoin from "../../../utils/Images/CreditCoin";
import { loadStripe } from "@stripe/stripe-js";
import { setSessionStorageItem } from "../../../utils/SessionStorageMethod/setSessionStorageItem";
const PayCheckout = ({ payCheckout, handleClosePayCheckout }: any) => {
  // Initialize Stripe (replace with your Stripe public key)
  const stripePromise = loadStripe(`
    ${process.env.REACT_APP_STRIPE_PUBLISH_KEY}`);
  const [rechargeSlabId, setRechargeSlabId] = useState<any>({});
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [totalAmount, setTotalAmount] = useState<any>({});
  const [paymentMethodID, SetPaymentMethodID] = useState<any>("");
  const [isSavaCard, setIsSaveCard] = useState(false);
  const [cardUsed, setCardUsed] = useState("DEFAULT");
  const [disableConfirmPay, setDisableConfirmPay] = useState(false);
  const [confirmPaymentPopup, setConfirmPaymentPopup] = useState(false);

  const { i18n, t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  useEffect(() => {
    getPayment();
  }, [rechargeSlabId?.rechargeSlabId]);
  const getPayment = async () => {
    const companyId = localStorage.getItem("companyId") as string;
    const branchId = localStorage.getItem("branchId") as string;
    const paymentMethodData = await getPaymentMethod(companyId, branchId);
    if (
      paymentMethodData[0]?.gatewayPaymentMethodId &&
      rechargeSlabId?.rechargeSlabId
    ) {
      calculatePaymentTax(paymentMethodData[0]?.gatewayPaymentMethodId);
    } else {
      if (paymentMethodID) {
        calculatePaymentTax(paymentMethodID);
      }
    }
    setPaymentMethod(paymentMethodData);
  };
  // function to calculate total amount with tax and plateform fess
  const calculatePaymentTax = async (paymentId: string) => {
    SetPaymentMethodID(paymentId);
    const paymentCalData = await calculatePayment({
      paymentMethodId: paymentId,
      rechargeSlabId: rechargeSlabId?.rechargeSlabId,
    });
    setTotalAmount(paymentCalData?.data);
  };

  const handlePaymentMethod = async () => {
    setDisableConfirmPay(true);
    setSessionStorageItem("rechargeSlabId", rechargeSlabId?.rechargeSlabId);
    setSessionStorageItem(
      "paymentMethodId",
      cardUsed === "DEFAULT"
        ? paymentMethod[0]?.gatewayPaymentMethodId
        : paymentMethodID,
    );
    setSessionStorageItem("saveCardDetails", isSavaCard);

    const stripe = await stripePromise;
    try {
      const rechargeData = await rechargeAnAccount({
        companyId: localStorage.getItem("companyId"),
        branchId: localStorage.getItem("branchId"),
        rechargeSlabId: rechargeSlabId?.rechargeSlabId,
        paymentMethodId:
          cardUsed === "DEFAULT"
            ? paymentMethod[0]?.gatewayPaymentMethodId
            : paymentMethodID,
        saveCardDetails: isSavaCard,
      });

      if (rechargeData?.status) {
        setConfirmPaymentPopup(false);
        setDisableConfirmPay(false);
        if (rechargeData?.data?.requires_action && stripe && stripe) {
          // Handle 3D Secure or other required action
          const { paymentIntent, error } = await stripe.handleCardAction(
            rechargeData.data.client_secret,
          );

          if (error) {
            showToast(t("PAYMENT.AUTHENTICATION_FAILED"), "error");
          } else if (paymentIntent?.status === "succeeded") {
            // Payment succeeded after handling 3D Secure
            showToast(t("PAYMENT.RECHARGE_SUCCESSFUL"), "success");
            navigate(`/${currentLanguage}/account/`, {
              state: { payment: true },
            });
          }
        } else {
          // No further action needed, payment successful
          showToast(t("PAYMENT.RECHARGE_SUCCESSFUL"), "success");
          navigate(`/${currentLanguage}/account/`, {
            state: { payment: true },
          });
        }
      } else {
        showToast(t("PAYMENT.FAILED"), "error");
        setConfirmPaymentPopup(false);
        navigate(`/${currentLanguage}/account/`, {
          state: { payment: true },
        });
      }
    } catch (error) {
      showToast(t("PAYMENT.ERROR_OCCURRED_DURING_PAYMENT"), "error");
    }
  };
  return (
    <CustomModal
      show={payCheckout}
      isHeaderVisible={true}
      handleClose={handleClosePayCheckout}
      isCrossBtn={true}
      isFooterVisible={false}
      size="xl"
      instructionHeadTitle={t("GENERAL.BUY_CREDITS")}
    >
      <div className="payCheckout">
        <div className="row">
          <div className=" col-lg-6 col-md-12 col-sm-12">
            <RechargeSlab
              rechargeSlabId={rechargeSlabId}
              setRechargeSlabId={setRechargeSlabId}
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 ">
            <AddCard
              cardUsed={cardUsed}
              setCardUsed={setCardUsed}
              setIsSaveCard={setIsSaveCard}
              isSavaCard={isSavaCard}
              rechargeSlabId={rechargeSlabId}
              paymentMethod={paymentMethod}
              calculatePaymentTax={calculatePaymentTax}
            />
          </div>
        </div>
        <div className="calculatePaymentConfirm mt-3">
          <div className="responsivePayment">
            <Payment totalAmount={totalAmount} />
          </div>
          <div className="confirmPayment">
            <div className="totalAmountCal">
              <div className="totalAmountText">
                <span>{t("PAYMENT.TOTAL_AMOUNT")} :</span>
                <span>
                  $
                  {totalAmount?.amount
                    ? totalAmount?.stripeFees +
                      totalAmount?.amount +
                      totalAmount?.taxFee
                    : "0"}
                </span>
              </div>
              <div className="totalCreditText">
                <span>{t("PAYMENT.TOTAL_CREDITS")} :</span>
                <span className="creditCoin">
                  <CreditCoin width={22} height={22} />
                  {totalAmount?.amount
                    ? rechargeSlabId?.credits + rechargeSlabId?.extraCredits
                    : "0"}
                </span>
              </div>
            </div>
            {confirmPaymentPopup ? (
              <div className="restDraft">
                <div className="yesNoBtn">
                  <AssessmentButton
                    disabled={!paymentMethodID?.length || disableConfirmPay}
                    className={"yesBtn lightBtn"}
                    onClick={() => handlePaymentMethod()}
                  >
                    <span>{t("GENERAL.YES")}</span>
                  </AssessmentButton>
                  <AssessmentButton
                    className={"NoBtn lightBtn"}
                    disabled={!paymentMethodID?.length || disableConfirmPay}
                    onClick={() => {
                      setConfirmPaymentPopup(false);
                      setDisableConfirmPay(false);
                    }}
                  >
                    <span>{t("GENERAL.NO")}</span>
                  </AssessmentButton>
                </div>
              </div>
            ) : (
              <AssessmentButton
                disabled={!paymentMethodID?.length || disableConfirmPay}
                onClick={() => {
                  setConfirmPaymentPopup(true);
                  // handlePaymentMethod();
                }}
              >
                {disableConfirmPay
                  ? t("GENERAL.LOADING")
                  : t("PAYMENT.CONFIRM_PAYMENT")}
              </AssessmentButton>
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default PayCheckout;
