const ResponseApplicantIcon = (props: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  const { width = "18", height = "18", color = "#1C274C" } = props;
  return (
    <svg
      width={width}
      height={height}
      color={color}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.00059 8.99995C10.3261 8.99995 11.4006 7.92543 11.4006 6.59995C11.4006 5.27447 10.3261 4.19995 9.00059 4.19995C7.6751 4.19995 6.60059 5.27447 6.60059 6.59995C6.60059 7.92543 7.6751 8.99995 9.00059 8.99995Z"
        stroke={color}
        strokeWidth="1.75"
      />
      <path
        d="M13.7758 15.4001C13.6485 13.0869 12.9402 11.4001 9.00041 11.4001C5.06066 11.4001 4.35242 13.0869 4.2251 15.4001"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
      <path
        d="M5 2.07026C6.1767 1.38958 7.54288 1 9 1C13.4182 1 17 4.58172 17 9C17 13.4182 13.4182 17 9 17C4.58172 17 1 13.4182 1 9C1 7.54288 1.38958 6.1767 2.07026 5"
        stroke={color}
        strokeWidth="1.75"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ResponseApplicantIcon;
