import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

//  to get created assessment list
export const getCreatedAssessList = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyGetAssessmentList", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  to get created company GetAssessmentApplicantList
export const companyGetAssessmentApplicantList = async (data: any) => {
  try {
    const res = await apiClient.post(
      "company/companyGetAssessmentApplicantList",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
    return error;
  }
};

//  to get company Assessment Applicant Count
export const companyGetAssessmentApplicantCount = async (
  assessmentId: string,
  search?: string,
) => {
  try {
    const res = await apiClient.get(
      `company/companyGetAssessmentApplicantCount?assessmentId=${assessmentId}${search ? `&search=${search}` : ""}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  to get applicant assessment response
export const companyGetApplicantAssessmentResponse = async (
  applicantId: string | null,
  assessmentId: string | undefined,
  filterOption: string | string[],
) => {
  let queryFilterOption;

  if (filterOption === "All" || filterOption === "Custom") {
    queryFilterOption = filterOption;
  } else {
    queryFilterOption = JSON.stringify(filterOption);
  }
  try {
    const res = await apiClient.get(
      `company/companyGetApplicantAssessmentResponse?applicantId=${applicantId}&assessmentId=${assessmentId}&filterOption=${encodeURIComponent(queryFilterOption)}`,
    );
    return res;
  } catch (error) {
    handleError(error);
    return error;
  }
};

//  add company assessmentQuestionReport
export const assessmentQuestionReport = async (data: any) => {
  try {
    const res = await apiClient.post("company/assessmentQuestionReport", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  add company addCommentOverQuestion
export const addCommentOverQuestion = async (data: any) => {
  try {
    const res = await apiClient.post("company/addCommentOverQuestion", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};
// to get company getAssessmentEvaluationComment
export const getAssessmentEvaluationComment = async (
  assessmentApplicantQuestionId: string,
) => {
  try {
    const res = await apiClient.get(
      `company/getAssessmentEvaluationComment?assessmentApplicantQuestionId=${assessmentApplicantQuestionId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  add company assessment Applicant ResponseManual Review
export const assessmentApplicantResponseManualReview = async (data: any) => {
  try {
    const res = await apiClient.post(
      "company/assessmentApplicantResponseManualReview",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  add company Evaluation Comment
export const addEvaluationComment = async (data: any) => {
  try {
    const res = await apiClient.post("company/addEvaluationComment", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

// update Assessment Applicant Question FinalScore
export const updateAssessmentApplicantQuestionFinalScore = async (
  data: any,
) => {
  try {
    const res = await apiClient.put(
      "company/updateAssessmentApplicantQuestionFinalScore",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
