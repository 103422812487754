import { AssessmentButton, InputField, showToast } from "ui-components";
// import ".";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import CompanyOnboardWrapper from "../../../../components/CompanyOnboard/CompanyOnboardWrapper";
import { loginUserData } from "../../../../utils/Common/Constant";
import {
  resetPasswordApi,
  validateToken,
} from "../../../../service/Scope/Scope";
import { useEffect, useState } from "react";
import i18n from "../../../../i18nConfig";
import Loader from "../../../../components/Loader/Loader";
const ResetPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const type = searchParams.get("type");
  const [showPage, setShowPage] = useState(false);
  const currentLanguage = i18n.language;
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .max(32, t("ERROR.PASSWORD_MUST"))
      .matches(/[a-zA-Z]/, t("ERROR.PASSWORD_LETTER"))
      .matches(/[0-9]/, t("ERROR.PASSWORD_NUMBER"))
      .matches(
        /[!@#$%^&*(),.?":{}\\|<>`~\-_=+[\];:'"<>/?]/,
        t("ERROR.PASSWORD_SPECIAL"),
      )

      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("ERROR.CONFIRM_PASSWORD_MATCH"),
      )
      .trim()
      .required(t("ERROR.CONFIRM_PASSWORD_REQUIRED")),
  });

  useEffect(() => {
    if (token && type) {
      validateTokenApi();
    }
  }, [token, type]);
  const validateTokenApi = async () => {
    try {
      const res = await validateToken(token, type);
      if (res?.data?.status === 200) {
        setShowPage(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // reset password api call
  const resetPasswordApiCall = async (data: any, setSubmitting: any) => {
    try {
      const res = await resetPasswordApi(data);
      if (res?.status === 200) {
        showToast(res?.customMessage, "success");
        navigate(`/${currentLanguage}/company-login`, { replace: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting && setSubmitting(false);
    }
  };
  //call onsubmit password creation
  const handleConfirmPassword = (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    setSubmitting && setSubmitting(true);
    resetForm && resetForm();
    const data = {
      token: token,
      type: type,
      password: value?.password,
    };
    resetPasswordApiCall(data, setSubmitting);
  };
  const onSignInClick = () => {
    navigate("/");
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={showPage ? t("GENERAL.ALREADY_ACCOUNT") : ""}
      lowerSubmitText2={showPage ? t("GENERAL.SIGN_IN") : ""}
      signInUpClick={onSignInClick}
      islogoVisible={!showPage}
    >
      {showPage ? (
        <Formik
          initialValues={loginUserData}
          validationSchema={loginUserSchema}
          onSubmit={(value, { resetForm, setSubmitting }) => {
            handleConfirmPassword(value, resetForm, setSubmitting);
          }}
        >
          {({ errors, touched, values, setFieldValue, isSubmitting }) => {
            return (
              <div className="companyLogin">
                <Form>
                  <div className="userSec">
                    <div className="userContent">
                      <div className="heading-div">
                        <h1 className="userHeadingApp">
                          {t("GENERAL.CREATE_NEW_PASS")}
                        </h1>
                      </div>
                      <Field name="password">
                        {() => (
                          <div className="passwordSec">
                            <InputField
                              placeholder={`${t("GENERAL.NEW_PASSWORD")} *`}
                              onChange={(newValue) =>
                                setFieldValue("password", newValue)
                              }
                              value={values.password}
                              additionalProps={{
                                className: "newPasswordInput",
                              }}
                              type="password"
                            />
                          </div>
                        )}
                      </Field>
                      {errors?.password && touched?.password ? (
                        <div className="errorMessage">{errors?.password}</div>
                      ) : null}
                      <Field name="confirmPassword">
                        {() => (
                          <div className="passwordSec">
                            <InputField
                              placeholder={`${t("GENERAL.CONFIRM_PASSWORD")} *`}
                              onChange={(newValue) =>
                                setFieldValue("confirmPassword", newValue)
                              }
                              value={values.confirmPassword}
                              additionalProps={{
                                className:
                                  "newPasswordInput confirmPasswordPlaceholder",
                              }}
                              type="password"
                            />
                          </div>
                        )}
                      </Field>
                      {errors?.confirmPassword && touched?.confirmPassword ? (
                        <div className="errorMessage">
                          {errors?.confirmPassword}
                        </div>
                      ) : null}
                      <AssessmentButton
                        className="submitBtn submitBtnCreatePassword"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        {isSubmitting
                          ? t("GENERAL.LOADING")
                          : t("GENERAL.SAVE")}
                      </AssessmentButton>
                    </div>
                  </div>
                </Form>
                <div className="password-instructions">
                  <div>
                    <li>{t("GENERAL.INSTRUCTION1")}</li>
                    <li>{t("GENERAL.INSTRUCTION2")}</li>
                    <li>{t("GENERAL.INSTRUCTION3")}</li>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
      ) : (
        <div>
          <Loader />
        </div>
      )}
    </CompanyOnboardWrapper>
  );
};

export default ResetPassword;
