import ManualScoreIcon from "../../../../utils/Images/ManualScoreIcon";
import { useTranslation } from "react-i18next";
import { AssessmentButton, RangeSelector } from "ui-components";
import ResponseVideoTypeIcon from "../../../../utils/Images/ResponseVideoTypeIcon";
import { ResponseScreenEnums } from "../../../../utils/Enums/ResponseScreenEnums";
interface ManualScore {
  handleRangeValue: (value: number, setFn: any) => void;
  setFn: any;
  rangeValue: number;
  handleManualReview: any;
  isVideoType: boolean;
  handleManualClick: any;
  isManualEvaluation: any;
  isNotified: boolean;
  evaluationStatus: any;
}
const ManualScore: React.FC<ManualScore> = ({
  handleRangeValue,
  setFn,
  rangeValue,
  handleManualReview,
  isVideoType,
  handleManualClick,
  isManualEvaluation,
  isNotified,
  evaluationStatus,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`manualContainer ${!isVideoType && "justify-content-start"} mt-3`}
    >
      {isVideoType && (
        <div
          onClick={handleManualClick}
          className={`ResponseVideoTypeIcon ${
            evaluationStatus
              ? evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
                evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                ? !isNotified && isManualEvaluation?.isEvaluation
                  ? "mt-5"
                  : ""
                : "mt-5"
              : ""
          }`}
        >
          <ResponseVideoTypeIcon
            color={
              evaluationStatus
                ? evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
                  evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                  ? !isManualEvaluation?.isEvaluation && !isNotified
                    ? "var(--primary-green)"
                    : "var(--icons-grey)"
                  : "var(--icons-grey)"
                : !isManualEvaluation?.isEvaluation && !isNotified
                  ? "var(--primary-green)"
                  : "var(--icons-grey)"
            }
          />
        </div>
      )}
      <div className="manualRangeContainer">
        <div className="manualScore">
          <div className="ManualScoreIconDiv">
            <ManualScoreIcon
              height="24"
              width="24"
              color={
                evaluationStatus
                  ? evaluationStatus ===
                      ResponseScreenEnums?.STATUS_COMPLETED ||
                    evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                    ? !isManualEvaluation?.isEvaluation && !isNotified
                      ? "var(--primary-green)"
                      : "var(--icons-grey)"
                    : "var(--icons-grey)"
                  : !isManualEvaluation?.isEvaluation && !isNotified
                    ? "var(--primary-green)"
                    : "var(--icons-grey)"
              }
            />{" "}
            <span>{t("RESPONSE_LIST.MANUAL_SCORE")}</span>
          </div>

          <div
            className={`manualCount ${
              evaluationStatus
                ? evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
                  evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                  ? !isManualEvaluation?.isEvaluation && !isNotified
                    ? "activeManualScore"
                    : "inActiveManualScore"
                  : "inActiveManualScore"
                : !isManualEvaluation?.isEvaluation && !isNotified
                  ? "activeManualScore"
                  : "inActiveManualScore"
            }`}
          >
            <h3>
              {t("RESPONSE_LIST.SCORE")} - <span>{rangeValue}%</span>
            </h3>
          </div>
        </div>
        <div
          className={`manualRage ${
            evaluationStatus
              ? evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
                evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                ? !isManualEvaluation?.isEvaluation && !isNotified
                  ? "activeManualSection"
                  : "inactiveManualSection"
                : "inactiveManualSection"
              : !isManualEvaluation?.isEvaluation && !isNotified
                ? "activeManualSection"
                : "inactiveManualSection"
          }`}
        >
          <RangeSelector
            id="two6547bdgtrb4564"
            label={""}
            minLabel={"0"}
            maxLabel={100}
            step={1}
            min={0}
            max={100}
            value={rangeValue}
            setValue={(value) => {
              handleRangeValue(value, setFn);
            }}
            disabled={
              evaluationStatus
                ? evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
                  evaluationStatus === ResponseScreenEnums?.STATUS_FAILED
                  ? !isManualEvaluation?.isEvaluation && !isNotified
                    ? false
                    : true
                  : true
                : !isManualEvaluation?.isEvaluation && !isNotified
                  ? false
                  : true
            }
          />
        </div>
        {evaluationStatus ? (
          (evaluationStatus === ResponseScreenEnums?.STATUS_COMPLETED ||
            evaluationStatus === ResponseScreenEnums?.STATUS_FAILED) &&
          !isManualEvaluation?.isEvaluation &&
          !isNotified ? (
            <div className="rangeSaveBtn">
              <AssessmentButton onClick={handleManualReview}>
                {t("GENERAL.SAVE")}
              </AssessmentButton>
            </div>
          ) : (
            isNotified && (
              <div className="rangeSaveBtn">
                <AssessmentButton onClick={handleManualReview} disabled={true}>
                  {t("GENERAL.SAVE")}
                </AssessmentButton>
              </div>
            )
          )
        ) : (
          <div className="rangeSaveBtn">
            <AssessmentButton
              onClick={handleManualReview}
              disabled={isNotified}
            >
              {t("GENERAL.SAVE")}
            </AssessmentButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default ManualScore;
