import { AssessmentButton, TypeSelect } from "ui-components";
import "./SkillCard.scss";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AiStarIcon from "../../utils/Images/AiStarIcon";
import { handlePopupOpen } from "../../pages/Assessment/sections/AssessmentBuild/SidebarCategory";
interface SkillCardProps {
  addedSkills?: any;
  skillItem: any;
  handleAddSkill: (skill: any) => void;
  isAdded?: boolean;
  textBtn: string;
  setIsAddedSKillPopupOpen?: any;
}
const SkillCard: React.FC<SkillCardProps> = ({
  addedSkills,
  skillItem,
  handleAddSkill,
  isAdded,
  textBtn,
  setIsAddedSKillPopupOpen,
}) => {
  const { t } = useTranslation();
  const [selectedDifficultyLevels, setSelectedDifficultyLevels] = useState<{
    [key: string]: number;
  }>({});
  useEffect(() => {
    // Initialize the selectedDifficultyLevels with the values from skillItem if it exists
    if (Object.keys(skillItem?.selectedDifficultyLevels ?? {}).length) {
      setSelectedDifficultyLevels(skillItem?.selectedDifficultyLevels);
    } else if (Object.keys(skillItem?.selectDifficultyLevel ?? {}).length) {
      setSelectedDifficultyLevels(skillItem?.selectDifficultyLevel);
    } else {
      setSelectedDifficultyLevels({});
    }
  }, [skillItem]);
  const handleAddClick = () => {
    handleAddSkill({
      ...skillItem,
      selectedDifficultyLevels,
    });
  };
  const handleUpdateSkill = () => {
    const addedSkillData = addedSkills?.filter(
      (item: any) => item.skillId === skillItem?.skillId,
    );
    handlePopupOpen(
      skillItem?.skillId,
      addedSkillData[0],
      setIsAddedSKillPopupOpen,
    );
  };
  const handleTypeSelectChange = (level: string, value: number) => {
    setSelectedDifficultyLevels((prev: any) => ({
      ...prev,
      [level]: value,
    }));
  };
  return (
    <div
      className={`skillCard mb-3 ${isAdded && "cursorPointer"}`}
      onClick={isAdded ? handleUpdateSkill : () => {}}
    >
      <div className="skillHeading">
        <div className="skillName">
          <div className="skillFirstLetter">
            {skillItem?.icon || skillItem?.skillIcon ? (
              <img src={skillItem?.icon ?? skillItem?.skillIcon} alt="" />
            ) : (
              <span className="skillChar">
                {skillItem?.skillName.charAt(0)}
              </span>
            )}
          </div>
          <div className="skillEllip">
            <span title={skillItem?.skillName}>{skillItem?.skillName}</span>
          </div>
        </div>
        <div className="skillName">
          <div>
            <span>{skillItem?.questionType}</span>
          </div>
          <div>
            {skillItem?.questionType === "Video" ? <AiStarIcon /> : null}
          </div>
        </div>
      </div>
      <div className="levels mt-4 mb-3">
        <span>{t("CREATE_ASSESSMENT.DIFFICULTY_LEVELS")}</span>
        <span>{t("CREATE_ASSESSMENT.NO_OF_QUESTIONS")}</span>
      </div>
      <div className="difficultyLevel">
        {(skillItem?.difficultyLevels ?? skillItem?.difficultyLevel)
          ?.split(",")
          ?.map((ele: string, ind: number) => {
            return (
              <div
                className="skillCardSelect d-flex justify-content-between mb-3"
                key={ind}
              >
                <span>{ele} </span>
                <TypeSelect
                  className={`${isAdded ? "customSelectTypeDisabled" : ""}`}
                  options={Array.from(
                    {
                      length:
                        skillItem?.totalQuestions + 1 ||
                        skillItem?.questionsCount + 1,
                    },
                    (v, k) => ({
                      label: String(k).padStart(2),
                      value: k,
                    }),
                  )}
                  loadingText=" "
                  value={
                    selectedDifficultyLevels[ele] ?? skillItem?.questionCount
                  }
                  onChange={(value: any) => handleTypeSelectChange(ele, value)}
                  placeholder="0"
                />
              </div>
            );
          })}

        <div className="addBtn">
          <AssessmentButton
            // disabled={isAdded}
            className={isAdded ? "" : "lightBtn"}
            onClick={!isAdded ? handleAddClick : () => {}}
          >
            {isAdded ? t("GENERAL.UPDATE") : textBtn}
          </AssessmentButton>
        </div>
      </div>
    </div>
  );
};

export default SkillCard;
