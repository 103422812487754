type KeyCheckMode = "all" | "any";
export function checkKeysInSessionStorage(
  keys: string[],
  mode: KeyCheckMode,
): boolean {
  if (mode === "all") {
    return keys.every((key) => sessionStorage.getItem(key) !== null);
  } else if (mode === "any") {
    return keys.some((key) => sessionStorage.getItem(key) !== null);
  } else {
    throw new Error("Invalid mode. Mode must be all or any.");
  }
}
