import React from "react";
import "./Unauthorized.scss";
import { useTranslation } from "react-i18next";
import UnauthorizedImg from "../../utils/Images/UnauthorizedImg";

const Unauthorized: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="unauthorizedScreen">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="notFoundMessage">
              <div className="unauthorizeContent">
                <UnauthorizedImg />
                <div>
                  <h1>{t("GENERAL.UNAUTHORIZED")}</h1>
                  <h3>{t("GENERAL.SOMETHING_WENT_WRONG")}</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Unauthorized;
