function InviteSelectIcon() {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="6"
        r="5.6"
        fill="#35D4AE"
        stroke="white"
        strokeWidth="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.90219 4.07163C8.01617 4.18243 8.03297 4.38205 7.93972 4.51747L5.70161 7.76759C5.52062 8.0304 5.19745 8.07696 4.96884 7.87325L4.10668 7.10499C3.98886 7 3.96498 6.8014 4.05335 6.66142C4.14171 6.52143 4.30886 6.49306 4.42668 6.59805L5.28883 7.36631L7.52694 4.11621C7.6202 3.98078 7.7882 3.96082 7.90219 4.07163Z"
        fill="white"
        stroke="white"
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default InviteSelectIcon;
