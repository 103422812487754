import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18n from "./i18nConfig";
import ErrorBoundary from "./utils/ErrorBoundary/ErrorBoundary";
import { AssessmentJsonContextProvider } from "./context/JsonContext/JsonContext";
import { BrowserRouter } from "react-router-dom";
import { SystemPreferenceContextProvider } from "./context/SystemPreference/SystemPreference";
import { ToastContainer } from "ui-components";
import { isRtl } from "./utils/Common/Constant";
import { ThemeProvider } from "./context/ThemeContext/ThemeContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const RootComponent = () => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLanguage = i18n.language;
    const direction = isRtl(currentLanguage) ? "rtl" : "ltr";
    document.documentElement.setAttribute("dir", direction);
    document.documentElement.setAttribute("lang", currentLanguage);
  }, [i18n.language]);

  return <App />;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <React.StrictMode>
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_CLIENT_ID || "ClienId"}
    >
      <ErrorBoundary>
        <I18nextProvider i18n={i18n}>
          <AssessmentJsonContextProvider>
            <SystemPreferenceContextProvider>
              <ThemeProvider>
                <BrowserRouter>
                  <RootComponent />
                  <ToastContainer />
                </BrowserRouter>
              </ThemeProvider>
            </SystemPreferenceContextProvider>
          </AssessmentJsonContextProvider>
        </I18nextProvider>
      </ErrorBoundary>
    </GoogleOAuthProvider>
  </React.StrictMode>,
);
