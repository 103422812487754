function UnauthorizedImg() {
  return (
    <svg
      width="328"
      height="328"
      viewBox="0 0 328 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M248.019 19.0097C259.218 34.6864 246.551 60.4287 263.046 72.1345C279.105 83.5296 298.487 64.3704 313.603 74.1649C336.033 88.6956 332.679 156.293 300.549 198.617C290.886 211.343 282.753 216.34 279.279 230.656C271.817 261.394 299.366 279.341 294.319 294.559C282.956 328.821 102.986 359.365 29.5733 257.526C-4.71349 209.963 -10.4975 140.818 19.1339 87.9525C72.6769 -7.5842 222.892 -16.1685 248.019 19.0097Z"
        fill="#F4F7FA"
      />
      <path
        d="M55.1857 54.041V228.216C55.1811 228.793 55.2939 229.366 55.5172 229.898C55.7406 230.431 56.0698 230.913 56.4849 231.314C56.9001 231.716 57.3925 232.029 57.9323 232.234C58.4721 232.439 59.048 232.533 59.625 232.509L300.634 224.183C302.608 224.115 304.206 222.278 304.206 220.078V64.109L55.1857 54.041Z"
        fill="url(#paint0_linear_6897_457)"
      />
      <path
        d="M300.637 55.541L59.6285 44.8469C59.0534 44.816 58.4781 44.9034 57.9381 45.1036C57.398 45.3038 56.9048 45.6126 56.4888 46.0109C56.0727 46.4091 55.7428 46.8885 55.5193 47.4193C55.2957 47.9501 55.1834 48.521 55.1892 49.0969V56.6765L304.211 66.4734V59.6859C304.211 57.4779 302.612 55.6286 300.637 55.541Z"
        fill="url(#paint1_linear_6897_457)"
      />
      <path
        d="M65.4598 50.8471C65.4627 51.1021 65.4133 51.3549 65.3148 51.5902C65.2163 51.8254 65.0707 52.0379 64.8869 52.2148C64.7032 52.3916 64.4852 52.529 64.2464 52.6185C64.0076 52.7079 63.753 52.7476 63.4983 52.7349C62.9706 52.7084 62.4731 52.481 62.1077 52.0993C61.7423 51.7177 61.5368 51.2107 61.5333 50.6823C61.5304 50.427 61.5799 50.1738 61.6786 49.9384C61.7773 49.7029 61.9233 49.4902 62.1074 49.3133C62.2915 49.1364 62.5099 48.9991 62.7491 48.9099C62.9884 48.8206 63.2433 48.7814 63.4983 48.7945C64.0252 48.8224 64.5215 49.0504 64.886 49.4318C65.2506 49.8133 65.4558 50.3195 65.4598 50.8471Z"
        fill="white"
      />
      <g>
        <path
          d="M97.8503 87.4268L73.6338 86.7761V80.6416L97.8503 81.3566V87.4268Z"
          fill="white"
        />
        <path
          d="M225.171 90.8526L113.247 87.842V81.8115L225.171 85.1118V90.8526Z"
          fill="white"
        />
        <path
          d="M136.663 98.6677L113.247 98.142V92.1104L136.663 92.698V98.6677Z"
          fill="white"
        />
        <path
          d="M259.907 101.437L151.553 99.0025V93.0713L259.907 95.7863V101.437Z"
          fill="white"
        />
        <path
          d="M136.663 108.866L113.247 108.444V102.413L136.663 102.897V108.866Z"
          fill="white"
        />
        <path
          d="M259.907 111.089L151.553 109.135V103.204L259.907 105.439V111.089Z"
          fill="white"
        />
        <path
          d="M97.8503 118.536L73.6338 118.207V112.073L97.8503 112.466V118.536Z"
          fill="white"
        />
        <path
          d="M225.171 120.268L113.247 118.745V112.715L225.171 114.528V120.268Z"
          fill="white"
        />
        <path
          d="M136.663 129.262L113.247 129.047V123.017L136.663 123.292V129.262Z"
          fill="white"
        />
        <path
          d="M259.907 130.391L151.553 129.398V123.467L259.907 124.741V130.391Z"
          fill="white"
        />
        <path
          d="M136.663 139.459L113.247 139.348V133.317L136.663 133.489V139.459Z"
          fill="white"
        />
        <path
          d="M259.907 140.044L151.553 139.53V133.599L259.907 134.393V140.044Z"
          fill="white"
        />
        <path
          d="M136.663 149.657L113.247 149.65V143.619L136.663 143.687V149.657Z"
          fill="white"
        />
        <path
          d="M259.907 149.693L151.553 149.661V143.729L259.907 144.044V149.693Z"
          fill="white"
        />
        <path
          d="M174.559 159.699L151.913 159.792V153.862L174.559 153.827V159.699Z"
          fill="white"
        />
        <path
          d="M293.882 159.207L188.965 159.639V153.805L293.882 153.645V159.207Z"
          fill="white"
        />
        <path
          d="M97.8503 170.384L73.6338 170.59V164.457L97.8503 164.313V170.384Z"
          fill="white"
        />
        <path
          d="M225.171 169.294L113.247 170.251V164.221L225.171 163.554V169.294Z"
          fill="white"
        />
        <path
          d="M136.663 180.25L113.247 180.554V174.523L136.663 174.28V180.25Z"
          fill="white"
        />
        <path
          d="M259.907 178.65L151.553 180.056V174.125L259.907 172.999V178.65Z"
          fill="white"
        />
        <path
          d="M136.663 190.447L113.247 190.855V184.825L136.663 184.478V190.447Z"
          fill="white"
        />
        <path
          d="M259.907 188.3L151.553 190.188V184.257L259.907 182.65V188.3Z"
          fill="white"
        />
        <path
          d="M97.8503 201.493L73.6338 202.022V195.889L97.8503 195.423V201.493Z"
          fill="white"
        />
        <path
          d="M225.171 198.71L113.247 201.157V195.126L225.171 192.971V198.71Z"
          fill="white"
        />
        <path
          d="M136.663 210.842L113.247 211.457V205.426L136.663 204.872V210.842Z"
          fill="white"
        />
        <path
          d="M259.907 207.603L151.553 210.451V204.52L259.907 201.953V207.603Z"
          fill="white"
        />
      </g>
      <path
        d="M230.899 264.001C232.505 272.355 227.337 279.026 222.91 283.277C222.152 283.094 221.66 283.194 220.85 283.189C213.533 283.153 210.509 281.494 203.369 280.258C201.832 279.992 197.627 277.751 195.971 276.716L192.916 274.73C192.916 274.73 191.514 255.035 202.085 252.319C212.657 249.602 213.596 230.582 215.273 220.498C219.83 193.138 248.218 202.786 256.877 168.094C259.181 158.865 260.488 140.501 270.097 135.085C281.043 128.914 285.472 138.738 292.914 133.153C304.544 124.421 319.977 124.09 325.794 137.496C331.535 150.729 315.697 170.291 285.584 181.691C264.906 192.322 263.537 196.696 262.182 207.86C261.233 215.675 263.555 237.226 251.301 242.557C239.047 247.887 229.062 254.452 230.899 264.001Z"
        fill="url(#paint2_linear_6897_457)"
      />
      <mask
        id="mask0_6897_457"
        maskUnits="userSpaceOnUse"
        x="193"
        y="126"
        width="134"
        height="163"
      >
        <path
          d="M216.907 288.299L193.308 275.538C193.308 275.538 191.51 255.042 202.079 252.325C212.649 249.609 213.59 230.589 215.268 220.505C219.824 193.144 248.212 202.793 256.871 168.101C259.175 158.872 260.482 140.508 270.091 135.092C281.038 128.921 285.466 138.745 292.908 133.16C304.538 124.428 319.972 124.096 325.788 137.503C331.529 150.736 315.691 170.298 285.579 181.698C264.901 192.329 263.531 196.703 262.176 207.867C261.228 215.682 263.549 237.233 251.295 242.563C239.042 247.894 229.057 254.46 230.893 264.009C233.693 278.531 216.907 288.299 216.907 288.299Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_6897_457)">
        <g opacity="0.5">
          <path
            d="M206.952 281.45L206.455 281.26C207.594 278.303 208.78 275.335 209.979 272.439C218.857 250.995 229.409 231.155 241.352 213.471C251.471 198.486 262.731 184.836 274.806 172.9C284.52 163.301 294.815 154.751 305.408 147.492C312.854 142.389 320.369 137.97 327.745 134.359C329.03 133.729 330.379 133.156 331.684 132.606C333.423 131.869 335.221 131.107 336.88 130.203L337.135 130.67C335.453 131.587 333.643 132.355 331.892 133.097C330.593 133.647 329.25 134.216 327.978 134.84C320.626 138.439 313.133 142.845 305.708 147.935C295.142 155.178 284.87 163.706 275.179 173.286C263.125 185.202 251.893 198.82 241.792 213.776C229.869 231.43 219.33 251.238 210.469 272.648C209.273 275.534 208.089 278.498 206.952 281.45Z"
            fill="#FCFDFE"
          />
          <path
            d="M261.087 188.407L260.673 188.072C275.067 170.359 276.017 131.258 276.025 130.865L276.557 130.876C276.549 131.271 275.595 170.552 261.087 188.407Z"
            fill="#FCFDFE"
          />
          <path
            d="M225.164 241.487L224.715 241.201C233.088 228.019 262.76 223.614 263.059 223.57L263.134 224.097C262.839 224.139 233.407 228.508 225.164 241.487Z"
            fill="#FCFDFE"
          />
          <path
            d="M221.059 249.377L220.567 249.175C224.423 239.739 224.646 224.809 221.187 207.137C221.012 206.248 221.012 206.248 221.05 206.154L221.544 206.349L221.296 206.251H221.562C221.562 206.312 221.632 206.647 221.709 207.036C225.187 224.808 224.956 239.841 221.059 249.377Z"
            fill="#FCFDFE"
          />
          <path
            d="M209.105 276.085L208.692 275.751C215.738 267.009 232.392 267.008 232.559 267.009V267.54C232.391 267.54 215.991 267.543 209.105 276.085Z"
            fill="#FCFDFE"
          />
          <path
            d="M206.162 281.297C206.347 272.244 203.942 262.838 198.802 252.558L199.278 252.324C204.455 262.691 206.88 272.175 206.693 281.315L206.162 281.297Z"
            fill="#FCFDFE"
          />
          <path
            d="M299.539 152.308L299.146 151.951C307.698 142.549 314.503 130.689 316.739 126.792C317.28 125.848 317.471 125.517 317.556 125.413L317.964 125.754C317.9 125.834 317.59 126.373 317.198 127.056C314.959 130.966 308.131 142.863 299.539 152.308Z"
            fill="#FCFDFE"
          />
          <path
            d="M283.374 165.54L283.103 165.082C293.364 159.018 316.428 165.029 317.406 165.288L317.27 165.802C317.032 165.738 293.442 159.588 283.374 165.54Z"
            fill="#FCFDFE"
          />
        </g>
      </g>
      <path
        d="M204.495 291.022L225.507 303.83C225.507 303.83 236.033 295.463 235.52 284.718C234.475 262.792 253.744 263.408 261.863 262.457C274.095 261.025 284.251 254.427 274.547 242.947C264.844 231.468 288.586 220.05 308.677 220.585C328.336 221.107 340.607 186.46 298.162 181.425C272.811 178.417 263.115 201.322 255.458 211.724C247.802 222.126 230.918 213.075 228.329 229.902C226.119 244.271 230.098 258.161 218.37 261.82C206.642 265.479 200.538 273.873 204.495 291.022Z"
        fill="url(#paint3_linear_6897_457)"
      />
      <mask
        id="mask1_6897_457"
        maskUnits="userSpaceOnUse"
        x="203"
        y="181"
        width="124"
        height="123"
      >
        <path
          d="M204.495 291.022L225.507 303.83C225.507 303.83 236.033 295.463 235.52 284.718C234.476 262.792 253.745 263.408 261.863 262.457C274.096 261.025 284.251 254.427 274.548 242.947C264.844 231.468 288.587 220.05 308.677 220.585C328.336 221.107 340.607 186.46 298.163 181.425C272.812 178.417 263.115 201.322 255.459 211.724C247.802 222.126 230.919 213.075 228.33 229.902C226.119 244.271 230.099 258.161 218.371 261.82C206.643 265.479 200.538 273.873 204.495 291.022Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_6897_457)">
        <path
          d="M204.495 291.021L225.507 303.83C225.507 303.83 236.033 295.463 235.52 284.718C234.476 262.792 253.745 263.408 261.863 262.457C274.096 261.024 282.85 254.874 276.202 243.37C268.681 230.354 288.585 220.048 308.679 220.581C328.338 221.103 340.61 186.456 298.165 181.421C272.814 178.413 263.118 201.318 255.461 211.72C247.804 222.122 230.921 213.071 228.332 229.898C226.122 244.267 230.101 258.158 218.373 261.816C206.645 265.475 200.538 273.873 204.495 291.021Z"
          fill="url(#paint4_linear_6897_457)"
        />
        <g opacity="0.5">
          <path
            d="M212.4 298.516L212.885 298.702C213.002 298.402 224.642 268.364 245.163 242.066C264.066 217.839 294.593 191.921 332.664 206.031L332.845 205.544C315.895 199.262 299.008 200.254 282.65 208.489C269.585 215.069 256.832 226.26 244.741 241.755C224.187 268.113 212.516 298.215 212.4 298.516Z"
            fill="#BEB8F2"
          />
          <path
            d="M275.97 212.226L276.443 212.44C283.55 196.676 301.818 179.261 302.002 179.093L301.644 178.716C301.46 178.891 283.118 196.37 275.97 212.226Z"
            fill="#BEB8F2"
          />
          <path
            d="M287.019 207.037C307.622 206.836 318.852 219.804 318.963 219.936L319.359 219.599C319.242 219.466 307.869 206.314 287.013 206.515L287.019 207.037Z"
            fill="#BEB8F2"
          />
          <path
            d="M232.684 259.99C250.392 242.099 281.964 254.309 282.26 254.453L282.517 254.002C282.239 253.833 250.18 241.575 232.314 259.624L232.684 259.99Z"
            fill="#BEB8F2"
          />
          <path
            d="M237.949 251.044L238.438 251.219C243.831 236.088 234.897 217.969 234.806 217.787L234.339 218.021C234.432 218.199 243.267 236.125 237.949 251.044Z"
            fill="#BEB8F2"
          />
          <path
            d="M214.821 294.015C220.429 286.265 235.973 286.904 236.13 286.911L236.154 286.393C235.505 286.361 220.167 285.731 214.4 293.711L214.821 294.015Z"
            fill="#BEB8F2"
          />
          <path
            d="M217.482 286.865L217.964 287.057C220.758 280.031 215.46 267.08 213.719 262.821C213.472 262.22 213.26 261.7 213.241 261.601L212.729 261.693C212.755 261.834 212.895 262.18 213.238 263.018C214.957 267.226 220.197 280.028 217.482 286.865Z"
            fill="#BEB8F2"
          />
        </g>
      </g>
      <path
        d="M241.608 310.475L241.304 310.533C235.299 311.674 228.535 311.86 220.977 310.485L216.615 309.369C216.148 305.622 216.382 301.974 221.142 301.605C228.529 301.032 231.339 288.871 230.372 284.138C229.174 278.286 226.666 264.602 238.071 263.536C247.567 262.648 253.875 264.016 256.974 252.708C260.072 241.401 267.863 235.615 278.093 238.541C288.857 241.621 300.958 229.022 308.852 235.82C322.607 247.666 303.027 269.824 282.488 273.027C261.949 276.229 271.345 285.41 269.83 294.888C267.761 307.822 251.071 301.351 245.414 305.385C243.505 306.75 242.325 308.568 241.608 310.475Z"
        fill="url(#paint5_linear_6897_457)"
      />
      <mask
        id="mask2_6897_457"
        maskUnits="userSpaceOnUse"
        x="216"
        y="233"
        width="98"
        height="85"
      >
        <path
          d="M240.587 317.626L216.833 312.29C216.206 308.617 215.832 302.016 221.145 301.604C228.532 301.03 231.341 288.87 230.374 284.136C229.177 278.284 226.668 264.601 238.074 263.534C247.569 262.646 253.878 264.014 256.976 252.707C260.074 241.399 267.865 235.613 278.096 238.54C288.86 241.619 300.961 229.021 308.854 235.82C322.609 247.665 303.03 269.823 282.491 273.025C261.952 276.227 271.348 285.408 269.833 294.886C267.764 307.82 251.073 301.349 245.416 305.383C240.989 308.546 240.477 314.122 240.587 317.626Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_6897_457)">
        <g opacity="0.5">
          <path
            d="M230.241 311.378L229.758 311.166C245.322 275.876 277.937 248.314 314.875 239.234L315.001 239.746C278.219 248.788 245.741 276.236 230.241 311.378Z"
            fill="#FCFDFE"
          />
          <path
            d="M240.375 292.865L239.86 292.748C243.117 277.945 232.984 263.618 232.881 263.474L233.31 263.167C233.416 263.315 243.684 277.828 240.375 292.865Z"
            fill="#FCFDFE"
          />
          <path
            d="M233.281 304.965L232.882 304.62C242.915 292.98 270.595 297.112 270.873 297.155L270.793 297.676C270.517 297.634 243.124 293.546 233.281 304.965Z"
            fill="#FCFDFE"
          />
          <path
            d="M265.731 264.826L265.302 264.517C272.443 254.637 269.013 236.261 268.978 236.077L269.496 235.978C269.532 236.163 273.004 254.764 265.731 264.826Z"
            fill="#FCFDFE"
          />
          <path
            d="M285.987 251.056L285.812 250.559C298.934 246.002 315.971 253.861 316.141 253.946L315.918 254.424C315.752 254.342 298.906 246.57 285.987 251.056Z"
            fill="#FCFDFE"
          />
        </g>
      </g>
      <path
        d="M325.261 297.596L6.13623 294.816V303.043C6.13623 305.275 7.52878 307.173 9.40732 307.511L99.6519 323.702C100.72 323.894 101.809 323.945 102.89 323.854L321.793 305.396C323.747 305.23 325.261 303.327 325.261 301.034V297.596Z"
        fill="url(#paint6_linear_6897_457)"
      />
      <path
        d="M222.53 284.264L9.64934 299.304C5.83969 299.573 2.74268 296.713 2.74268 292.914V140.734C2.7467 138.906 3.47659 137.155 4.77181 135.865C6.06702 134.575 7.8215 133.853 9.64934 133.856H222.53C225.67 133.856 228.207 136.639 228.207 140.074V277.646C228.207 281.08 225.67 284.043 222.53 284.264Z"
        fill="url(#paint7_linear_6897_457)"
      />
      <path
        d="M225.925 284.264L13.0429 299.304C9.23325 299.573 6.13623 296.713 6.13623 292.914V140.734C6.14025 138.906 6.87014 137.155 8.16536 135.865C9.46058 134.575 11.2151 133.853 13.0429 133.856H225.925C229.063 133.856 231.601 136.639 231.601 140.074V277.646C231.601 281.08 229.063 284.043 225.925 284.264Z"
        fill="url(#paint8_linear_6897_457)"
      />
      <path
        d="M219.293 274.566L21.0157 287.634C17.9152 287.838 15.3965 285.49 15.3965 282.39V149.151C15.3986 147.66 15.9907 146.23 17.0435 145.173C18.0963 144.117 19.5243 143.52 21.0157 143.512L219.293 142.694C221.881 142.684 223.976 144.964 223.976 147.789V269.143C223.976 271.968 221.881 274.395 219.293 274.566Z"
        fill="#EFF2F6"
      />
      <path
        d="M127.002 138.018C127.002 139.432 125.907 140.582 124.549 140.589C123.192 140.596 122.096 139.447 122.096 138.03C122.096 136.613 123.195 135.46 124.549 135.46C125.903 135.46 127.002 136.604 127.002 138.018Z"
        fill="white"
      />
      <path
        d="M220.613 283.583L6.13623 299.422L99.6519 316.2C100.72 316.393 101.809 316.444 102.89 316.352L325.261 297.596L224.024 283.694C222.894 283.537 221.751 283.5 220.613 283.583Z"
        fill="url(#paint9_linear_6897_457)"
      />
      <path
        opacity="0.3"
        d="M37.4326 299.816L215.991 286.437L271.252 293.966L94.2327 308.269L37.4326 299.816Z"
        fill="url(#paint10_linear_6897_457)"
      />
      <g opacity="0.48">
        <path
          d="M174.635 242.528C158.223 233.698 145.319 244.886 132.865 233.848C125.316 227.155 129.023 222.165 119.732 212.162C109.443 201.084 102.743 204.802 92.403 194.838C79.3244 182.23 85.8666 172.421 74.6036 163.371C60.9351 152.389 46.2059 162.553 33.1986 152.023C27.5911 147.481 24.5116 140.878 22.9041 133.856H10.1772C9.10618 133.856 8.07887 134.281 7.32111 135.038C6.56335 135.795 6.13716 136.822 6.13623 137.893V295.647C6.13623 297.876 7.94701 299.554 10.1772 299.397L227.139 284C228.203 283.926 229.147 283.3 229.754 282.402C213.865 281.818 204.114 276.499 197.877 270.996C185.676 260.223 187.792 249.605 174.635 242.528Z"
          fill="white"
        />
      </g>
      <mask
        id="mask3_6897_457"
        maskUnits="userSpaceOnUse"
        x="26"
        y="123"
        width="207"
        height="163"
      >
        <path
          d="M180.217 277.143L60.7484 285.017C60.3143 285.053 59.8774 284.997 59.4659 284.855C59.0543 284.712 58.677 284.485 58.3582 284.188C58.0393 283.891 57.7859 283.531 57.6141 283.131C57.4424 282.73 57.356 282.298 57.3605 281.863L26.7432 251.115V171.468L201.115 123.678C202.676 123.672 258.78 138.291 217.704 227.947L183.038 273.876C183.038 275.578 181.779 277.04 180.217 277.143Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_6897_457)">
        <path
          d="M203.813 165.362C204.808 162.492 204.409 160.923 203.813 160.005C202.981 158.728 201.077 157.668 197.563 155.098C197.329 154.93 196.936 154.631 196.986 154.464C197.111 154.055 199.023 154.266 200.688 155.098C203.1 156.305 203.609 156.397 205.037 156.825C207.572 157.579 209.801 156.95 213.188 155.99C215.121 155.443 216.991 154.695 218.769 153.759C218.769 153.759 221.222 152.642 221.894 153.759L218.323 155.544C218.323 155.544 221.448 154.205 223.229 155.098L216.979 157.777C216.979 157.777 221.667 156.438 221.886 157.33C218.839 159.503 216.152 160.245 214.073 160.455C210.874 160.779 208.465 159.908 207.599 161.125C206.898 162.107 207.626 163.861 208.493 165.367L203.813 165.362Z"
          fill="url(#paint11_linear_6897_457)"
        />
        <path
          d="M155.853 186.906C158.987 182.211 162.837 179.398 165.624 177.701C167.12 178.36 168.564 179.129 169.947 180.001C175.038 183.218 175.597 185.535 179.918 188.218C182.21 189.641 185.047 191.403 188.691 191.162C192.885 190.886 195.759 188.113 196.954 186.957C205.148 179.052 201.942 164.079 201.59 162.529L210.887 162.277C212.522 165.136 221.5 183.364 214.907 201.727C205.465 228.026 167.053 228.253 155.443 216.145C148.519 208.941 148.56 197.833 155.853 186.906Z"
          fill="url(#paint12_linear_6897_457)"
        />
        <path
          d="M158.513 266.654C156.568 314.611 160.381 362.628 169.87 409.677L162.402 411.264C156.485 405.125 149.118 395.565 146.017 382.447C144.093 374.306 145.097 370.227 144.132 360.288C142.594 344.461 138.22 335.979 133.922 323.052C130.06 311.438 125.668 294.46 124.027 271.991L158.513 266.654Z"
          fill="url(#paint13_linear_6897_457)"
        />
        <path
          opacity="0.3"
          d="M155.073 367.227C152.333 356.371 147.051 353.501 146.863 343.253C146.73 336.01 149.335 335.412 149.162 328.473C148.9 317.95 142.737 312.431 139.637 305.809C136.366 298.817 135.113 287.585 143.93 268.909L124.027 271.989C125.663 294.458 130.06 311.436 133.922 323.05C138.22 335.977 142.594 344.458 144.132 360.286C145.097 370.225 144.093 374.305 146.017 382.445C149.118 395.563 156.485 405.123 162.402 411.262L164.301 410.858C157.905 403.223 155.648 396.406 155.072 390.873C154.14 381.874 157.838 378.174 155.073 367.227Z"
          fill="url(#paint14_linear_6897_457)"
        />
        <path
          d="M133.628 265.309C130.669 292.579 127.957 320.369 125.493 348.678C123.654 369.918 122.012 390.895 120.566 411.609L112.995 412.135C109.892 405.555 107.62 398.614 106.232 391.473C104.129 380.595 105.088 374.253 104.507 363.066C103.857 350.516 102.088 347.696 99.5401 331.774C94.3659 299.462 91.7794 283.305 99.984 273.779C102.648 270.683 110.888 263.013 133.628 265.309Z"
          fill="url(#paint15_linear_6897_457)"
        />
        <path
          d="M68.2705 180.506C81.9028 175.884 102.703 170.763 128.142 171.811C140.812 172.337 153.378 174.333 165.588 177.758C166.945 185.35 168.302 192.941 169.659 200.532C170.07 210.567 169.922 220.617 169.217 230.635C168.362 242.817 167.525 243 165.565 260.192C162.371 288.23 163.708 295.551 158.074 298.919C154.409 301.11 153.083 298.466 134.383 296.612C121.843 295.369 115.443 294.792 109.094 296.94C100.449 299.861 97.5526 304.779 93.9859 303.181C90.5653 301.647 90.1307 295.742 89.716 285.446C89.0793 269.558 89.1178 258.766 89.0594 254.901C88.7896 237.171 84.3 223.632 78.5452 206.294C75.6318 197.503 72.2009 188.892 68.2705 180.506Z"
          fill="url(#paint16_linear_6897_457)"
        />
        <path
          d="M126.459 154.246C126.312 157.741 125.814 161.212 124.973 164.607C124.286 167.351 123.86 168.047 123.337 168.601C122.545 169.437 121.376 170.111 114.906 171.353C111.385 172.029 110.94 171.981 110.844 172.322C110.377 173.984 119.549 180.223 129.411 179.365C135.448 178.839 141.081 175.711 140.842 173.899C140.604 172.088 134.567 172.724 132.957 169.361C131.838 167.024 133.401 163.87 135.188 161.284C133.75 158.215 127.891 157.315 126.459 154.246Z"
          fill="url(#paint17_linear_6897_457)"
        />
        <path
          d="M137.483 156.602C139.002 154.799 139.26 151.524 137.741 149.359C136.434 147.5 134.237 147.128 132.929 146.905C129.126 146.259 124.211 147.599 122.343 151.031C121.236 153.065 123.16 157.519 123.454 158.317C123.788 159.219 124.464 159.557 125.069 160.258C125.907 161.23 127.218 162.775 129.355 165.355C129.616 164.477 129.953 163.623 130.363 162.804C130.546 162.438 131.282 161.009 132.343 159.849C134.464 157.529 136.049 158.31 137.483 156.602Z"
          fill="url(#paint18_linear_6897_457)"
        />
        <path
          d="M126.23 163.121C126.23 163.121 128.526 167.382 133.554 167.326C138.054 167.274 139.248 157.25 136.498 154.964C135.579 154.2 133.681 153.796 132.102 154.155C131.167 154.363 130.836 154.729 129.306 155.04C127.892 155.327 127.508 155.157 127.069 155.483C126.518 155.897 126.076 156.963 126.797 159.95C125.987 159.037 124.978 158.65 124.226 158.955C123.971 159.077 123.754 159.267 123.599 159.505C122.914 160.618 123.886 162.142 126.23 163.121Z"
          fill="url(#paint19_linear_6897_457)"
        />
        <path
          d="M80.8483 254.919L85.3612 254.667C85.3612 254.667 90.3064 250.529 92.5565 250.787C94.8065 251.045 98.1734 252.607 98.5846 252.934C98.9958 253.261 97.3521 255.097 95.7107 254.92C94.0693 254.744 90.4034 255.627 89.7527 257.073C89.102 258.52 86.2479 259.395 85.7701 258.545L79.7046 259.75L80.8483 254.919Z"
          fill="url(#paint20_linear_6897_457)"
        />
        <path
          d="M82.2139 253.34C82.5305 257.042 81.7233 259.557 80.3774 261.284C74.4194 268.928 54.1422 265.801 42.0941 255.559C33.178 247.983 28.4536 236.22 28.6277 225.674C28.9688 205.102 48.0346 184.944 76.3949 177.979L88.9886 215.626C71.5046 215.893 60.8818 229.074 62.4566 238.483C63.6306 245.493 71.8049 251.798 82.2139 253.34Z"
          fill="url(#paint21_linear_6897_457)"
        />
        <path
          opacity="0.3"
          d="M150.805 289.718C141.606 285.794 141.66 277.672 133.07 274.282C120.81 269.445 107.267 280.682 105.149 277.566C102.949 274.324 119.125 264.382 116.973 254.576C115.262 246.784 103.971 248.164 97.9232 237.497C92.1871 227.379 97.7491 218.024 92.6661 214.507C90.8448 213.246 87.631 212.748 81.5386 215.43C85.7863 228.699 88.8331 240.399 89.0539 254.904C89.1123 258.769 89.0737 269.564 89.7104 285.449C90.124 295.745 90.5598 301.65 93.9804 303.184C97.547 304.782 100.441 299.87 109.088 296.943C115.438 294.795 121.837 295.372 134.377 296.615C153.077 298.469 154.403 301.113 158.068 298.922C160.455 297.496 161.589 295.351 162.348 291.231C158.605 291.635 154.629 291.35 150.805 289.718Z"
          fill="url(#paint22_linear_6897_457)"
        />
      </g>
      <path
        d="M207.614 154.97C204.556 155.066 201.535 154.285 198.907 152.717C190.963 147.953 179.219 140.087 168.65 129.789C155.25 116.721 147.55 103.438 145.863 90.3948L145.836 90.1904L145.509 34.534C145.509 23.535 152.683 14.6061 166.153 8.73918C177.282 3.89213 192.032 1.49606 207.614 1.94116C223.019 2.38626 237.271 5.56505 247.815 10.8654C260.354 17.1599 266.932 26.0047 266.932 36.492L266.634 89.6717L266.61 89.868C265.07 102.358 257.983 115.269 245.458 128.325C235.412 138.796 224.061 147.096 216.264 152.237C213.691 153.934 210.696 154.881 207.614 154.97Z"
        fill="url(#paint23_linear_6897_457)"
      />
      <path
        d="M210.67 154.97C207.649 155.065 204.664 154.283 202.079 152.717C194.236 147.953 182.65 140.087 172.225 129.789C158.999 116.721 151.398 103.438 149.734 90.3948L149.709 90.1904L149.386 34.534C149.386 23.535 156.466 14.6061 169.757 8.73918C180.738 3.89213 195.294 1.49606 210.67 1.94116C225.868 2.38626 239.93 5.56272 250.339 10.8595C262.712 17.154 269.204 25.9988 269.204 36.4862L268.909 89.6659L268.886 89.8621C267.367 102.352 260.374 115.263 248.014 128.32C238.102 138.791 226.899 147.09 219.206 152.231C216.675 153.928 213.716 154.877 210.67 154.97Z"
        fill="url(#paint24_linear_6897_457)"
      />
      <path
        d="M153.06 34.574L153.385 89.9488C157.031 118.203 190.235 141.078 204.696 149.769C208.839 152.259 214.136 152.105 218.248 149.404C232.408 140.088 263.795 116.63 267.149 89.4652L267.445 36.4268C267.446 -2.91966 153.06 -6.51201 153.06 34.574Z"
        fill="url(#paint25_linear_6897_457)"
      />
      <path
        d="M211.491 141.233C211.113 141.243 210.741 141.145 210.418 140.949C204.46 137.344 192.922 129.791 182.879 119.927C172.033 109.273 165.777 98.9143 164.343 89.1851L164.021 34.7262C164.034 29.5018 168.171 25.0298 176.279 21.4514C185.33 17.456 198.183 15.3602 211.487 15.6756C224.657 15.9887 237.136 18.6406 245.781 22.9304C253.428 26.7249 257.294 31.1981 257.307 36.2484L257.01 88.8195C255.671 98.2343 249.8 108.384 239.487 119.052C229.79 129.084 218.471 137.036 212.553 140.899C212.237 141.107 211.869 141.222 211.491 141.233Z"
        fill="url(#paint26_linear_6897_457)"
      />
      <path
        d="M210.133 137.512C204.119 133.808 193.427 126.656 184.096 117.475C173.904 107.443 168.004 97.8158 166.617 88.9091L166.302 34.7668C166.311 31.0285 169.746 27.6043 176.483 24.6206C185.057 20.8238 197.342 18.8238 210.128 19.1112C222.797 19.3951 234.738 21.8975 242.943 25.9571C249.318 29.1114 252.544 32.553 252.552 36.1968L252.257 88.5762C250.955 97.2212 245.392 106.67 235.662 116.72C226.632 126.055 216.119 133.557 210.133 137.512Z"
        fill="url(#paint27_linear_6897_457)"
      />
      <g>
        <path
          d="M231.648 66.6242L191.154 66.9841C188.042 67.0121 185.506 69.4374 185.506 72.4036V98.3479C185.506 101.294 188.033 103.578 191.134 103.452L231.484 101.799C234.355 101.682 236.683 99.3059 236.697 96.4904L236.826 71.7061C236.84 68.8743 234.529 66.5985 231.648 66.6242Z"
          fill="white"
        />
        <path
          d="M231.079 64.0497C230.793 64.0541 230.509 64.0017 230.243 63.8953C229.977 63.7889 229.735 63.6307 229.53 63.43C229.326 63.2292 229.164 62.9898 229.053 62.7258C228.942 62.4618 228.885 62.1783 228.884 61.8919L228.909 56.2399C228.934 50.5634 224.236 45.894 218.395 45.8367L205.177 45.7047C199.124 45.6451 194.148 50.3648 194.142 56.2189V62.0496C194.142 63.288 193.09 64.2973 191.785 64.3067C190.48 64.316 189.421 63.3137 189.421 62.0706V56.2166C189.421 47.8473 196.547 41.1275 205.192 41.2467L218.416 41.4313C226.723 41.5481 233.359 48.1954 233.32 56.2411L233.292 61.8709C233.284 62.4522 233.048 63.007 232.633 63.415C232.219 63.8229 231.661 64.0509 231.079 64.0497Z"
          fill="white"
        />
        <path
          d="M216.943 81.5015C216.952 78.6358 214.49 76.3706 211.49 76.561C208.831 76.7292 206.625 78.8765 206.477 81.4524C206.422 82.3907 206.639 83.3249 207.102 84.1427C207.565 84.9605 208.255 85.6271 209.088 86.0623C209.424 86.2461 209.693 86.5322 209.857 86.8791C210.02 87.2261 210.068 87.6159 209.995 87.9923L208.827 94.5461C208.678 95.3803 209.339 96.1139 210.212 96.0835L213.173 95.9796C214.04 95.9492 214.699 95.1735 214.555 94.3534L213.423 87.8614C213.288 87.0869 213.687 86.3182 214.389 85.8953C215.913 84.9782 216.936 83.3438 216.943 81.5015Z"
          fill="url(#paint28_linear_6897_457)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6897_457"
          x1="212.449"
          y1="70.4011"
          x2="86.3883"
          y2="328.948"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6897_457"
          x1="225.624"
          y1="-44.247"
          x2="181.176"
          y2="54.0398"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6897_457"
          x1="313.571"
          y1="122.243"
          x2="173.54"
          y2="299.162"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6897_457"
          x1="203.291"
          y1="242.491"
          x2="326.713"
          y2="242.491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6897_457"
          x1="313.258"
          y1="191.013"
          x2="199.218"
          y2="283.885"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6897_457"
          x1="302.86"
          y1="215.078"
          x2="177.512"
          y2="395.265"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E38DDD" />
          <stop offset="1" stopColor="#9571F6" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_6897_457"
          x1="140.933"
          y1="309.863"
          x2="118.516"
          y2="319.278"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_6897_457"
          x1="110.999"
          y1="211.06"
          x2="86.6694"
          y2="221.279"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_6897_457"
          x1="6.13623"
          y1="216.592"
          x2="231.601"
          y2="216.592"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_6897_457"
          x1="6.13623"
          y1="299.968"
          x2="325.261"
          y2="299.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_6897_457"
          x1="37.4326"
          y1="297.353"
          x2="271.252"
          y2="297.353"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_6897_457"
          x1="202.463"
          y1="178.568"
          x2="214.007"
          y2="142.651"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_6897_457"
          x1="208.864"
          y1="171.174"
          x2="164.364"
          y2="218.044"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_6897_457"
          x1="176.255"
          y1="388.261"
          x2="138.904"
          y2="290.254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_6897_457"
          x1="169.513"
          y1="390.831"
          x2="132.161"
          y2="292.824"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_6897_457"
          x1="152.354"
          y1="393.558"
          x2="103.269"
          y2="300.866"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_6897_457"
          x1="95.2009"
          y1="283.053"
          x2="160.23"
          y2="132.796"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_6897_457"
          x1="126.359"
          y1="146.737"
          x2="125.717"
          y2="179.126"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_6897_457"
          x1="119.677"
          y1="176.79"
          x2="132.468"
          y2="148.53"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40447E" />
          <stop offset="1" stopColor="#3C3B6B" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_6897_457"
          x1="143.509"
          y1="197.715"
          x2="139.02"
          y2="184.246"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_6897_457"
          x1="87.6907"
          y1="251.869"
          x2="90.7083"
          y2="261.082"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_6897_457"
          x1="45.371"
          y1="261.488"
          x2="110.4"
          y2="111.231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_6897_457"
          x1="107.155"
          y1="288.227"
          x2="172.184"
          y2="137.968"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_6897_457"
          x1="277.443"
          y1="-2.31709"
          x2="242.447"
          y2="31.3202"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_6897_457"
          x1="173.428"
          y1="5.34776"
          x2="292.389"
          y2="199.918"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_6897_457"
          x1="236.508"
          y1="116.128"
          x2="139.894"
          y2="-78.143"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC444" />
          <stop offset="1" stopColor="#F36F56" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_6897_457"
          x1="164.025"
          y1="78.4396"
          x2="257.311"
          y2="78.4396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_6897_457"
          x1="225.651"
          y1="149.859"
          x2="198.717"
          y2="3.06768"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF9085" />
          <stop offset="1" stopColor="#FB6FBB" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_6897_457"
          x1="206.466"
          y1="86.317"
          x2="216.942"
          y2="86.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#311944" />
          <stop offset="1" stopColor="#893976" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default UnauthorizedImg;
