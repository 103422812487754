import React from "react";
import "./NotFound.scss";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="notFoundScreen">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="notFoundMessage">
              <img
                src={require("../../utils/Images/errorImg.png")}
                alt={t("GENERAL.SOMETHING_WENT_WRONG")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
