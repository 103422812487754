const SignoutIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.5 10.1667L1 7.66667M1 7.66667L3.5 5.16667M1 7.66667H12.6667M12.6667 14.3333H15.1667C15.3877 14.3333 15.5996 14.2455 15.7559 14.0893C15.9122 13.933 16 13.721 16 13.5V1.83333C16 1.61232 15.9122 1.40036 15.7559 1.24408C15.5996 1.0878 15.3877 1 15.1667 1H12.6667"
        stroke="#5A5A5A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SignoutIcon;
