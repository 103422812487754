const NextIcon = () => {
  return (
    <div>
      <svg
        width="35"
        height="34"
        viewBox="0 0 48 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M31.1108 3L45.333 17.2222M45.333 17.2222L31.1108 31.4444M45.333 17.2222L2.66634 17.2222"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default NextIcon;
