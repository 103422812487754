const TestCaseRightIcon = () => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.2039 1.5473L9.02767 15.846L3.06778 10.8372L1.62303 12.5436L9.29306 18.9886L20.9156 2.9847L19.2039 1.5473Z"
        fill="#35D4AE"
        stroke="#35D4AE"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default TestCaseRightIcon;
