import { useTranslation } from "react-i18next";
import FilterButton from "../../../components/FilterButton/FilterButton";
interface Filter {
  filters: any;
  handleFilter: (value: any) => void;
  filterCountValue: any;
}
const Filter: React.FC<Filter> = ({
  filters,
  handleFilter,
  filterCountValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="scoreFilter">
      <FilterButton
        handleFilter={handleFilter}
        name="all"
        isActive={filters?.all}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.ALL")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.all > 99
                ? "99+"
                : filterCountValue?.all
              : null}
          </span>
        </div>
      </FilterButton>
      <span className="pipeSpace" />
      <FilterButton
        handleFilter={handleFilter}
        name="submitted"
        isActive={filters?.submitted}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.SUBMITTED")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.submitted > 99
                ? "99+"
                : filterCountValue?.submitted
              : null}
          </span>
        </div>
      </FilterButton>
      {/* For future use */}
      {/* <FilterButton
        handleFilter={handleFilter}
        name="initiated"
        isActive={filters?.initiated}
      >
 <div className="filterNameValue">
          <span>
            {t("GENERAL.INITIATED")}
          </span>
          <span className="countBubble">
            {filterCountValue ? filterCountValue?.initiated : null}
          </span>
        </div>
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="notInitiated"
        isActive={filters?.notInitiated}
      >
 <div className="filterNameValue">
          <span>
            {t("GENERAL.NOT.INITIATED")}
          </span>
          <span className="countBubble">
            {filterCountValue ? filterCountValue?.notInititated : null}
          </span>
        </div>
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="deffered"
        isActive={filters?.deffered}
      >
 <div className="filterNameValue">
          <span>
            {t("GENERAL.DEFFERED")}
          </span>
          <span className="countBubble">
            {filterCountValue ? filterCountValue?.deferred : null}
          </span>
        </div>
      </FilterButton> */}
      {/* Combine initiated, notInitiated, and deferred under "Others" */}
      <FilterButton
        handleFilter={handleFilter}
        name="others"
        isActive={filters?.others}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.OTHERS")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.others > 99
                ? "99+"
                : filterCountValue?.others
              : null}
          </span>
        </div>
      </FilterButton>
      <span className="pipeSpace" />
      <FilterButton
        handleFilter={handleFilter}
        name="reviewed"
        isActive={filters?.reviewed}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.REVIEWED")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.reviewed > 99
                ? "99+"
                : filterCountValue?.reviewed
              : null}
          </span>
        </div>
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="selected"
        isActive={filters?.selected}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.SELECTED")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.selected > 99
                ? "99+"
                : filterCountValue?.selected
              : null}
          </span>
        </div>
      </FilterButton>
      <span className="pipeSpace" />
      <FilterButton
        handleFilter={handleFilter}
        name="notified"
        isActive={filters?.notified}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.NOTIFY")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.notified > 99
                ? "99+"
                : filterCountValue?.notified
              : null}
          </span>
        </div>
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="notNotified"
        isActive={filters?.notNotified}
      >
        <div className="filterNameValue">
          <span className="filterNameText">{t("GENERAL.NOT_NOTIFY")}</span>
          <div className="line"></div>
          <span className="countBubble">
            {filterCountValue
              ? filterCountValue?.notNotified > 99
                ? "99+"
                : filterCountValue?.notNotified
              : null}
          </span>
        </div>
      </FilterButton>
    </div>
  );
};

export default Filter;
