import { getCreateAssessmentDraftJson } from "../../../service/DraftJson/DraftJson";
import {
  checkUniqueIdMatch,
  determinePageType,
} from "../../../utils/Common/Constant";
import { setSessionStorageItem } from "../../../utils/SessionStorageMethod/setSessionStorageItem";

const SetDraftJsonInSession = async (
  UserId: string,
  tempAssessmentUId: string | undefined,
  pathname?: any,
) => {
  const tempUniqueIdBySession = localStorage.getItem("tempUniqueId") ?? null;
  const draftRes = await getCreateAssessmentDraftJson(
    UserId,
    tempAssessmentUId,
  );
  if (draftRes?.status === 200) {
    if (checkUniqueIdMatch(tempAssessmentUId, tempUniqueIdBySession)) {
      localStorage.setItem("tempUniqueId", tempAssessmentUId ?? "");
    }
    const finaData = draftRes?.data?.data?.createAssessmentJson;
    setSessionStorageItem("Type", finaData?.template?.assessmentType || "");
    setSessionStorageItem("titleHeadContent", finaData?.template?.title || "");
    setSessionStorageItem("ownPreview", finaData?.build?.assessmentSkill || []);
    setSessionStorageItem(
      "existingPreview",
      finaData?.template?.existingPreview || [],
    );
    setSessionStorageItem(
      "existingData",
      finaData?.template?.existingData || [],
    );
    setSessionStorageItem(
      "createQuestionData",
      finaData?.custom?.AssessmentCustomQuestions || [],
    );
    setSessionStorageItem(
      "maximumExperience",
      finaData?.setting?.maximumExperience || 0,
    );
    setSessionStorageItem(
      "maximumResponse",
      finaData?.setting?.maximumResponse || 0,
    );
    setSessionStorageItem(
      "minimumExperience",
      finaData?.setting?.minimumExperience || null,
    );
    setSessionStorageItem(
      "noOfPositions",
      finaData?.setting?.noOfPositions || 0,
    );
    setSessionStorageItem("createTemplate", finaData?.isTemplate || "");
    setSessionStorageItem("cutOff", finaData?.setting?.cutOff || 0);
    setSessionStorageItem("description", finaData?.setting?.description || "");
    setSessionStorageItem("assessmentId", finaData?.assessmentId || "");
    setSessionStorageItem("instruction", finaData?.setting?.instructions || "");
    setSessionStorageItem(
      "advanceSettingData",
      finaData?.setting?.advanceSettingData || {},
    );
    const activeStep = determinePageType(pathname);
    if (activeStep) {
      setSessionStorageItem("currentStep", activeStep || 1);
    }
  }
};

export default SetDraftJsonInSession;
