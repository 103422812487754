const PlayVideoIcon = () => {
  return (
    <svg
      width="33"
      height="37"
      viewBox="0 0 33 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0L33 18.5001L0 37V0Z" fill="#4D4D4D" />
    </svg>
  );
};

export default PlayVideoIcon;
