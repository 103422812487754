import { useTranslation } from "react-i18next";
import "./Footer.scss";
import packageJson from "../../../package.json";
import LogoTagline from "../../utils/Images/LogoTagline";
import SupportReportPopup from "../SupportReportPopup/SupportReportPopup";
import { useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const Footer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const topRef = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [title, setTitle] = useState("");
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);
  const handleOpenPopup = (title: any) => {
    setTitle(title);
    setIsPopupOpen(true);
  };
  return (
    <>
      <div className="footer">
        <div className="logo">
          <LogoTagline />
        </div>
        <div className="middle">
          <p>
            © 2024, Evluate Global, LLC. &nbsp;| &nbsp;{t("GENERAL.VERSION")}:
            {packageJson.version}
          </p>
        </div>
        <div className="base">
          <button
            className="getSupport"
            onClick={() => handleOpenPopup("support")}
          >
            {" "}
            {t("GET_SUPPORT")}
          </button>
          <button
            className="reportBug"
            onClick={() => handleOpenPopup("report")}
          >
            {" "}
            {t("REPORT_BUG")}
          </button>
        </div>
      </div>
      <SupportReportPopup
        show={isPopupOpen}
        title={title}
        setIsPopupOpen={setIsPopupOpen}
        isPopupOpen={isPopupOpen}
      />
    </>
  );
};

export default Footer;
