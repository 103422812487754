const InstagramIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 20.0909C16.9162 20.0909 20.0909 16.9162 20.0909 13C20.0909 9.0838 16.9162 5.90909 13 5.90909C9.0838 5.90909 5.90909 9.0838 5.90909 13C5.90909 16.9162 9.0838 20.0909 13 20.0909ZM13 17.7273C15.6108 17.7273 17.7273 15.6108 17.7273 13C17.7273 10.3892 15.6108 8.27273 13 8.27273C10.3892 8.27273 8.27273 10.3892 8.27273 13C8.27273 15.6108 10.3892 17.7273 13 17.7273Z"
        fill="url(#paint0_radial_7470_65)"
      />
      <path
        d="M20.0909 4.72727C19.4382 4.72727 18.9091 5.2564 18.9091 5.90909C18.9091 6.56179 19.4382 7.09091 20.0909 7.09091C20.7436 7.09091 21.2727 6.56179 21.2727 5.90909C21.2727 5.2564 20.7436 4.72727 20.0909 4.72727Z"
        fill="url(#paint1_radial_7470_65)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.772862 3.87171C0 5.38854 0 7.37418 0 11.3455V14.6545C0 18.6258 0 20.6115 0.772862 22.1282C1.45269 23.4625 2.53746 24.5473 3.87171 25.2271C5.38854 26 7.37418 26 11.3455 26H14.6545C18.6258 26 20.6115 26 22.1282 25.2271C23.4625 24.5473 24.5473 23.4625 25.2271 22.1282C26 20.6115 26 18.6258 26 14.6545V11.3455C26 7.37418 26 5.38854 25.2271 3.87171C24.5473 2.53746 23.4625 1.45269 22.1282 0.772862C20.6115 0 18.6258 0 14.6545 0H11.3455C7.37418 0 5.38854 0 3.87171 0.772862C2.53746 1.45269 1.45269 2.53746 0.772862 3.87171ZM14.6545 2.36364H11.3455C9.32081 2.36364 7.94448 2.36548 6.88064 2.45239C5.84437 2.53706 5.31445 2.69052 4.94477 2.87887C4.05528 3.3321 3.3321 4.05528 2.87887 4.94477C2.69052 5.31445 2.53706 5.84437 2.45239 6.88064C2.36548 7.94448 2.36364 9.32081 2.36364 11.3455V14.6545C2.36364 16.6792 2.36548 18.0555 2.45239 19.1193C2.53706 20.1557 2.69052 20.6856 2.87887 21.0553C3.3321 21.9447 4.05528 22.6679 4.94477 23.1211C5.31445 23.3095 5.84437 23.463 6.88064 23.5476C7.94448 23.6345 9.32081 23.6364 11.3455 23.6364H14.6545C16.6792 23.6364 18.0555 23.6345 19.1193 23.5476C20.1557 23.463 20.6856 23.3095 21.0553 23.1211C21.9447 22.6679 22.6679 21.9447 23.1211 21.0553C23.3095 20.6856 23.463 20.1557 23.5476 19.1193C23.6345 18.0555 23.6364 16.6792 23.6364 14.6545V11.3455C23.6364 9.32081 23.6345 7.94448 23.5476 6.88064C23.463 5.84437 23.3095 5.31445 23.1211 4.94477C22.6679 4.05528 21.9447 3.3321 21.0553 2.87887C20.6856 2.69052 20.1557 2.53706 19.1193 2.45239C18.0555 2.36548 16.6792 2.36364 14.6545 2.36364Z"
        fill="url(#paint2_radial_7470_65)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 20.0909C16.9162 20.0909 20.0909 16.9162 20.0909 13C20.0909 9.0838 16.9162 5.90909 13 5.90909C9.0838 5.90909 5.90909 9.0838 5.90909 13C5.90909 16.9162 9.0838 20.0909 13 20.0909ZM13 17.7273C15.6108 17.7273 17.7273 15.6108 17.7273 13C17.7273 10.3892 15.6108 8.27273 13 8.27273C10.3892 8.27273 8.27273 10.3892 8.27273 13C8.27273 15.6108 10.3892 17.7273 13 17.7273Z"
        fill="url(#paint3_radial_7470_65)"
      />
      <path
        d="M20.0909 4.72727C19.4382 4.72727 18.9091 5.2564 18.9091 5.90909C18.9091 6.56179 19.4382 7.09091 20.0909 7.09091C20.7436 7.09091 21.2727 6.56179 21.2727 5.90909C21.2727 5.2564 20.7436 4.72727 20.0909 4.72727Z"
        fill="url(#paint4_radial_7470_65)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.772862 3.87171C0 5.38854 0 7.37418 0 11.3455V14.6545C0 18.6258 0 20.6115 0.772862 22.1282C1.45269 23.4625 2.53746 24.5473 3.87171 25.2271C5.38854 26 7.37418 26 11.3455 26H14.6545C18.6258 26 20.6115 26 22.1282 25.2271C23.4625 24.5473 24.5473 23.4625 25.2271 22.1282C26 20.6115 26 18.6258 26 14.6545V11.3455C26 7.37418 26 5.38854 25.2271 3.87171C24.5473 2.53746 23.4625 1.45269 22.1282 0.772862C20.6115 0 18.6258 0 14.6545 0H11.3455C7.37418 0 5.38854 0 3.87171 0.772862C2.53746 1.45269 1.45269 2.53746 0.772862 3.87171ZM14.6545 2.36364H11.3455C9.32081 2.36364 7.94448 2.36548 6.88064 2.45239C5.84437 2.53706 5.31445 2.69052 4.94477 2.87887C4.05528 3.3321 3.3321 4.05528 2.87887 4.94477C2.69052 5.31445 2.53706 5.84437 2.45239 6.88064C2.36548 7.94448 2.36364 9.32081 2.36364 11.3455V14.6545C2.36364 16.6792 2.36548 18.0555 2.45239 19.1193C2.53706 20.1557 2.69052 20.6856 2.87887 21.0553C3.3321 21.9447 4.05528 22.6679 4.94477 23.1211C5.31445 23.3095 5.84437 23.463 6.88064 23.5476C7.94448 23.6345 9.32081 23.6364 11.3455 23.6364H14.6545C16.6792 23.6364 18.0555 23.6345 19.1193 23.5476C20.1557 23.463 20.6856 23.3095 21.0553 23.1211C21.9447 22.6679 22.6679 21.9447 23.1211 21.0553C23.3095 20.6856 23.463 20.1557 23.5476 19.1193C23.6345 18.0555 23.6364 16.6792 23.6364 14.6545V11.3455C23.6364 9.32081 23.6345 7.94448 23.5476 6.88064C23.463 5.84437 23.3095 5.31445 23.1211 4.94477C22.6679 4.05528 21.9447 3.3321 21.0553 2.87887C20.6856 2.69052 20.1557 2.53706 19.1193 2.45239C18.0555 2.36548 16.6792 2.36364 14.6545 2.36364Z"
        fill="url(#paint5_radial_7470_65)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13 20.0909C16.9162 20.0909 20.0909 16.9162 20.0909 13C20.0909 9.0838 16.9162 5.90909 13 5.90909C9.0838 5.90909 5.90909 9.0838 5.90909 13C5.90909 16.9162 9.0838 20.0909 13 20.0909ZM13 17.7273C15.6108 17.7273 17.7273 15.6108 17.7273 13C17.7273 10.3892 15.6108 8.27273 13 8.27273C10.3892 8.27273 8.27273 10.3892 8.27273 13C8.27273 15.6108 10.3892 17.7273 13 17.7273Z"
        fill="url(#paint6_radial_7470_65)"
      />
      <path
        d="M20.0909 4.72727C19.4382 4.72727 18.9091 5.2564 18.9091 5.90909C18.9091 6.56179 19.4382 7.09091 20.0909 7.09091C20.7436 7.09091 21.2727 6.56179 21.2727 5.90909C21.2727 5.2564 20.7436 4.72727 20.0909 4.72727Z"
        fill="url(#paint7_radial_7470_65)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.772862 3.87171C0 5.38854 0 7.37418 0 11.3455V14.6545C0 18.6258 0 20.6115 0.772862 22.1282C1.45269 23.4625 2.53746 24.5473 3.87171 25.2271C5.38854 26 7.37418 26 11.3455 26H14.6545C18.6258 26 20.6115 26 22.1282 25.2271C23.4625 24.5473 24.5473 23.4625 25.2271 22.1282C26 20.6115 26 18.6258 26 14.6545V11.3455C26 7.37418 26 5.38854 25.2271 3.87171C24.5473 2.53746 23.4625 1.45269 22.1282 0.772862C20.6115 0 18.6258 0 14.6545 0H11.3455C7.37418 0 5.38854 0 3.87171 0.772862C2.53746 1.45269 1.45269 2.53746 0.772862 3.87171ZM14.6545 2.36364H11.3455C9.32081 2.36364 7.94448 2.36548 6.88064 2.45239C5.84437 2.53706 5.31445 2.69052 4.94477 2.87887C4.05528 3.3321 3.3321 4.05528 2.87887 4.94477C2.69052 5.31445 2.53706 5.84437 2.45239 6.88064C2.36548 7.94448 2.36364 9.32081 2.36364 11.3455V14.6545C2.36364 16.6792 2.36548 18.0555 2.45239 19.1193C2.53706 20.1557 2.69052 20.6856 2.87887 21.0553C3.3321 21.9447 4.05528 22.6679 4.94477 23.1211C5.31445 23.3095 5.84437 23.463 6.88064 23.5476C7.94448 23.6345 9.32081 23.6364 11.3455 23.6364H14.6545C16.6792 23.6364 18.0555 23.6345 19.1193 23.5476C20.1557 23.463 20.6856 23.3095 21.0553 23.1211C21.9447 22.6679 22.6679 21.9447 23.1211 21.0553C23.3095 20.6856 23.463 20.1557 23.5476 19.1193C23.6345 18.0555 23.6364 16.6792 23.6364 14.6545V11.3455C23.6364 9.32081 23.6345 7.94448 23.5476 6.88064C23.463 5.84437 23.3095 5.31445 23.1211 4.94477C22.6679 4.05528 21.9447 3.3321 21.0553 2.87887C20.6856 2.69052 20.1557 2.53706 19.1193 2.45239C18.0555 2.36548 16.6792 2.36364 14.6545 2.36364Z"
        fill="url(#paint8_radial_7470_65)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.28571 19.5) rotate(-55.3758) scale(23.6968)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.28571 19.5) rotate(-55.3758) scale(23.6968)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.28571 19.5) rotate(-55.3758) scale(23.6968)"
        >
          <stop stopColor="#B13589" />
          <stop offset="0.79309" stopColor="#C62F94" />
          <stop offset="1" stopColor="#8A3AC8" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.35714 26.9286) rotate(-65.1363) scale(20.9803)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.35714 26.9286) rotate(-65.1363) scale(20.9803)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.35714 26.9286) rotate(-65.1363) scale(20.9803)"
        >
          <stop stopColor="#E0E8B7" />
          <stop offset="0.444662" stopColor="#FB8A2E" />
          <stop offset="0.71474" stopColor="#E2425C" />
          <stop offset="1" stopColor="#E2425C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1.39286 0.928572) rotate(-8.1301) scale(36.113 7.72419)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1.39286 0.928572) rotate(-8.1301) scale(36.113 7.72419)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_7470_65"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(-1.39286 0.928572) rotate(-8.1301) scale(36.113 7.72419)"
        >
          <stop offset="0.156701" stopColor="#406ADC" />
          <stop offset="0.467799" stopColor="#6A45BE" />
          <stop offset="1" stopColor="#6A45BE" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default InstagramIcon;
