const ProfilePlaceholderIcon = () => {
  return (
    <svg
      width="51"
      height="49"
      viewBox="0 0 51 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.8513 29.0696L25.7771 33.7312L17.7026 29.0696V19.7461L25.777 15.0844L33.8513 19.7461V29.0696Z"
        stroke="#989898"
      />
      <path
        d="M25.7766 15.0845L17.7024 19.7461L9.62793 15.0845V5.76101L17.7023 1.09924L25.7766 5.76101V15.0845Z"
        stroke="#989898"
      />
      <path
        d="M41.9255 15.0845L33.8513 19.7461L25.7769 15.0845V5.76101L33.8512 1.09924L41.9255 5.76101V15.0845Z"
        stroke="#989898"
      />
      <path
        d="M41.9255 43.0549L33.8513 47.7165L25.7769 43.0549V33.7314L33.8512 29.0696L41.9255 33.7314V43.0549Z"
        stroke="#989898"
      />
      <path
        d="M49.9997 29.0696L41.9256 33.7312L33.8511 29.0696V19.7461L41.9254 15.0844L49.9997 19.7461V29.0696Z"
        stroke="#989898"
      />
      <path
        d="M25.7766 43.0549L17.7024 47.7165L9.62793 43.0549V33.7314L17.7023 29.0696L25.7766 33.7314V43.0549Z"
        stroke="#989898"
      />
      <path
        d="M17.7024 29.0696L9.6282 33.7312L1.55371 29.0696V19.7461L9.62803 15.0844L17.7024 19.7461V29.0696Z"
        stroke="#989898"
      />
    </svg>
  );
};

export default ProfilePlaceholderIcon;
