import { companyApplicant } from "../../../service/Scope/Scope";
import { v4 as uuidv4 } from "uuid";
import { APIClient } from "smartassess-api-lib";

const handleApplicantLogin = async (
  email?: any,
  password?: any,
  loginType?: any,
  providerId?: any,
  isVerified?: any,
  resetForm?: () => void,
  setSubmitting?: (data: boolean) => any,
  navigate?: any,
  currentLanguage?: any,
  rechargeSlabID?: any,
  templateID?: any,
  partnerId?: any,
) => {
  const data = {
    emailId: email?.toLowerCase(),
    password: password?.trim(),
    loginType: loginType,
    providerId: providerId,
    isVerified: isVerified,
    partnerId: partnerId,
  };
  try {
    setSubmitting && setSubmitting(true);
    const sendInvitationRes = await companyApplicant(data);
    if (sendInvitationRes?.status === 200) {
      resetForm && resetForm();
      const uniqueId = uuidv4();
      localStorage.setItem(
        "companyToken",
        sendInvitationRes?.data?.accessToken,
      );
      APIClient.setApiToken(sendInvitationRes?.data?.accessToken);
      localStorage.setItem("companyId", sendInvitationRes?.data?.companyId);
      localStorage.setItem(
        "companyUserId",
        sendInvitationRes?.data?.companyUserId,
      );
      localStorage.setItem("industryId", sendInvitationRes?.data?.industryId);
      localStorage.setItem("tempUniqueId", uniqueId);
      localStorage.setItem("branchId", sendInvitationRes?.data?.branchId);
      localStorage.setItem("mediaPath", sendInvitationRes?.data?.mediaPath);
      localStorage.setItem("email", email?.toLowerCase());
      localStorage.setItem(
        "companyAddress",
        sendInvitationRes?.data?.address ?? "",
      );
      sessionStorage.removeItem("referredPartnerId");
      sessionStorage.removeItem("referredClientEmail");
      if (rechargeSlabID) {
        navigate(`/${currentLanguage}/account/account-details`, {
          state: { rechrSlab: "true" },
        });
      } else if (templateID) {
        navigate(`/${currentLanguage}/assessment/template/${templateID}`, {
          state: { templateID: templateID },
        });
      } else {
        navigate(`/${currentLanguage}/assessment/list`, { replace: true });
      }
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  } finally {
    setSubmitting && setSubmitting(false);
  }
};
export default handleApplicantLogin;
