import { ReactNode } from "react";
import "./Accordion.scss";
import { useTranslation } from "react-i18next";
interface Accordion {
  children: ReactNode;
  index: number;
  type: string;
  timeLabel: string;
  timeValue: string;
  data: any;
}

const Accordion: React.FC<Accordion> = ({
  children,
  index,
  type,
  timeLabel,
  timeValue,
  data,
}) => {
  const { t } = useTranslation();
  return (
    <div className="customQuestionSetsSection">
      <div className="accordion" id="accordionExample">
        <div className="accordion-item">
          <h2
            className="accordion-header questionsHead customAccordion-button hidden"
            key={index}
            data-bs-toggle="collapse"
            data-bs-target={`#${"accordian" + index}`}
            aria-expanded="false"
            aria-controls={"accordian" + index}
          >
            <div className="legendText">{t("GENERAL.CUSTOM_QUESTION")}</div>
            <div className="accordianHeader">
              <div className="customQuestionSet">
                <div className="d-flex">
                  <span
                    className="questionSpan"
                    dangerouslySetInnerHTML={{
                      __html: ` ${data.question}`,
                    }}
                  />
                </div>
              </div>
              <h6>
                {type} <span>|</span> {timeLabel} : {timeValue}
              </h6>
            </div>
          </h2>
          <div
            id={"accordian" + index}
            className="accordion-collapse collapse"
            data-bs-parent="#accordionExample"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Accordion;
