import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  determinePageType,
  getAssessmentPath,
  initialScopeData,
} from "../../../../utils/Common/Constant";
import Head from "../AssessmentScope/Head/Head";
import { setSessionStorageItem } from "../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { Form, Formik } from "formik";
import { createAssessmentDraftJson } from "../../../../service/DraftJson/DraftJson";
import { assessmentDraftJson } from "../../../../context/CreateAssessmentDraftJson/CreateAssessmentDraftJson";
import SidebarCategory from "./SidebarCategory";

const AssessmentBuild = () => {
  const { tempUniqueId } = useParams();
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const editedParams = new URLSearchParams(location.search).get("edited");

  // Get current language
  const currentLanguage = i18n.language;
  const activeStep = determinePageType(pathname);

  const handleNext = async (count: number) => {
    let nextStep;
    if (count != null && count != undefined && typeof count === "number") {
      nextStep = activeStep < 5 ? count : activeStep;
    } else {
      nextStep = activeStep < 5 ? activeStep + 1 : activeStep;
    }
    if (activeStep < count) {
      await createAssessmentDraftJson(assessmentDraftJson(tempUniqueId));
    }
    setSessionStorageItem("currentStep", nextStep);
    navigate(
      getAssessmentPath(nextStep, tempUniqueId, currentLanguage, editedParams),
    );
  };
  // to move to previous page
  const handlePrevious = (count: number) => {
    if (count != null && count != undefined && typeof count === "number") {
      setSessionStorageItem("currentStep", count);
      navigate(
        getAssessmentPath(count, tempUniqueId, currentLanguage, editedParams),
      );
    } else {
      const prevStep = activeStep > 1 ? activeStep - 1 : activeStep;
      setSessionStorageItem("currentStep", prevStep);
      navigate(
        getAssessmentPath(
          prevStep,
          tempUniqueId,
          currentLanguage,
          editedParams,
        ),
      );
    }
  };
  return (
    <>
      <Formik
        initialValues={initialScopeData}
        onSubmit={() => {
          // handleNext();
        }}
      >
        {({ errors, touched }) => {
          return (
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <Head
                    handleNext={handleNext}
                    handlePrevious={handlePrevious}
                    currentStep={activeStep}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <SidebarCategory />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AssessmentBuild;
