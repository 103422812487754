const LinkedinIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill="#0077B5"
      />
      <path
        d="M9.60353 6.68453C9.60353 7.49385 8.98733 8.14879 8.02371 8.14879C7.09759 8.14879 6.48132 7.49385 6.50043 6.68453C6.48132 5.83589 7.09752 5.2 8.04217 5.2C8.98733 5.2 9.58507 5.83589 9.60353 6.68453ZM6.57791 18.7324V9.30553H9.50759V18.7318L6.57791 18.7324Z"
        fill="white"
      />
      <path
        d="M11.8558 12.314C11.8558 11.1382 11.8171 10.1358 11.7783 9.30682H14.3231L14.4583 10.5982H14.5162C14.9018 10.0005 15.8654 9.09525 17.4268 9.09525C19.3541 9.09525 20.7999 10.3676 20.7999 13.1423V18.7336H17.8702V13.5095C17.8702 12.2944 17.4465 11.4659 16.3863 11.4659C15.5764 11.4659 15.0948 12.0249 14.9024 12.5643C14.8249 12.7574 14.7867 13.0268 14.7867 13.2973V18.7336H11.857V12.314H11.8558Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
