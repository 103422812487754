import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
import { AssessmentButton } from "ui-components";
import PayCheckout from "./Checkout";
import { convertDate } from "../../utils/Common/Constant";
import CreditCoin from "../../utils/Images/CreditCoin";
import { useLocation } from "react-router-dom";

const AccountDetails = ({ accountDetails }: any) => {
  const { t } = useTranslation();
  const location = useLocation();
  const rechrSlab = location?.state?.rechrSlab ?? "";
  const [payCheckout, SetPayCheckout] = useState(() => {
    if (rechrSlab) {
      return true;
    } else {
      const isPopUpOpen = new URLSearchParams(location.search).get("rechrSlab");
      return isPopUpOpen ? JSON.parse(isPopUpOpen) : false;
    }
  });
  useEffect(() => {
    if (rechrSlab) {
      SetPayCheckout(true);
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("rechrSlab", "true");
      history.replaceState({}, "", url.toString());
    }
  }, [rechrSlab]);

  const handlePayCheckout = () => {
    SetPayCheckout(true);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("rechrSlab", "true");
    history.replaceState({}, "", url.toString());
  };
  const handleClosePayCheckout = () => {
    SetPayCheckout(false);
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("rechrSlab", "false");
    history.replaceState({}, "", url.toString());
  };
  return (
    <div className="accountsRightDescription">
      <div className="topRow">
        <div className="lastRechargeSec ">
          <div className="textRow">
            <span className="rightContentHeading ">
              {t("GENERAL.LAST_RECHARGE")}
            </span>
            <span className="textInfo">
              <div className="d-flex gap-1 align-items-center">
                {`${accountDetails?.lastRechargeAmount ? `$${accountDetails?.lastRechargeAmount} ` : ""}`}

                <div className="d-flex gap-1 align-items-center">
                  {accountDetails?.credits ? (
                    <CreditCoin width={22} height={22} />
                  ) : (
                    "---"
                  )}
                  {`${accountDetails?.credits ? `${accountDetails?.credits}` : ""}`}
                </div>
              </div>
            </span>
            <span className="textInfo">
              {accountDetails?.lastRechargeDate
                ? convertDate(accountDetails?.lastRechargeDate, false, true)
                : "---"}
            </span>
          </div>
          <AssessmentButton
            onClick={() => handlePayCheckout()}
            className="rechargeBtn"
          >
            {t("GENERAL.BUY_CREDITS")}
          </AssessmentButton>
        </div>
        <div className="textRow alignEnd">
          <span className="rightContentHeading">
            {t("ACCOUNT.ACCOUNT_BALANCE")}
          </span>
          <span className="textInfo bolderBalance">
            <CreditCoin width={30} height={30} />
            <span>{accountDetails?.accountBalance ?? 0}</span>
          </span>
        </div>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">
          {t("GENERAL.TOTAL_ASSESSMENTS")}
        </span>
        <span className="textInfo">
          {accountDetails?.totalAssessments ?? 0}
        </span>
      </div>
      <hr className="divider" />
      <div className="textRow">
        <span className="rightContentHeading">
          {t("ACCOUNT.LAST_ASSESSMENT_CREATED")}
        </span>
        <span className="textInfo">
          {accountDetails?.lastAssessmentCreated
            ? convertDate(accountDetails?.lastAssessmentCreated, false, true)
            : "---"}
        </span>
      </div>
      {payCheckout && (
        <PayCheckout
          payCheckout={payCheckout}
          handleClosePayCheckout={handleClosePayCheckout}
        />
      )}
    </div>
  );
};

export default AccountDetails;
