import { useEffect } from "react";
import { rechargeAnAccount } from "../../../service/Account/Account";
import { getSessionStorageItem } from "../../../utils/SessionStorageMethod/getSessionStorageItem";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showToast } from "ui-components";

const StripeConfirmOtp = () => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const paymentIntent = new URLSearchParams(location.search).get(
    "payment_intent",
  );
  const confirmOtpPayment = async () => {
    const rechargeData = await rechargeAnAccount({
      companyId: localStorage.getItem("companyId"),
      branchId: localStorage.getItem("branchId"),
      rechargeSlabId: getSessionStorageItem("rechargeSlabId"),
      paymentMethodId: getSessionStorageItem("paymentMethodId"),
      saveCardDetails: getSessionStorageItem("saveCardDetails"),
      is3ds: true,
      paymentIntentId: paymentIntent,
    });
    if (rechargeData?.status) {
      showToast(t("PAYMENT.RECHARGE_SUCCESSFUL"), "success");
      navigate(`/${currentLanguage}/account/`, { state: { payment: true } });
    } else {
      navigate(`/${currentLanguage}/account/`, { state: { payment: true } });
    }
  };

  useEffect(() => {
    confirmOtpPayment();
  }, [paymentIntent]);

  return (
    <div
      style={{
        height: "100%",
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <h2>{t("PAYMENT.REDIRECTING")}...</h2>
    </div>
  );
};

export default StripeConfirmOtp;
