import { useTranslation } from "react-i18next";
import "./DataNotAvailable.scss";

function DataNotAvailable() {
  const { t } = useTranslation();
  return (
    <div className="dataNotAvailable mt-4 p-5">
      <h2>{t("GENERAL.DATA_NOT_FOUND")}</h2>
    </div>
  );
}

export default DataNotAvailable;
