type ManualScoreIconProps = {
  width?: string;
  height?: string;
  color?: string;
  fontWeight?: string;
};
function ManualScoreIcon(props: ManualScoreIconProps) {
  const {
    height = "40",
    width = "40",
    color = "#4D4D4D",
    fontWeight = "600",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      color={color}
      fontWeight={fontWeight}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.3225 32.7269H34.3349C33.5196 32.7269 32.8584 32.0518 32.8584 31.2195C32.8584 30.3871 33.5196 29.7121 34.3349 29.7121H35.3225C36.2733 29.7121 37.047 28.9223 37.047 27.9514V4.7755C37.047 3.80475 36.2735 3.01479 35.3225 3.01479H4.67739C3.72659 3.01479 2.95286 3.80455 2.95286 4.7755V27.9514C2.95286 28.9221 3.72639 29.7121 4.67739 29.7121H25.1988C26.0141 29.7121 26.6752 30.3871 26.6752 31.2195C26.6752 32.0518 26.0141 32.7269 25.1988 32.7269H4.67739C2.09836 32.7269 0 30.5847 0 27.9514V4.7755C0 2.14217 2.09836 0 4.67739 0H35.3226C37.9017 0 40 2.14217 40 4.7755V27.9514C39.9999 30.5847 37.9015 32.7269 35.3225 32.7269Z"
        fill={color}
        strokeWidth="1.75"
      />
      <path
        d="M24.9478 39.9997H14.6541C13.8469 39.9997 13.1924 39.3154 13.1924 38.4717V31.6482C13.1924 30.8044 13.8469 30.1201 14.6541 30.1201H24.9478C25.7549 30.1201 26.4095 30.8044 26.4095 31.6482V38.4717C26.4096 39.3156 25.755 39.9997 24.9478 39.9997ZM16.1158 36.9435H23.4861V33.1763H16.1158V36.9435Z"
        fill={color}
        strokeWidth="1.75"
      />
      <path
        d="M28.6266 40H10.9744C10.1672 40 9.5127 39.3157 9.5127 38.4719C9.5127 37.6281 10.1672 36.9438 10.9744 36.9438H28.6266C29.4338 36.9438 30.0883 37.6281 30.0883 38.4719C30.0883 39.3157 29.4338 40 28.6266 40Z"
        fill={color}
        strokeWidth="1.75"
      />
      <path
        d="M19.7998 22.7265C15.5238 22.7265 12.0449 19.0896 12.0449 14.619C12.0449 10.1487 15.5238 6.51172 19.7998 6.51172C24.0761 6.51172 27.5549 10.1487 27.5549 14.619C27.5549 19.0897 24.0761 22.7265 19.7998 22.7265ZM19.7998 9.56811C17.1357 9.56811 14.9684 11.834 14.9684 14.6191C14.9684 17.4045 17.1357 19.6704 19.7998 19.6704C22.4641 19.6704 24.6315 17.4045 24.6315 14.6191C24.6315 11.834 22.464 9.56811 19.7998 9.56811Z"
        fill={color}
        strokeWidth="1.75"
      />
      <path
        d="M27.8313 33.176H11.7673C10.9602 33.176 10.3057 32.4917 10.3057 31.6479V30.0574C10.3057 25.8965 12.69 22.1972 16.3799 20.633C16.5543 20.5591 16.7409 20.521 16.9292 20.521H22.6696C22.8578 20.521 23.0443 20.559 23.2188 20.633C26.9088 22.1973 29.2931 25.8965 29.2931 30.0574V31.6479C29.2931 32.4917 28.6386 33.176 27.8313 33.176ZM13.229 30.1198H26.3696V30.0575C26.3696 27.2423 24.8089 24.7292 22.3704 23.5774C20.363 22.8872 19.2356 22.8739 17.2282 23.5774C14.7895 24.7292 13.229 27.2423 13.229 30.0575V30.1198Z"
        fill={color}
        strokeWidth="1.75"
      />
    </svg>
  );
}

export default ManualScoreIcon;
