import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../src/i18n/en/translation.json";
import translationFR from "../src/i18n/fr/translation.json";
import translationES from "../src/i18n/es/translation.json";
import translationAR from "../src/i18n/ar/translation.json";
import translationHE from "../src/i18n/he/translation.json";
import translationFA from "../src/i18n/fa/translation.json";
import translationUR from "../src/i18n/ur/translation.json";

type TranslationResources = {
  en: typeof translationEN;
  fr: typeof translationFR;
  es: typeof translationES;
  ar: typeof translationAR;
  he: typeof translationHE;
  fa: typeof translationFA;
  ur: typeof translationUR;
};
const resources: Resource = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
  he: {
    translation: translationHE,
  },
  fa: {
    translation: translationFA,
  },
  ur: {
    translation: translationUR,
  },
};
i18n.use(initReactI18next).init({
  resources: resources as TranslationResources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
