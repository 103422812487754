function AssesmentListingIcon() {
  return (
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3442_332)">
        <circle cx="65" cy="65" r="45" fill="white" />
      </g>
      <circle cx="65.5" cy="64.5" r="40.5" fill="white" />
      <circle
        cx="65.5"
        cy="64.5"
        r="39.75"
        stroke="#35D4AE"
        strokeOpacity="0.2"
        strokeWidth="1.5"
      />
      <path
        d="M46.2993 75.9146C46.0052 75.7164 46.0052 75.2836 46.2993 75.0854L65.7206 61.9971C65.8895 61.8833 66.1105 61.8833 66.2794 61.9971L85.7007 75.0854C85.9948 75.2836 85.9948 75.7164 85.7007 75.9146L66.2794 89.0029C66.1105 89.1167 65.8895 89.1167 65.7206 89.0029L46.2993 75.9146Z"
        fill="white"
        stroke="#35D4AE"
        strokeWidth="3"
      />
      <path
        d="M46.2993 64.9146C46.0052 64.7164 46.0052 64.2836 46.2993 64.0854L65.7206 50.9971C65.8895 50.8833 66.1105 50.8833 66.2794 50.9971L85.7007 64.0854C85.9948 64.2836 85.9948 64.7164 85.7007 64.9146L66.2794 78.0029C66.1105 78.1167 65.8895 78.1167 65.7206 78.0029L46.2993 64.9146Z"
        fill="white"
        stroke="#35D4AE"
        strokeWidth="3"
      />
      <path
        d="M46.2993 53.9146C46.0052 53.7164 46.0052 53.2836 46.2993 53.0854L65.7206 39.9971C65.8895 39.8833 66.1105 39.8833 66.2794 39.9971L85.7007 53.0854C85.9948 53.2836 85.9948 53.7164 85.7007 53.9146L66.2794 67.0029C66.1105 67.1167 65.8895 67.1167 65.7206 67.0029L46.2993 53.9146Z"
        fill="white"
        stroke="#35D4AE"
        strokeWidth="3"
      />
      <defs>
        <filter
          id="filter0_d_3442_332"
          x="0"
          y="0"
          width="130"
          height="130"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="10" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3442_332"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3442_332"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default AssesmentListingIcon;
