function AssessmentListingDeleteIcon() {
  return (
    <svg
      className="shadowSvg"
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <circle cx="23.1038" cy="22.6207" r="19.2276" fill="white" />
      <circle
        cx="23.1038"
        cy="22.6207"
        r="18.7276"
        stroke="#35D4AE"
        strokeOpacity="0.2"
      />
      <path
        d="M21.5486 22.0551V27.3961"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7898 22.0551V27.3961"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1863 16.7141H32.152"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.3071 19.9188V28.4644C17.3071 30.2343 18.7314 31.669 20.4882 31.669H26.8504C28.6073 31.669 30.0314 30.2343 30.0314 28.4644V19.9188"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.488 14.5778C20.488 13.3979 21.4375 12.4414 22.6088 12.4414H24.7295C25.9007 12.4414 26.8502 13.3979 26.8502 14.5778V16.7142H20.488V14.5778Z"
        stroke="#4D4D4D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
export default AssessmentListingDeleteIcon;
