import { useTranslation } from "react-i18next";
import "./ReviewEvalution.scss";
import { AssessmentCheckboxRadio } from "ui-components";
import { useState } from "react";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import SmartAssessLogo from "../../../../../utils/Images/SmartAssessLogo";
import ManualReviewLogo from "../../../../../utils/Images/ManualReviewLogo";
import VisualAnalysisIcon from "../../../../../utils/Images/VisualAnalysisIcon";
import VoiceAnalysisIcon from "../../../../../utils/Images/VoiceAnalysisIcon";
import CorrectnessIcon from "../../../../../utils/Images/CorrectnessIcon";

const ReviewEvalution: React.FunctionComponent = () => {
  const { t } = useTranslation();
  // Initialize state with data from session storage, or default values
  const [evaluation, setEvaluation] = useState<any>(
    getSessionStorageItem("evaluation")?.length > 1
      ? getSessionStorageItem("evaluation")
      : [
          {
            type: "smartAi",
            aiAnalysis: false,
            visualAnalysis: false,
            audioAnalysis: false,
          },
          { type: "manual", attributes: true },
        ],
  );
  // Handle checkbox changes
  const handleEvaluation = (key: string, value: boolean) => {
    setEvaluation((prevEvaluation: any) => {
      let updatedEvaluation = prevEvaluation.map((item: any) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          return { ...item, [key]: value };
        }
        setSessionStorageItem("isAiEvaluation", true);
        return item;
      });

      // If aiAnalysis is set to false, disable all related analyses
      if (key === "aiAnalysis" && !value) {
        updatedEvaluation = updatedEvaluation.map((item: any) => {
          if (item.type === "smartAi") {
            setSessionStorageItem("isAiEvaluation", false);

            return {
              ...item,
              aiAnalysis: false,
              visualAnalysis: false,
              audioAnalysis: false,
            };
          }
          return item;
        });
      }

      setSessionStorageItem("evaluation", updatedEvaluation); // Save updated state to session storage
      return updatedEvaluation;
    });
  };

  // Handle checkbox clicks (toggling on/off)
  const handleCheckboxClick = (key: keyof (typeof evaluation)[number]) => {
    const currentValue = evaluation?.find((item: any) =>
      Object.prototype.hasOwnProperty.call(item, key),
    )?.[key];
    handleEvaluation(key as string, !currentValue);
  };

  return (
    <div className="evolutionSec">
      <div className="horizontalLine"></div>
      <h6>{t("GENERAL.EVALUATION")}</h6>

      <div className="evolutionAdd">
        {/* Manual Review */}
        <div className="evolutionChecked defaultEvolutionChecked">
          <AssessmentCheckboxRadio
            type="checkbox"
            checked={
              evaluation.find((item: any) =>
                Object.prototype.hasOwnProperty.call(item, "attributes"),
              )?.attributes
            }
          />
          <p>
            <ManualReviewLogo color={"var(--primary-green)"} /> &nbsp;{" "}
            {t("GENERAL.MANUAL_REVIEW")}
          </p>
        </div>

        {/* AI Analysis */}
        <div
          className="evolutionChecked"
          onClick={() => handleCheckboxClick("aiAnalysis")}
        >
          <AssessmentCheckboxRadio
            type="checkbox"
            checked={
              evaluation.find((item: any) =>
                Object.prototype.hasOwnProperty.call(item, "aiAnalysis"),
              )?.aiAnalysis
            }
          />
          <p>
            <SmartAssessLogo
              color={
                evaluation.find((item: any) => item.aiAnalysis)
                  ? "var(--primary-green)"
                  : "var(--light-grey)"
              }
            />
            &nbsp;&nbsp;&nbsp;{t("GENERAL.SMARTASSESS_AI")}
          </p>
        </div>

        {/* Sub Evaluation - AI (Visual and Audio) */}
        <div className="smartSubEvaluation">
          {/* AI Correctness */}
          <div className="evolutionChecked defaultEvolutionChecked">
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={evaluation.some((item: any) => item.aiAnalysis)}
            />
            <p>
              <CorrectnessIcon
                color={
                  evaluation.some((item: any) => item.aiAnalysis)
                    ? "var(--primary-green)"
                    : "var(--light-grey)"
                }
              />
              &nbsp;&nbsp; {t("RESPONSE_LIST.AI_ANALYSIS")}
            </p>
          </div>

          {/* Visual Analysis */}
          <div
            className="evolutionChecked"
            onClick={() => {
              if (evaluation.some((item: any) => item.aiAnalysis))
                handleCheckboxClick("visualAnalysis");
            }}
          >
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={
                evaluation.find((item: any) =>
                  Object.prototype.hasOwnProperty.call(item, "visualAnalysis"),
                )?.visualAnalysis
              }
            />
            <p>
              <VisualAnalysisIcon
                color={
                  evaluation.find((item: any) => item.visualAnalysis)
                    ? "var(--primary-green)"
                    : "var(--light-grey)"
                }
              />
              &nbsp;&nbsp; {t("CREATE_ASSESSMENT.LABEL_VISUAL_ANALYSIS")}
            </p>
          </div>

          {/* Audio Analysis */}
          <div
            className="evolutionChecked"
            onClick={() => {
              if (evaluation.some((item: any) => item.aiAnalysis))
                handleCheckboxClick("audioAnalysis");
            }}
          >
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={
                evaluation.find((item: any) =>
                  Object.prototype.hasOwnProperty.call(item, "audioAnalysis"),
                )?.audioAnalysis
              }
            />
            <p>
              <VoiceAnalysisIcon
                color={
                  evaluation.find((item: any) => item.audioAnalysis)
                    ? "var(--primary-green)"
                    : "var(--light-grey)"
                }
              />
              &nbsp;&nbsp; {t("CREATE_ASSESSMENT.LABEL_VOICE_ANALYSIS")}
            </p>
          </div>
        </div>
      </div>
      <div className="horizontalLine"></div>
    </div>
  );
};

export default ReviewEvalution;
