import React, { useEffect, useState } from "react";

interface AiQuestionEvaluation {
  evaluationSummary: any;
  t: any;
  questionData?: any;
  selectedTab?: any;
  isAiAnalysis?: boolean;
}

const AiQuestionEvaluation: React.FC<AiQuestionEvaluation> = ({
  evaluationSummary,
  t,
  questionData,
  selectedTab,
  isAiAnalysis,
}) => {
  const [tabVisibilityValue, setTabVisibilityValue] = useState<any>("");
  useEffect(() => {
    if (questionData) {
      const tabVisibilityValue = getEvaluationType(questionData);
      setTabVisibilityValue(tabVisibilityValue);
    }
  }, [questionData, selectedTab]);
  function getEvaluationType(evaluationData: any[]) {
    let finalValue: any[] = [];
    evaluationData.map((evaluation) => {
      const typeArr = evaluation?.evaluationResult.map(
        (item: { evaluation: any }) => item?.evaluation?.evaluation_type,
      );
      finalValue = [...finalValue, ...typeArr];
    });
    finalValue = Array.from(new Set(finalValue));
    return finalValue;
  }
  return (
    <div className="additionalAnalysis mt-3">
      <div className="row">
        {tabVisibilityValue.includes("technical") && (
          <div className="col-lg-3">
            <div className="analysisSub mb-4">
              <p>{t("RESPONSE_LIST.CORRECTNESS")}</p>
              <span>
                {isAiAnalysis
                  ? evaluationSummary
                    ? evaluationSummary?.accuracy + "%"
                    : "-"
                  : "-"}
              </span>
            </div>
          </div>
        )}
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <p>{t("RESPONSE_LIST.COMPLETENESS")}</p>
            <span>
              {isAiAnalysis
                ? evaluationSummary
                  ? evaluationSummary?.completeness + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <p>{t("RESPONSE_LIST.CLARITY")}</p>
            <span>
              {isAiAnalysis
                ? evaluationSummary
                  ? evaluationSummary?.clarity + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <p>{t("RESPONSE_LIST.RELEVANCE")}</p>
            <span>
              {isAiAnalysis
                ? evaluationSummary
                  ? evaluationSummary?.relevance + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <p>{t("RESPONSE_LIST.DEPTH")}</p>
            <span>
              {isAiAnalysis
                ? evaluationSummary
                  ? evaluationSummary?.depth + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        {tabVisibilityValue.includes("text") && (
          <div className="col-lg-3">
            <div className="analysisSub mb-4">
              <p>{t("RESPONSE_LIST.POSITIVITY")}</p>
              <span>
                {isAiAnalysis
                  ? evaluationSummary
                    ? evaluationSummary?.positivity + "%"
                    : "-"
                  : "-"}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AiQuestionEvaluation;
