import React from "react";

const InfoIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 14.375H10.625V8.78875C10.625 8.78188 10.6231 8.77563 10.6231 8.76937C10.6231 8.76313 10.625 8.75688 10.625 8.75C10.625 8.405 10.345 8.125 10 8.125H8.75C8.405 8.125 8.125 8.405 8.125 8.75C8.125 9.095 8.405 9.375 8.75 9.375H9.375V14.375H8.75C8.405 14.375 8.125 14.655 8.125 15C8.125 15.345 8.405 15.625 8.75 15.625H11.25C11.595 15.625 11.875 15.345 11.875 15C11.875 14.655 11.595 14.375 11.25 14.375ZM10 6.875C10.6906 6.875 11.25 6.315 11.25 5.625C11.25 4.935 10.6906 4.375 10 4.375C9.30937 4.375 8.75 4.935 8.75 5.625C8.75 6.315 9.31 6.875 10 6.875ZM10 0C4.4775 0 0 4.47688 0 10C0 15.5231 4.47688 20 10 20C15.5231 20 20 15.5231 20 10C20 4.47688 15.5231 0 10 0ZM10 18.7694C5.175 18.7694 1.25 14.8244 1.25 9.99937C1.25 5.17438 5.175 1.24937 10 1.24937C14.825 1.24937 18.75 5.17438 18.75 9.99937C18.75 14.8244 14.825 18.7694 10 18.7694Z"
        fill="#5A5A5A"
      ></path>
    </svg>
  );
};

export default InfoIcon;
