import React, { useEffect, useRef } from "react";
import "./CircularProgressBar.scss";

interface CircularProgressBarProps {
  percent: number;
  selected?: boolean;
}

const CircularProgressBar: React.FC<CircularProgressBarProps> = ({
  percent,
  selected,
}) => {
  const progressCircleRef = useRef<SVGCircleElement>(null);
  const percentageTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressCircleRef.current && percentageTextRef.current) {
      const progressCircle = progressCircleRef.current;
      const percentageText = percentageTextRef.current;
      const radius = progressCircle.r.baseVal.value;
      const circumference = 2 * Math.PI * radius;
      const offset = circumference - (percent / 100) * circumference;
      progressCircle.style.strokeDasharray = `${circumference} ${circumference}`;
      progressCircle.style.strokeDashoffset = `${offset}`;
      percentageText.textContent = `${percent}%`;
    }
  }, [percent]);

  return (
    <div className="assesstProgressShadow">
      <div className="assesstProgressBar">
        <div className="progressDashed"></div>
        <div className="progress-circle">
          <svg>
            <circle className="bg" cx="50" cy="50" r="45"></circle>
            <circle
              className={`${selected ? "selected-progress-circle" : "progress"}`}
              cx="50"
              cy="50"
              r="45"
              ref={progressCircleRef}
            ></circle>
          </svg>
          <div
            className={`${selected ? "selected-Progress-text" : "percentage"}`}
            ref={percentageTextRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default CircularProgressBar;
