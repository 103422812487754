import { AssessmentButton, InputField } from "ui-components";
interface AddCommentAndReport {
  value: string;
  placeHolder: string;
  btnName: string;
  setFn: any;
  handleSubmit: () => void;
}
const AddCommentAndReport: React.FC<AddCommentAndReport> = ({
  value,
  placeHolder,
  btnName,
  setFn,
  handleSubmit,
}) => {
  const handlechange = (id: null, value: any) => {
    setFn(value);
  };
  return (
    <div className="comments d-flex gap-2 mt-3">
      <div className="reviewInputField w-100">
        <InputField
          additionalProps={{
            as: "textarea",
            rows: 2,
            style: { resize: "none" },
          }}
          value={value}
          placeholder={placeHolder}
          onChange={(e) => {
            handlechange(null, e);
          }}
        />
      </div>
      <div className="addCommentAndReportBtn">
        <AssessmentButton className="removeBtn" onClick={() => handleSubmit()}>
          {btnName}
        </AssessmentButton>
      </div>
    </div>
  );
};

export default AddCommentAndReport;
