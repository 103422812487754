const TestCaseWrongIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5019 0.498088C18.166 1.16222 18.166 2.23897 17.5019 2.90311L11.405 8.99996L17.5019 15.0969C18.166 15.761 18.166 16.8379 17.5019 17.5019C16.8378 18.166 15.761 18.166 15.0969 17.5019L9.00003 11.4049L2.90305 17.5019C2.23897 18.166 1.16215 18.166 0.498062 17.5019C-0.166021 16.8379 -0.166021 15.761 0.498062 15.0969L6.59505 8.99996L0.498062 2.90313C-0.166021 2.23901 -0.166021 1.16224 0.498062 0.498123C1.16232 -0.166012 2.23897 -0.166012 2.90305 0.498123L9.00003 6.59497L15.0969 0.498088C15.761 -0.166029 16.8378 -0.166029 17.5019 0.498088Z"
        fill="#FF6060"
      />
    </svg>
  );
};

export default TestCaseWrongIcon;
