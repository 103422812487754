const ResumeScanningIcon = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="white" />
      <circle cx="23.2276" cy="23.2276" r="19.2276" fill="white" />
      <circle
        cx="23.2276"
        cy="23.2276"
        r="18.7276"
        stroke="#35D4AE"
        strokeOpacity="0.2"
      />
      <path
        d="M26.375 29.1111L28.25 31L32 27.2222M24.5 14H20C18.9499 14 18.4249 14 18.0238 14.2059C17.671 14.387 17.3841 14.6759 17.2044 15.0314C17 15.4354 17 15.9643 17 17.0222V27.9778C17 29.0356 17 29.5646 17.2044 29.9687C17.3841 30.3241 17.671 30.6131 18.0238 30.7941C18.4249 31 18.9499 31 20 31H23.0938M24.5 14L30.125 19.6667M24.5 14V18.1556C24.5 18.6845 24.5 18.949 24.6022 19.151C24.6921 19.3287 24.8354 19.4732 25.0119 19.5637C25.2124 19.6667 25.4749 19.6667 26 19.6667H30.125M30.125 19.6667V23.8222M20.75 27.2222H23.0938M20.75 23.4444H26.375M20.75 19.6667H21.6875"
        stroke="#4D4D4D"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ResumeScanningIcon;
