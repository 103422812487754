import React, { useState, useRef, useCallback } from "react";
import ReactCrop, {
  PixelCrop,
  centerCrop,
  makeAspectCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Button } from "react-bootstrap";
import "./CropModal.scss";
import { useTranslation } from "react-i18next";
interface ImageCropPopupProps {
  show: boolean;
  file: File;
  onClose: () => void;
  onCropComplete: (croppedFile: Blob) => void;
}

const ImageCropPopup: React.FC<ImageCropPopupProps> = ({
  show,
  file,
  onClose,
  onCropComplete,
}) => {
  const [crop, setCrop] = useState<PixelCrop | any>(null);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const { t } = useTranslation();
  // Load the image URL when the file prop changes
  React.useEffect(() => {
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setImgSrc(objectUrl);
      return () => URL.revokeObjectURL(objectUrl); // Clean up the URL object
    }
  }, [file]);

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const crop = makeAspectCrop(
      {
        unit: "%",
        width: 50, // Default crop size
      },
      1, // Aspect ratio (1:1 for a circle)
      width,
      height,
    );
    const centeredCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const onComplete = useCallback((crop: PixelCrop) => {
    setCompletedCrop(crop);
  }, []);

  const handleCrop = () => {
    const image = imageRef.current;
    if (!image || !completedCrop) return;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = completedCrop.width;
    canvas.height = completedCrop.height;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.beginPath();
      ctx.arc(
        completedCrop.width / 2,
        completedCrop.height / 2,
        completedCrop.width / 2,
        0,
        Math.PI * 2,
      );
      ctx.clip();
      ctx.drawImage(
        image,
        completedCrop.x * scaleX,
        completedCrop.y * scaleY,
        completedCrop.width * scaleX,
        completedCrop.height * scaleY,
        0,
        0,
        completedCrop.width,
        completedCrop.height,
      );

      canvas.toBlob((blob) => {
        if (blob) {
          onCropComplete(blob);
        }
      });
    }
  };

  const handleCropChange = (newCrop: PixelCrop) => {
    if (newCrop.width && newCrop.height) {
      const minSize = Math.min(newCrop.width, newCrop.height);
      setCrop({
        ...newCrop,
        width: minSize,
        height: minSize,
      });
    } else {
      setCrop(newCrop);
    }
  };

  return (
    <Modal show={show} onHide={onClose} className="cropModal">
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={handleCropChange}
            onComplete={onComplete}
            circularCrop
            keepSelection
            minWidth={50}
            minHeight={50}
          >
            <img
              ref={imageRef}
              src={imgSrc}
              alt="Crop"
              onLoad={onImageLoad}
              style={{ cursor: "move" }}
            />
          </ReactCrop>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          {t("GENERAL.CLOSE")}
        </Button>
        <Button variant="primary" onClick={handleCrop} className="cropBtn">
          {t("GENERAL.CROP")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropPopup;
