import React, { useEffect, useRef } from "react";
import JSConfetti from "js-confetti";
import confetti from "canvas-confetti";
import "./Poppers.scss";
import { useTranslation } from "react-i18next";

interface PoppersProps {
  runFun: boolean;
}
const delayAfterThreeSec = 3000;
const textDelay = 1500;

const Poppers: React.FC<PoppersProps> = ({ runFun }) => {
  const { t } = useTranslation();
  const jsConfetti = new JSConfetti();
  const containerRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    // First Confetti Animation with Emojis (canvas-confetti)
    setTimeout(() => {
      confetti({
        particleCount: 200,
        spread: 160,
        colors: ["#2ebec6", "#006f7a", "#000"],
      });
    }, textDelay);

    // Second Confetti Animation (jsConfetti)
    jsConfetti.addConfetti({
      confettiNumber: 100,
      confettiRadius: 5,
    });

    // Show "Thank You" Message
    const thankYouDiv = document.createElement("div");
    setTimeout(() => {
      thankYouDiv.className = "thankYou";
      thankYouDiv.innerHTML = `<h1>${t("POPPERS_TEXT")}!</h1`;
    }, textDelay);

    if (containerRef.current) {
      containerRef.current.appendChild(thankYouDiv);
      thankYouDiv.style.opacity = "1";
    }

    // Hide and remove the thank you message after 3 seconds
    setTimeout(() => {
      if (thankYouDiv) {
        thankYouDiv.style.opacity = "0";
        setTimeout(() => {
          thankYouDiv.remove();
        }, delayAfterThreeSec); // Delay for fade-out effect
      }
    }, delayAfterThreeSec);
  };
  useEffect(() => {
    if (runFun) {
      handleButtonClick();
    }
  }, [runFun]);

  return (
    <div className="poppersSection" onClick={handleButtonClick}>
      <div className="container-fluid" ref={containerRef}></div>
    </div>
  );
};

export default Poppers;
