const PreviousIcon = () => {
  return (
    <div>
      <svg
        width="35"
        height="34"
        viewBox="0 0 49 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.2222 31.2222L3 16.9999M3 16.9999L17.2222 2.77772M3 16.9999L45.6667 16.9999"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PreviousIcon;
