const AiStarIcon = ({ color }: any) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 95 94"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M94.9983 37.5C95.0536 38.5429 93.7342 39.5889 90.9887 40.4855C75.5452 45.5402 65.1888 55.5786 60.2052 71.0642C59.2484 73.73 58.3855 75.0126 57.4998 74.9999C56.614 75.0126 55.7511 73.73 54.7949 71.0642C49.8107 55.5786 39.4549 45.5401 24.0113 40.4855C21.2659 39.5889 19.9459 38.5429 20.0017 37.5C19.9459 36.4571 21.2659 35.411 24.0113 34.514C39.4548 29.4598 49.8107 19.4214 54.7949 3.93583C55.7511 1.26997 56.614 -0.012571 57.4998 9.28546e-05C58.3855 -0.0125704 59.2484 1.26997 60.2052 3.93583C65.1888 19.4214 75.5452 29.4599 90.9887 34.514C93.7342 35.411 95.0536 36.4571 94.9983 37.5Z"
        fill={color ? color : "#2EBEC6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.9991 74.5C39.0279 75.0423 38.3418 75.5863 36.9141 76.0525C28.8835 78.6809 23.4982 83.9009 20.9067 91.9534C20.4092 93.3396 19.9605 94.0065 19.4999 94C19.0393 94.0065 18.5906 93.3396 18.0933 91.9534C15.5016 83.9009 10.1165 78.6809 2.08589 76.0525C0.658251 75.5863 -0.0281348 75.0423 0.000882508 74.5C-0.0281345 73.9577 0.658251 73.4137 2.08589 72.9473C10.1165 70.3191 15.5016 65.0991 18.0933 57.0466C18.5906 55.6604 19.0393 54.9935 19.4999 55C19.9605 54.9935 20.4092 55.6604 20.9067 57.0466C23.4982 65.0991 28.8835 70.3191 36.9141 72.9473C38.3418 73.4137 39.0279 73.9577 38.9991 74.5Z"
        fill={color ? color : "#2EBEC6"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9997 7.5C35.0107 7.70859 34.7468 7.91779 34.1977 8.0971C31.109 9.10803 29.0378 11.1157 28.041 14.2128C27.8497 14.746 27.6771 15.0025 27.5 15C27.3228 15.0025 27.1502 14.746 26.959 14.2128C25.9621 11.1157 23.891 9.10803 20.8023 8.0971C20.2532 7.91779 19.9892 7.70859 20.0003 7.5C19.9892 7.29141 20.2532 7.08221 20.8023 6.90279C23.891 5.89196 25.9621 3.88429 26.959 0.787167C27.1502 0.253994 27.3228 -0.00251419 27.5 1.85709e-05C27.6771 -0.00251407 27.8497 0.253994 28.041 0.787167C29.0378 3.88429 31.109 5.89197 34.1977 6.90279C34.7468 7.08221 35.0107 7.29141 34.9997 7.5Z"
        fill={color ? color : "#2EBEC6"}
      />
    </svg>
  );
};

export default AiStarIcon;
