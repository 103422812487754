const SmartAssessLogo = (props: {
  width?: string;
  height?: string;
  color?: string;
}) => {
  const { color, width, height } = props;
  return (
    <svg
      width={width ? width : "24"}
      height={height ? height : "24"}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1893 11.976C17.2009 12.1944 16.9249 12.4134 16.3505 12.6013C13.1198 13.6599 10.9533 15.7624 9.91074 19.0056C9.71065 19.564 9.53005 19.8326 9.34485 19.83C9.15946 19.8326 8.97905 19.564 8.77896 19.0056C7.73637 15.7624 5.56991 13.6599 2.33916 12.6013C1.76481 12.4134 1.48876 12.1944 1.50035 11.976C1.48876 11.7574 1.76481 11.5384 2.33916 11.3505C5.56991 10.2919 7.73637 8.18962 8.77896 4.94616C8.97905 4.388 9.15946 4.11939 9.34485 4.12197C9.53004 4.11939 9.71065 4.388 9.91074 4.94616C10.9533 8.18963 13.1198 10.2919 16.3505 11.3505C16.9249 11.5384 17.2009 11.7574 17.1893 11.976Z"
        fill={color ? color : "#00CCCC"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.4163 6.71477C12.4163 6.37091 12.6951 6.09216 13.0391 6.09216H21.8772C22.2212 6.09216 22.5 6.37091 22.5 6.71477C22.5 7.05862 22.2212 7.33737 21.8772 7.33737H13.0391C12.6951 7.33737 12.4163 7.05862 12.4163 6.71477ZM12.4163 17.2376C12.4163 16.8937 12.6951 16.615 13.0391 16.615H21.8772C22.2212 16.615 22.5 16.8937 22.5 17.2376C22.5 17.5814 22.2212 17.8602 21.8772 17.8602H13.0391C12.6951 17.8602 12.4163 17.5814 12.4163 17.2376Z"
        fill={color ? color : "#00CCCC"}
      />
    </svg>
  );
};

export default SmartAssessLogo;
