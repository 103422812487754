import React from "react";

const VideoMaximize = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 8.5625V10.625C12 11.3844 11.3844 12 10.625 12H8.5625M4.28811 12H2.375C1.61559 12 1 11.3844 1 10.625V8.5625M1 4.4375V2.375C1 1.61561 1.61559 1 2.375 1H4.4375M12 4.4375V2.375C12 1.61561 11.3844 1 10.625 1H8.5625"
        stroke="white"
      />
    </svg>
  );
};

export default VideoMaximize;
