const EmailIcon = () => {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.20633 2.15101C1.63706 2.69706 1.25581 3.58309 1.25581 4.97297V11.027C1.25581 12.4169 1.63706 13.3029 2.20633 13.849C2.78401 14.4031 3.65439 14.7027 4.81395 14.7027H13.186C14.3456 14.7027 15.2159 14.4031 15.7937 13.849C16.3629 13.3029 16.7442 12.4169 16.7442 11.027V4.97297C16.7442 3.58309 16.3629 2.69706 15.7937 2.15101C15.2159 1.59686 14.3456 1.2973 13.186 1.2973H4.81395C3.65439 1.2973 2.78401 1.59686 2.20633 2.15101ZM1.35181 1.20035C2.23924 0.349085 3.46189 0 4.81395 0H13.186C14.5381 0 15.7608 0.349085 16.6482 1.20035C17.5441 2.05969 18 3.33583 18 4.97297V11.027C18 12.6642 17.5441 13.9403 16.6482 14.7997C15.7608 15.6509 14.5381 16 13.186 16H4.81395C3.46189 16 2.23924 15.6509 1.35181 14.7997C0.455961 13.9403 0 12.6642 0 11.027V4.97297C0 3.33583 0.455961 2.05969 1.35181 1.20035Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1646 3.94405C15.3767 4.22752 15.3261 4.63489 15.0518 4.85392L10.4077 8.56099C9.57851 9.22279 8.42141 9.22279 7.59223 8.56099L2.94818 4.85392C2.67377 4.63489 2.62321 4.22752 2.83525 3.94405C3.04729 3.66057 3.44163 3.60834 3.71604 3.82739L8.36004 7.5344C8.73695 7.83529 9.26297 7.83529 9.6398 7.5344L14.2839 3.82739C14.5583 3.60834 14.9526 3.66057 15.1646 3.94405Z"
        fill="black"
      />
    </svg>
  );
};

export default EmailIcon;
