import "./Loader.scss";

const Loader = () => {
  return (
    <>
      <span className="loader"></span>
    </>
  );
};

export default Loader;
