import React, { useEffect } from "react";
import "./SampleQuestions.scss";
// import { Bullets, CheckboxRadio } from "ui-components";
import { useTranslation } from "react-i18next";
// import BackArrow from "../../../utils/Images/BackArrow";
import ComingSoon from "../../../components/ComingSoon/ComingSoon";
// const sampleData = [
//   {
//     category: "Behavioral Questions",
//     questions: [
//       {
//         id: 1,
//         type: "Video",
//         time: "1 min",
//         technology: "Nodejs - Basic",
//         questionText:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//         options: [],
//       },
//       {
//         id: 2,
//         type: "Single",
//         time: "1 min",
//         technology: "Nodejs - Basic",
//         questionText:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//         options: [
//           {
//             text: "Lorem Ipsum is simply dummy text of the.",
//             isDefault: false,
//           },
//           {
//             text: "Simply dummy text of the printing and typesetting industry.",
//             isDefault: true,
//           },
//           { text: "Typesetting industry.", isDefault: false },
//           {
//             text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//             isDefault: false,
//           },
//         ],
//       },
//       {
//         id: 3,
//         type: "Essay",
//         time: "5 min",
//         technology: "Nodejs - Intermediate",
//         questionText:
//           "Please describe the impact of technology on modern education.",
//         options: [],
//       },
//     ],
//   },
//   {
//     category: "Cultural Fit",
//     questions: [
//       {
//         id: 1,
//         type: "Multiple",
//         time: "1 min",
//         technology: "Nodejs - Basic",
//         questionText:
//           "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//         options: [
//           {
//             text: "Lorem Ipsum is simply dummy text of the.",
//             isDefault: false,
//           },
//           {
//             text: "Simply dummy text of the printing and typesetting industry.",
//             isDefault: true,
//           },
//           { text: "Typesetting industry.", isDefault: false },
//           {
//             text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//             isDefault: false,
//           },
//         ],
//       },
//     ],
//   },
// ];
const SampleQuestions: React.FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    // <div className="sample-questions">
    //   <div className="sample-header">
    //     <div className="backArrow" onClick={() => window.history.back()}>
    //       <BackArrow />
    //     </div>
    //     <span>{t("GENERAL.SAMPLE_QUEST")}</span>
    //   </div>
    //   {sampleData.map((categoryData, index) => (
    //     <div key={index} className="category-section">
    //       <h3>
    //         {t("GENERAL.CATEGORY")} : <span>{categoryData.category}</span>
    //       </h3>
    //       {categoryData.questions.map((question) => (
    //         <div key={question.id} className="question-card">
    //           <div className="question-header">
    //             <Bullets skillName={question.technology} />
    //             <span className="question-info">
    //               {t("GENERAL.TYPE")} : <span>{question.type}</span> |{" "}
    //               {t("GENERAL.TIME")} : <span>{question.time}</span>
    //             </span>
    //           </div>
    //           <span className="question-text">
    //             <strong>Q. {question.id} </strong>
    //             {question.questionText}
    //             {question.options.length ? <hr /> : null}
    //           </span>
    //           {/* Conditional rendering based on question type */}
    //           {question.type === "Single" && (
    //             <div className="question-options">
    //               {question.options.map((option, idx) => (
    //                 <label key={idx} className="option-label">
    //                   <CheckboxRadio
    //                     name="ansOptions"
    //                     type="radio"
    //                     checked={option.isDefault}
    //                     additionalProps={{ disabled: option.isDefault }}
    //                   />
    //                   {option.text}
    //                 </label>
    //               ))}
    //             </div>
    //           )}
    //           {question.type === "Multiple" && (
    //             <div className="question-options">
    //               {question.options.map((option, idx) => (
    //                 <label key={idx} className="option-label">
    //                   <CheckboxRadio
    //                     name="ansOptions"
    //                     type="checkbox"
    //                     checked={option.isDefault}
    //                     additionalProps={{ disabled: option.isDefault }}
    //                   />
    //                   {option.text}
    //                 </label>
    //               ))}
    //             </div>
    //           )}
    //         </div>
    //       ))}
    //     </div>
    //   ))}
    // </div>
    <ComingSoon title={t("GENERAL.SAMPLE_QUEST")} isBackArrowVisible={true} />
  );
};

export default SampleQuestions;
