import React, { ReactNode } from "react";
import "./ErrorBoundary.scss";
import { useTranslation } from "react-i18next";

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    console.log("Error: ", error);
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log("Error: ", error, errorInfo);
  }
  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

const ErrorFallback: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div id="errorScreen">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="errorMessage">
              <img
                src={require("../Images/errorImg.png")}
                alt={t("GENERAL.SOMETHING_WENT_WRONG")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ErrorBoundary;
