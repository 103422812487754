import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// get api for createAssessmentDraftJson
export const getCreateAssessmentDraftJson = async (
  userId: string,
  tempAssessmentUId: string | undefined,
) => {
  try {
    return await apiClient.get(
      `assessment/getAssessmentTempData?userId=${userId}&tempAssessmentUId=${tempAssessmentUId}`,
    );
  } catch (error) {
    handleError(error);
  }
};

// post api for createAssessmentDraftJson
export const createAssessmentDraftJson = async (data: any) => {
  try {
    return await apiClient.post("assessment/createAssessment", data);
  } catch (error) {
    handleError(error);
  }
};
