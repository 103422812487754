import { useTranslation } from "react-i18next";
import { InputField } from "ui-components";
import "./AdvanceSetting.scss";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { useEffect } from "react";
interface Location {
  setAdvanceSettingData: any;
  advanceSettingData: any;
}
const Location: React.FC<Location> = ({
  setAdvanceSettingData,
  advanceSettingData,
}) => {
  const { t } = useTranslation();
  const companyAddress = localStorage.getItem("companyAddress") || "";
  // to handle location text
  useEffect(() => {
    if (!advanceSettingData?.location) {
      setAdvanceSettingData((prev: any) => ({
        ...prev,
        location: companyAddress,
      }));
    }
  }, [advanceSettingData?.location, companyAddress, setAdvanceSettingData]);
  const handleLocationText = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e;
    setAdvanceSettingData((prev: any) => {
      const updatedData = { ...prev, location: value };
      setSessionStorageItem("advanceSettingData", updatedData);
      return updatedData;
    });
  };
  return (
    <div className="locationType mt-3 mb-3">
      <span>{t("CREATE_JOB_LOCATION")}</span>
      <InputField
        additionalProps={{
          as: "textarea",
          rows: 2,
          style: { resize: "none" },
          disabled: advanceSettingData?.disable,
          className: advanceSettingData?.disable
            ? "disableBackground"
            : "enableBackground",
        }}
        value={
          advanceSettingData?.location
            ? advanceSettingData?.location
            : companyAddress
        }
        onChange={(e: any) => handleLocationText(e)}
      />
    </div>
  );
};

export default Location;
