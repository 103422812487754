import { useTranslation } from "react-i18next";
import { formatTime } from "../../../../utils/Common/Constant";
import "./QuestionType.scss";
import VideoType from "./VideoType";
import SingleMultiTypes from "./SIngleMultiTypes";
import EssayType from "./EssayType";
import CodingType from "./CodingType";
import AiStarIcon from "../../../../utils/Images/AiStarIcon";
interface QuestionRapper {
  questionRapperData: any;
  handleAIClick: any;
  isAiEvaluation: any;
  isPaused: boolean;
  isMaximize: boolean;
  evaluationTypesObj: any;
  handleManualReview: any;
  isNotified?: boolean;
  evaluationStatus?: any;
}
const QuestionRapper: React.FC<QuestionRapper> = ({
  questionRapperData,
  handleAIClick,
  isAiEvaluation,
  isPaused,
  isMaximize,
  evaluationTypesObj,
  handleManualReview,
  isNotified,
  evaluationStatus,
}) => {
  const { t } = useTranslation();
  const renderAnswerType = (type: string, answerData: any) => {
    switch (type) {
      case "Single":
        return <SingleMultiTypes answerData={answerData} />;
      case "Multiple":
        return <SingleMultiTypes answerData={answerData} />;
      case "Essay":
        return <EssayType answerData={answerData} />;
      case "Video":
        return (
          <VideoType
            answerData={answerData}
            handleAIClick={handleAIClick}
            isAiEvaluation={isAiEvaluation}
            isPaused={isPaused}
            isMaximize={isMaximize}
            isCustomQuestion={
              questionRapperData?.questionData?.isCustomQuestion
            }
            evaluationTypesObj={evaluationTypesObj}
            handleManualReview={handleManualReview}
            isNotified={isNotified}
            evaluationStatus={evaluationStatus}
          />
        );
      case "Coding":
        return <CodingType answerData={answerData} />;

      default:
        break;
    }
  };
  return (
    <div className="questionRapper">
      <div className="responseQuestion mt-3 questionFormat">
        <div className="d-flex gap-2">
          <p className="questionFormat">{questionRapperData?.questionNo}.</p>
          <p
            className="questionFormat"
            dangerouslySetInnerHTML={{
              __html: ` ${questionRapperData?.questionData?.question}`,
            }}
          />
        </div>
        <div className="questionTime">
          <div className="questionTimeType">
            <p className="questionType">
              {questionRapperData?.questionData?.type === "Video" &&
                !questionRapperData?.questionData?.isCustomQuestion && (
                  <AiStarIcon />
                )}{" "}
              {questionRapperData?.questionData?.type}
            </p>
            <p className="questionTimeFormate">
              <span>{t("GENERAL.TIME")} :</span>{" "}
              <b>
                {formatTime(questionRapperData?.questionData?.responseTime)}/
                {formatTime(questionRapperData?.questionData?.timeLimit * 60)}
              </b>
            </p>
          </div>
        </div>
      </div>
      <div className="QuestionRapperHorizontalLine"></div>
      {renderAnswerType(
        questionRapperData?.questionData?.type,
        questionRapperData?.questionData,
      )}
    </div>
  );
};

export default QuestionRapper;
