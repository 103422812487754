const TwitterIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 26C20.1797 26 26 20.1797 26 13C26 5.8203 20.1797 0 13 0C5.8203 0 0 5.8203 0 13C0 20.1797 5.8203 26 13 26Z"
        fill="#1DA1F2"
      />
      <path
        d="M20.8002 8.00059C20.2267 8.25539 19.6095 8.42699 18.9621 8.50447C19.623 8.10823 20.1305 7.48117 20.3691 6.734C19.7514 7.10105 19.0656 7.36678 18.3372 7.51082C17.7532 6.88896 16.9207 6.5 16.0009 6.5C14.2336 6.5 12.8005 7.93306 12.8005 9.70041C12.8005 9.95098 12.8291 10.1954 12.8837 10.4299C10.2236 10.2968 7.86552 9.02233 6.28693 7.08597C6.01133 7.55865 5.85377 8.10823 5.85377 8.69479C5.85377 9.80493 6.41797 10.7845 7.27747 11.3586C6.75227 11.3419 6.25937 11.1979 5.82725 10.9582V10.9982C5.82725 12.5493 6.93115 13.8424 8.39436 14.1372C8.12604 14.21 7.84316 14.2496 7.55151 14.2496C7.34507 14.2496 7.14435 14.2293 6.94883 14.1918C7.35599 15.4632 8.5384 16.3887 9.93863 16.4147C8.84358 17.2726 7.46363 17.7848 5.96401 17.7848C5.70505 17.7848 5.45032 17.7697 5.2002 17.7395C6.61661 18.6475 8.29868 19.1778 10.1061 19.1778C15.9926 19.1778 19.2117 14.301 19.2117 10.0721C19.2117 9.9333 19.2091 9.79505 19.2024 9.65829C19.8284 9.20589 20.3712 8.64227 20.8002 8.00059Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterIcon;
