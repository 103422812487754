import React from "react";

const PauseVideoIcon = () => {
  return (
    <svg
      width="28"
      height="37"
      viewBox="0 0 28 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66667 0C2.09067 0 0 2.072 0 4.625V32.375C0 34.928 2.09067 37 4.66667 37C7.24267 37 9.33333 34.928 9.33333 32.375V4.625C9.33333 2.072 7.24267 0 4.66667 0ZM28 4.625V32.375C28 34.928 25.9093 37 23.3333 37C20.7573 37 18.6667 34.928 18.6667 32.375V4.625C18.6667 2.072 20.7573 0 23.3333 0C25.9093 0 28 2.072 28 4.625Z"
        fill="#4D4D4D"
      />
    </svg>
  );
};

export default PauseVideoIcon;
