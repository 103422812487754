import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get recharge slab
export const getRechargeSlab = async (currencyId: string) => {
  try {
    return (
      await apiClient.get(
        `rechargeSlab/getRechargeSlabs?currencyId=${currencyId}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// calculatePayment
export const calculatePayment = async (data: any) => {
  try {
    const res = await apiClient.post("company/calculatePayment", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
// process amount
export const rechargeAnAccount = async (data: any) => {
  try {
    const res = await apiClient.post("company/rechargeAnAccount", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get payment method
export const getPaymentMethod = async (companyId: string, branchId: string) => {
  try {
    return (
      await apiClient.get(
        `company/getPaymentMethod?companyId=${companyId}&branchId=${branchId}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get company account info
export const getCompanyAccountInfo = async (
  companyId: string,
  branchId: string,
) => {
  try {
    return (
      await apiClient.get(
        `company/getCompanyAccountInfo?branchId=${branchId}&companyId=${companyId}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

export const getCompanyTransactionHistory = async (
  companyId: string,
  branchId: string,
  pageSize: any,
  currentPage: any,
  searchTransaction?: string,
) => {
  try {
    return (
      await apiClient.get(
        `company/getCompanyTransactionHistory?branchId=${branchId}&companyId=${companyId}&search=${searchTransaction}&pageSize=${pageSize}&currentPage=${currentPage}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};
