const FacebookIcon = () => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 0C5.84999 0 0 5.86044 0 13.0783C0 19.6044 4.75799 25.0211 10.972 26V16.8635H7.66999V13.0783H10.972V10.1938C10.972 6.91766 12.909 5.11646 15.886 5.11646C17.303 5.11646 18.785 5.36445 18.785 5.36445V8.58835H17.147C15.535 8.58835 15.028 9.59336 15.028 10.6245V13.0783H18.642L18.057 16.8635H15.028V26C18.0913 25.5142 20.8808 23.9449 22.8928 21.5753C24.9048 19.2058 26.007 16.1921 26 13.0783C26 5.86044 20.15 0 13 0Z"
        fill="#4460A0"
      />
    </svg>
  );
};

export default FacebookIcon;
