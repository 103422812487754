import { useTranslation } from "react-i18next";
import { Field, Form, Formik } from "formik";
import { AssessmentButton, InputField, showToast } from "ui-components";
import * as Yup from "yup";
import CompanyOnboardWrapper from "../../../../components/CompanyOnboard/CompanyOnboardWrapper";
import { loginUserData } from "../../../../utils/Common/Constant";
import { useNavigate } from "react-router-dom";
import { forgotpasswordApi } from "../../../../service/Scope/Scope";
const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });
  const onSignInClick = () => {
    navigate("/");
  };
  //call to get link after enter email
  const handleForgotPassword = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      email: value?.email,
      type: "Company",
    };
    try {
      const res = await forgotpasswordApi(data);
      resetForm && resetForm();
      setSubmitting && setSubmitting(true);
      if (res?.status === 200) {
        showToast(res?.customMessage, "success");
        navigate("/", { replace: true });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      setSubmitting && setSubmitting(false);
    }
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.ALREADY_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_IN")}
      signInUpClick={onSignInClick}
      heading1={t("GENERAL.RESET_YOUR_PASS")}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          //get list on mail for forgot password
          handleForgotPassword(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <div className="companyLogin">
              <Form>
                <div className="userSec">
                  <div className="userContent">
                    <Field name="email">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.REGISTER_EMAIL")} *`}
                          onChange={(newValue: any) =>
                            setFieldValue("email", newValue.trim())
                          }
                          value={values.email}
                          additionalProps={{ className: "textInput" }}
                        />
                      )}
                    </Field>
                    {errors?.email && touched?.email ? (
                      <div className="errorMessage ">{errors?.email}</div>
                    ) : null}
                    <AssessmentButton
                      className="submitBtn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("GENERAL.LOADING")
                        : t("GENERAL.GET_LINK")}
                    </AssessmentButton>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </CompanyOnboardWrapper>
  );
};
export default ForgotPassword;
