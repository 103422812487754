const ManualReviewLogo = (props: any) => {
  const { color } = props;
  return (
    <svg
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.3104 18H19.7426C19.2738 18 18.8936 17.6287 18.8936 17.1709C18.8936 16.7131 19.2738 16.3418 19.7426 16.3418H20.3104C20.8572 16.3418 21.302 15.9075 21.302 15.3734V2.62656C21.302 2.09264 20.8573 1.65816 20.3104 1.65816H2.6895C2.14279 1.65816 1.69789 2.09253 1.69789 2.62656V15.3734C1.69789 15.9074 2.14267 16.3418 2.6895 16.3418H14.4893C14.9581 16.3418 15.3383 16.7131 15.3383 17.1709C15.3383 17.6287 14.9581 18 14.4893 18H2.6895C1.20656 18 0 16.8218 0 15.3734V2.62656C0 1.17821 1.20656 0 2.6895 0H20.3105C21.7935 0 23 1.17821 23 2.62656V15.3734C22.9999 16.8218 21.7934 18 20.3104 18Z"
        fill={color}
      />
      <path
        d="M14.3445 22H8.42554C7.96145 22 7.58508 21.6237 7.58508 21.1596V17.4066C7.58508 16.9425 7.96145 16.5662 8.42554 16.5662H14.3445C14.8086 16.5662 15.1849 16.9425 15.1849 17.4066V21.1596C15.185 21.6237 14.8086 22 14.3445 22ZM9.26606 20.319H13.504V18.2471H9.26606V20.319Z"
        fill={color}
      />
      <path
        d="M16.4601 22H6.31006C5.84597 22 5.4696 21.6236 5.4696 21.1596C5.4696 20.6955 5.84597 20.3191 6.31006 20.3191H16.4601C16.9242 20.3191 17.3006 20.6955 17.3006 21.1596C17.3006 21.6236 16.9242 22 16.4601 22Z"
        fill={color}
      />
      <path
        d="M11.385 12.4998C8.92625 12.4998 6.9259 10.4995 6.9259 8.04062C6.9259 5.58189 8.92625 3.58154 11.385 3.58154C13.8438 3.58154 15.8442 5.58189 15.8442 8.04062C15.8442 10.4995 13.8438 12.4998 11.385 12.4998ZM11.385 5.26258C9.85312 5.26258 8.60688 6.50882 8.60688 8.04068C8.60688 9.57265 9.85312 10.8189 11.385 10.8189C12.917 10.8189 14.1632 9.57265 14.1632 8.04068C14.1632 6.50882 12.9169 5.26258 11.385 5.26258Z"
        fill={color}
      />
      <path
        d="M16.0034 18.2472H6.76661C6.30252 18.2472 5.92615 17.8709 5.92615 17.4068V16.532C5.92615 14.2434 7.29712 12.2088 9.41883 11.3485C9.51912 11.3078 9.62643 11.2869 9.73466 11.2869H13.0354C13.1436 11.2869 13.2509 11.3078 13.3512 11.3485C15.4729 12.2088 16.8439 14.2434 16.8439 16.532V17.4068C16.8439 17.8709 16.4676 18.2472 16.0034 18.2472ZM7.60707 16.5663H15.1629V16.532C15.1629 14.9836 14.2655 13.6014 12.8634 12.9679C11.7091 12.5883 11.0609 12.581 9.90663 12.9679C8.50435 13.6014 7.60707 14.9836 7.60707 16.532V16.5663Z"
        fill={color}
      />
    </svg>
  );
};

export default ManualReviewLogo;
