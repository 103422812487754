import React from "react";
interface Voice_VisualAnalysis {
  evaluationSummary?: any;
  t?: any;
  isVisualAnalysis?: boolean;
}
const Voice_VisualAnalysis: React.FC<Voice_VisualAnalysis> = ({
  evaluationSummary,
  t,
  isVisualAnalysis,
}) => {
  return (
    <div className="additionalAnalysis mt-3">
      <div className="row">
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.CALM")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Calm ?? evaluationSummary?.CALM ?? 0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.HAPPY")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Happy ?? evaluationSummary?.HAPPY ?? 0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>{" "}
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.SAD")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Sad ?? evaluationSummary?.SAD ?? 0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.ANGRY")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Angry ?? evaluationSummary?.ANGRY ?? 0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.CONFUSED")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Confused ??
                        evaluationSummary?.CONFUSED ??
                        0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.DISGUSTED")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Disgusted ??
                        evaluationSummary?.DISGUSTED ??
                        0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.FEAR")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Fear ?? evaluationSummary?.FEAR ?? 0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="analysisSub mb-4">
            <span className="emotions-title">{t("GENERAL.SURPRISED")}</span>
            <br />
            <span>
              {isVisualAnalysis
                ? evaluationSummary
                  ? Math.round(
                      evaluationSummary?.Surprised ??
                        evaluationSummary?.SURPRISED ??
                        0,
                    ) + "%"
                  : "-"
                : "-"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Voice_VisualAnalysis;
