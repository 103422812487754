function NoAssessListFoundIcon() {
  return (
    <svg
      width="113"
      height="129"
      viewBox="0 0 113 129"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.561 77.2343C48.5808 80.9509 51.2149 83.3737 57.4816 84.9084C75.2297 89.2553 91.7551 80.1303 102.26 66.2159C105.49 61.9371 108.475 56.5031 110.211 51.4375C114.352 39.3484 114.277 25.5353 107.29 14.478C92.8949 -8.30249 62.0859 -1.55835 46.8971 16.4916C35.4425 14.8986 29.1212 14.1946 18.1937 21.9466C6.40987 30.3047 -1.04979 45.5533 0.12038 59.1858C1.69867 77.5826 18.5049 88.3478 35.9681 81.0767C38.4574 80.0403 40.9553 78.7194 43.561 77.2343Z"
        fill="#DFFFF7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.759 110.239H110.889C112.015 110.239 112.936 111.16 112.936 112.286C112.936 113.411 112.015 114.333 110.889 114.333H14.759C13.6333 114.333 12.7124 113.412 12.7124 112.286C12.7124 111.16 13.6333 110.239 14.759 110.239ZM39.3161 14.7039H85.9529C87.538 14.7039 88.978 15.3521 90.021 16.3951V16.402C91.0635 17.4451 91.7111 18.8845 91.7111 20.4647V90.5464C91.7111 92.1267 91.0635 93.5661 90.021 94.6091V94.6161C88.978 95.6591 87.538 96.3073 85.9529 96.3073H39.3161C37.7311 96.3073 36.2911 95.6591 35.2481 94.6161V94.6091C34.2056 93.5661 33.5579 92.1267 33.5579 90.5464V20.4647C33.5579 18.8845 34.2056 17.4451 35.2481 16.402V16.3951C36.2911 15.3521 37.7311 14.7039 39.3161 14.7039ZM71.2796 92.6933L88.097 75.6907V20.4649C88.097 19.8727 87.8564 19.3344 87.4701 18.946L87.4664 18.9497C87.0775 18.5608 86.5408 18.318 85.9528 18.318H39.316C38.7281 18.318 38.1914 18.5608 37.8024 18.9497L37.7992 18.946C37.413 19.3344 37.1718 19.8727 37.1718 20.4649V90.5465C37.1718 91.1387 37.413 91.6765 37.7992 92.0649L37.8024 92.0617C38.1914 92.4507 38.7281 92.6934 39.316 92.6934L71.2796 92.6933ZM88.097 80.8289L76.3618 92.6933H85.9528C86.5408 92.6933 87.0775 92.4505 87.4664 92.0616L87.4701 92.0648C87.8564 91.6764 88.097 91.1386 88.097 90.5464V80.8289ZM35.4386 124.143H90.2804C91.4061 124.143 92.327 125.064 92.327 126.189C92.327 127.315 91.4061 128.237 90.2804 128.237H35.4386C34.3129 128.237 33.3921 127.316 33.3921 126.19C33.3921 125.064 34.3129 124.143 35.4386 124.143Z"
        fill="#35D4AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8372 50.8734H82.1628C82.6233 50.8734 83 51.238 83 51.6838C83 52.1295 82.6233 52.4944 82.1628 52.4944H42.8372C42.3767 52.4944 42 52.1297 42 51.684C42 51.2382 42.3767 50.8734 42.8372 50.8734ZM42.8372 56.379H65.2722C65.7327 56.379 66.1094 56.7437 66.1094 57.1894C66.1094 57.6352 65.7327 58 65.2722 58H42.8372C42.3767 58 42 57.6354 42 57.1896C42 56.7438 42.3767 56.379 42.8372 56.379Z"
        fill="#35D4AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.8372 45C42.8372 45 81.7023 45 82.1628 45C82.6233 45 83 45.3646 83 45.8104C83 46.2561 82.6233 46.621 82.1628 46.621H42.8372C42.3767 46.621 42 46.2563 42 45.8106C42 45.3648 42.3767 45 42.8372 45Z"
        fill="#35D4AE"
      />
      <rect x="42" y="24" width="15" height="15" fill="#ABFFEB" />
    </svg>
  );
}
export default NoAssessListFoundIcon;
