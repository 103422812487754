// AssessmentContext.tsx

import React, { createContext, useState, useEffect, ReactNode } from "react";
import { getFrontEndSystemPreference } from "../../service/Scope/Scope";

interface SystemPreferenceContextProvider {
  children: ReactNode;
}

// Define the type for your context
type SystemPreferenceContextType = {
  preferenceData: any | null;
  setPreferenceData: React.Dispatch<React.SetStateAction<any | null>>;
};

// Create a new context
export const systemPreferenceContext =
  createContext<SystemPreferenceContextType>({
    preferenceData: null,
    setPreferenceData: () => null,
  });

// Create a provider component
export const SystemPreferenceContextProvider: React.FC<
  SystemPreferenceContextProvider
> = ({ children }) => {
  const [preferenceData, setPreferenceData] = useState<any | null>(null);

  useEffect(() => {
    // Fetch data from the API
    const fetchData = async () => {
      try {
        const data: any = await getFrontEndSystemPreference();
        setPreferenceData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <systemPreferenceContext.Provider
      value={{ preferenceData, setPreferenceData }}
    >
      {children}
    </systemPreferenceContext.Provider>
  );
};
