import { useTranslation } from "react-i18next";
import { convertDate } from "../../utils/Common/Constant";
import DataNotAvailable from "../../utils/DataNotFound/DataNotAvailable";

export interface TableColumn<T> {
  Header: string;
  accessor: keyof T;
}
export interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  totalCount: any;
}
const TransactionTable = <T extends Record<string, any>>({
  columns,
  data,
  totalCount,
}: TableProps<T>) => {
  const { t } = useTranslation();
  return (
    // Table start
    <>
      {totalCount === 0 ? (
        <DataNotAvailable />
      ) : (
        <div className="table-responsive">
          <table>
            <div className="row mb-3 table-container">
              {/* table header */}
              <div className="tabel-header">
                {columns.map((item, index) => (
                  <div key={index} className="col text-center">
                    <span>{item?.Header}</span>
                  </div>
                ))}
              </div>
              {/* table rows */}
              <div className="container event-row">
                {data?.map((item: any, index: number) => (
                  <>
                    <div key={index} className="row mb-3 event-item">
                      <div className="col text-center">
                        <span>{item?.category ?? "---"}</span>
                      </div>
                      <div className="col text-center">
                        <span>
                          {item?.createdAt
                            ? convertDate(item?.createdAt, false, true)
                            : "---"}
                        </span>
                      </div>
                      <div className="col text-center">
                        <span>{item?.type ?? "---"}</span>
                      </div>
                      <div className="col text-center">
                        <span>{item?.credits ?? 0}</span>
                      </div>

                      <div className="col status-column text-center">
                        <span>
                          {item?.accountBalance ? item?.accountBalance : 0}
                        </span>
                      </div>
                      <div className="col text-center">
                        <div>
                          <p>
                            {t("ACCOUNT.GRAND_TOTAL")}: {item?.meta.grandTotal}
                          </p>
                          <p>
                            {t("ACCOUNT.TAX")}:
                            {item?.meta.tax !== null ? item?.meta.tax : "N/A"}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            </div>
          </table>
        </div>
      )}
    </>
  );
};

export default TransactionTable;
