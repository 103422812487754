import React, { createContext, useReducer, Dispatch, ReactNode } from "react";
import JsonData from "../../utils/Json/smartAssess.json";
// import { getSessionStorageItem } from "../../utils/SessionStorageMethod/setSessionStorageItem";

type Action = { type: "UPDATE_CENTRIC_RENDER_JSON"; payload: any };

interface State {
  renderJson: any;
}

// Create the context
export const AssessmentJsonContext = createContext<
  | {
      state: State;
      dispatch: Dispatch<Action>;
    }
  | undefined
>(undefined);

// Define the reducer function
const assessmentReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case "UPDATE_CENTRIC_RENDER_JSON":
      return {
        ...state,
        renderJson: [{ ...state.renderJson, ...action.payload }],
      };
    default:
      return state;
  }
};

interface AssessmentContextProvider {
  children: ReactNode;
}

// Create the context provider
export const AssessmentJsonContextProvider: React.FC<
  AssessmentContextProvider
> = ({ children }) => {
  const [state, dispatch] = useReducer(assessmentReducer, {
    renderJson: JsonData,
  });

  return (
    <AssessmentJsonContext.Provider value={{ state, dispatch }}>
      {children}
    </AssessmentJsonContext.Provider>
  );
};
