import CustomModal from "../../../../components/CustomModal/CustomModal";
import { useTranslation } from "react-i18next";
import { AssessmentButton } from "ui-components";
interface NotifyConfirmPopupProps {
  isPopupShow?: any;
  setIsClosePopup?: any;
  handleYesPress?: any;
  isDisable?: any;
}
const NotifyConfirmPopup = ({
  isPopupShow,
  setIsClosePopup,
  handleYesPress,
  isDisable,
}: NotifyConfirmPopupProps) => {
  const { t } = useTranslation();
  //function to have notify popup open/close
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const handleNotifyOpenCloseStatus = () => {
    url.searchParams.delete("apllid");
    url.searchParams.set("notifyConfirmation", "false");
    history.replaceState({}, "", url.toString());
    const isPopUpOpen: any = new URLSearchParams(location.search).get(
      "notifyConfirmation",
    );
    setIsClosePopup(JSON.parse(isPopUpOpen));
  };
  return (
    <CustomModal
      show={isPopupShow}
      handleClose={() => setIsClosePopup(false)}
      isHeaderVisible={true}
      isFooterVisible={false}
      instructionHeadTitle={t("Notify Applicant")}
      className="closeAssessPopup"
    >
      <div className="markAsCloseModal">
        <div className="closeModalText">
          <span className="">{t("GENERAL.NOTIFY_MSG")} </span>
        </div>
        <div className="closeModalBtn">
          <AssessmentButton
            onClick={handleYesPress}
            className="lightBtn"
            disabled={isDisable}
          >
            {t("GENERAL.YES")}
          </AssessmentButton>
          <AssessmentButton
            onClick={handleNotifyOpenCloseStatus}
            className="lightBtn"
            disabled={isDisable}
          >
            {t("GENERAL.NO")}
          </AssessmentButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default NotifyConfirmPopup;
