import { useTranslation } from "react-i18next";

const SeparateOrLine = () => {
  const { t } = useTranslation();
  return (
    <div className="separateLine">
      <div className="borderline"></div>
      <p className="textor">{t("GENERAL.OR")}</p>
      <div className="borderline"></div>
    </div>
  );
};

export default SeparateOrLine;
