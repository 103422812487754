import { ReactNode } from "react";
import "./FilterButton.scss";
interface IFilterButton {
  children: ReactNode;
  isActive?: boolean;
  handleFilter: (value: string) => void;
  name?: string | undefined;
}
const FilterButton: React.FC<IFilterButton> = ({
  children,
  isActive = false,
  handleFilter,
  name,
}) => {
  // added arrowActive class for active status of arrow filter buttons, rest of the  ilter buttons will have "scoreActive" class.
  const arrowClass =
    isActive && (name === "score" || name === "date")
      ? "arrowActive"
      : isActive
        ? "scoreActive"
        : "";
  return (
    <>
      <div
        onClick={() => {
          handleFilter(name ?? "");
        }}
        className="parentFilterBtn"
      >
        <h6 className={`scoreBtn ${isActive ? arrowClass : ""}`}>{children}</h6>
      </div>
    </>
  );
};
export default FilterButton;
