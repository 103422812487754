const GoogleIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4341 11C17.4351 12.514 16.9018 13.9799 15.928 15.1393C14.9542 16.2987 13.6025 17.0773 12.111 17.3378C10.6195 17.5984 9.08391 17.3242 7.77474 16.5636C6.46557 15.8029 5.46683 14.6047 4.95447 13.1799L1.27441 16.124C2.41871 18.2992 4.25883 20.0283 6.50098 21.0351C8.74312 22.042 11.258 22.2686 13.644 21.6787C16.03 21.0888 18.1495 19.7165 19.6642 17.7808C21.1789 15.8451 22.0013 13.4578 22.0002 11"
        fill="#00AC47"
      />
      <path
        d="M17.4338 11C17.433 12.0236 17.1876 13.0322 16.718 13.9418C16.2485 14.8513 15.5684 15.6355 14.7344 16.2291L18.3726 19.1396C19.5134 18.1125 20.4258 16.8572 21.0507 15.455C21.6755 14.0528 21.9989 12.5351 21.9999 11"
        fill="#4285F4"
      />
      <path
        d="M4.56604 11C4.56763 10.2563 4.69903 9.51856 4.95432 8.82002L1.27426 5.87601C0.437495 7.45434 0 9.21359 0 11C0 12.7864 0.437495 14.5457 1.27426 16.124L4.95432 13.18C4.69903 12.4815 4.56763 11.7437 4.56604 11Z"
        fill="#FFBA00"
      />
      <path
        d="M11.0002 4.56604C12.3616 4.56642 13.6873 5.00148 14.7842 5.80784L18.1547 2.66201C16.9035 1.58323 15.4248 0.800941 13.829 0.373529C12.2332 -0.053883 10.5615 -0.115385 8.93862 0.193613C7.31572 0.50261 5.78356 1.17413 4.45648 2.15806C3.12939 3.14198 2.04165 4.41291 1.27441 5.876L4.95447 8.82001C5.40329 7.57587 6.22435 6.50012 7.30603 5.73901C8.38771 4.97789 9.67754 4.56835 11.0002 4.56604Z"
        fill="#EA4435"
      />
      <path
        d="M21.9999 10.1698V11L20.132 13.9057H11.415V9.33966H21.1698C21.3899 9.33966 21.6011 9.42713 21.7568 9.58282C21.9125 9.73851 21.9999 9.94967 21.9999 10.1698Z"
        fill="#4285F4"
      />
    </svg>
  );
};

export default GoogleIcon;
