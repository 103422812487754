function AssessListUserIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="25" cy="25" r="25" fill="#35D4AE" />
      <path
        d="M27.25 33L29.275 35L34 30.3333M30.0625 27.3021C29.7308 27.1034
         29.3632 26.9614 28.9716 26.8884C28.8867 26.8726 28.7966 26.8613 28.6947
          26.8533C28.4414 26.8333 28.3147 26.8234 28.1456 26.8365C27.9699 26.85 
          27.8718 26.868 27.7021 26.9177C27.539 26.9654 27.2452 27.1034 26.6573 27.3796C25.809 27.7781 24.8669 28 23.875 28C22.8831 28 21.941 27.7781 21.0927 27.3796C20.5048 27.1034 20.2109 26.9653 20.0477 26.9176C19.8781 26.868 19.7798 26.85 19.6041 26.8364C19.435 26.8233 19.3084 26.8333 19.0552 26.8534C18.9517 26.8616 18.8603 26.8731 18.7738 26.8893C17.3887 27.1494 16.3047 28.2735 16.054 29.7099C16 30.019 16 30.3899 16 31.1314V33.1333C16 33.7868 16 34.1134 16.1226 34.363C16.2305 34.5826 16.4026 34.761 16.6143 34.8728C16.8549 35 17.1699 35 17.8 35H23.3125M28.375 18.6667C28.375 21.244 26.3602 23.3333 23.875 23.3333C21.3897 23.3333 19.375 21.244 19.375 18.6667C19.375 16.0893 21.3897 14 23.875 14C26.3602 14 28.375 16.0893 28.375 18.6667Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AssessListUserIcon;
