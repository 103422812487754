import { AssessmentButton, InputField, showToast } from "ui-components";
import CompanyOnboardWrapper from "../../../components/CompanyOnboard/CompanyOnboardWrapper";
import "./LoginSignup.scss";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getOtp, validateReferredLink } from "../../../service/Scope/Scope";
import { loginUserData } from "../../../utils/Common/Constant";
import { useEffect, useState } from "react";
interface ClientDetail {
  clientEmail: any;
  partnerId: any;
}
const SignupScreen = () => {
  const { t, i18n } = useTranslation();
  const searchParams = new URLSearchParams(location.search);
  const partnerIdParam = searchParams.get("q");
  const emailParam = searchParams.get("e");
  // Get current language
  const currentLanguage = i18n.language;
  const navigate: any = useNavigate();
  const rechargeSlabID = new URLSearchParams(location.search).get("sId");
  const templateID = new URLSearchParams(location.search).get("tempId");
  const [referredClientDetail, setReferredClientDetail] = useState<
    ClientDetail | any
  >([]);
  const [isBtnDisable, setBtnDisable] = useState(false);
  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });

  // login applicant
  const handleApplicantSignup = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      email: value?.email?.toLowerCase(),
    };
    try {
      setSubmitting && setSubmitting(true);
      const getCompanyOtp = await getOtp(data);
      if (getCompanyOtp?.status === 200) {
        showToast(getCompanyOtp?.customMessage, "success");
        resetForm && resetForm();
        sessionStorage.setItem("leftTime", "60");
        navigate(
          `/${currentLanguage}/verify-otp${rechargeSlabID ? `?sId=${rechargeSlabID}` : ""}${templateID ? `?tempId=${templateID}` : ""}`,
          { state: data },
        );
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };
  const onSignInCLick = () => {
    navigate("/", {
      state: { rechargeSlabID: rechargeSlabID, templateID: templateID },
    });
  };
  useEffect(() => {
    if (partnerIdParam && emailParam) {
      validateTokenApi();
    } else {
      sessionStorage.removeItem("referredPartnerId");
      sessionStorage.removeItem("referredClientEmail");
    }
  }, [partnerIdParam, emailParam]);
  const validateTokenApi = async () => {
    try {
      const res = await validateReferredLink(partnerIdParam, emailParam);
      if (res?.data?.status === 200) {
        setBtnDisable(true);
        setReferredClientDetail(res?.data?.data);
        sessionStorage.setItem("referredPartnerId", res?.data?.data?.partnerId);
        sessionStorage.setItem(
          "referredClientEmail",
          res?.data?.data?.clientEmail,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.ALREADY_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_IN")}
      signInUpClick={onSignInCLick}
      isGoogleSignBtn={true}
      heading1={t("GENERAL.REGISTER")}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleApplicantSignup(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          useEffect(() => {
            referredClientDetail?.clientEmail &&
              setFieldValue("email", referredClientDetail?.clientEmail.trim());
          }, [referredClientDetail]);
          return (
            <div className="companyLogin">
              <Form>
                <div className="userSec">
                  <div className="userContent">
                    <Field name="email">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.EMAILADDRESS")} *`}
                          onChange={(newValue: any) =>
                            setFieldValue("email", newValue.trim())
                          }
                          value={values.email}
                          additionalProps={{
                            className: "textInput",
                            disabled: isBtnDisable,
                          }}
                        />
                      )}
                    </Field>
                    {errors?.email && touched?.email ? (
                      <div className="errorMessage enterOtpError">
                        {errors?.email}
                      </div>
                    ) : null}
                    <AssessmentButton
                      className="submitBtn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("GENERAL.LOADING")
                        : t("GENERAL.GET_OTP")}
                    </AssessmentButton>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </CompanyOnboardWrapper>
  );
};

export default SignupScreen;
