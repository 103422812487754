import { useTranslation } from "react-i18next";

interface EssayType {
  answerData: any;
}
const EssayType: React.FC<EssayType> = ({ answerData }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="essayTypeQuestion">
        <span>{t("RESPONSE_LIST.ANSWER")} :</span>
        <p className="answerText">{answerData?.response?.answer}</p>
      </div>
    </div>
  );
};

export default EssayType;
