const ResponseVideoTypeIcon = (props: any) => {
  const { color } = props;
  return (
    <div>
      <svg
        width="26"
        height="24"
        viewBox="0 0 26 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9829 12.6128C22.8533 14.9356 21.9906 17.1575 20.5188 18.9592C19.047 20.7608 17.0419 22.0495 14.7916 22.6399C12.5413 23.2303 10.1618 23.092 7.99509 22.245C5.82834 21.398 3.98592 19.8859 2.73264 17.9259C1.47936 15.9659 0.879757 13.659 1.02001 11.3369C1.16026 9.01466 2.03314 6.79673 3.5132 5.00184C4.99326 3.20694 7.00428 1.92751 9.25722 1.34743C11.5102 0.767356 13.889 0.916504 16.0518 1.77344"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.42041 11.3891L12.7074 15.7949L24.5 2.5"
          stroke={color}
          strokeWidth="2.5"
          strokeLinecap="round"
        />
      </svg>
    </div>
  );
};

export default ResponseVideoTypeIcon;
