import { Suspense } from "react";
import Header from "../Header/Header";
import { Outlet } from "react-router-dom";
import Footer from "../../Footer/Footer";
const CreateAssessLayout: React.FunctionComponent = () => {
  return (
    <Suspense>
      <div>
        <Header />
        <div className="totalQuestions">
          <div className="container-fluid">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
};

export default CreateAssessLayout;
