import React from "react";
import CorrectnessIcon from "../../../../utils/Images/CorrectnessIcon";
import VoiceAnalysisIcon from "../../../../utils/Images/VoiceAnalysisIcon";
import VisualAnalysisIcon from "../../../../utils/Images/VisualAnalysisIcon";
import ManualReviewLogo from "../../../../utils/Images/ManualReviewLogo";
// Define types for the props of AnalysisSub component
interface AnalysisSubProps {
  IconComponent: React.ComponentType<{ color: string }>;
  isActive?: boolean;
  label: string;
}

// Reusable AnalysisSub component
const AnalysisSub: React.FC<AnalysisSubProps> = ({
  IconComponent,
  isActive,
  label,
}) => {
  const color = isActive ? "var(--primary-green)" : "var(--light-grey)";

  return (
    <div>
      <div className="analysisSub">
        <IconComponent color={color} />
        <p style={{ color }}>{label}</p>
      </div>
    </div>
  );
};

// Define types for the props of AnalysisRow component
interface EvaluationAttributes {
  aiAnalysis?: boolean;
  visualAnalysis?: boolean;
  audioAnalysis?: boolean;
}

interface EvaluationTypesObj {
  attributes?: EvaluationAttributes;
}

interface AnalysisRowProps {
  evaluationTypesObj?: EvaluationTypesObj;
  t: (key: string) => string;
}

// Main component
const EvaluationTypes: React.FC<AnalysisRowProps> = ({
  evaluationTypesObj,
  t,
}) => {
  const { aiAnalysis, visualAnalysis, audioAnalysis } =
    evaluationTypesObj?.attributes || {};

  return (
    <div className="d-flex justify-content-around criteria-wrap">
      <AnalysisSub
        IconComponent={ManualReviewLogo}
        isActive={true}
        label={t("GENERAL.MANUAL_REVIEW")}
      />
      <AnalysisSub
        IconComponent={CorrectnessIcon}
        isActive={aiAnalysis}
        label={t("RESPONSE_LIST.AI_ANALYSIS")}
      />
      <AnalysisSub
        IconComponent={VisualAnalysisIcon}
        isActive={visualAnalysis}
        label={t("CREATE_ASSESSMENT.LABEL_VISUAL_ANALYSIS")}
      />
      <AnalysisSub
        IconComponent={VoiceAnalysisIcon}
        isActive={audioAnalysis}
        label={t("CREATE_ASSESSMENT.LABEL_VOICE_ANALYSIS")}
      />
    </div>
  );
};

export default EvaluationTypes;
