import "./Invite.scss";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import React, { useEffect, useRef, useState } from "react";
import AssessListUserIcon from "../../utils/Images/AssessListUserIcon";
import "./InviteApplicant.scss";
import InviteSelectIcon from "../../utils/Images/InviteSelectIcon";
import {
  AssessmentButton,
  InputField,
  Pagination,
  showToast,
} from "ui-components";
import { Field, Form, Formik } from "formik";
import {
  getTimeDifference,
  initialInviteData,
} from "../../utils/Common/Constant";
import Loader from "../../components/Loader/Loader";
import RecentEmailIcon from "../../utils/Images/RecentEmailIcon";
import { useParams } from "react-router-dom";
import { resendInviteAnApplicant } from "../../service/Invite/Invite";
import moment from "moment";

export interface InviteApplicantProps {
  sendInvitation?: any;
  applicantListForSearch: any;
  setApplicantListForSearch: any;
  inviteAppTotalCount: number;
  setInviteCurrentPage: any;
  inviteCurrentPage: number;
  invitePageSize: number;
  getApplicantListForSearch: () => any;
  setInvitePageSize: any;
}

const InviteApplicant: React.FC<InviteApplicantProps> = ({
  sendInvitation,
  applicantListForSearch,
  setApplicantListForSearch,
  inviteAppTotalCount,
  setInviteCurrentPage,
  inviteCurrentPage,
  invitePageSize,
  getApplicantListForSearch,
  setInvitePageSize,
}) => {
  const { assessmentIdParam } = useParams();
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [reInviteLoader, setReInviteLoader] = useState<any>(null);
  const invitePaginaitonRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  useEffect(() => {
    if (invitePaginaitonRef.current && managePaginationRef != null) {
      invitePaginaitonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  const inviteSchema = Yup.object().shape({
    inviteEmail: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });

  // manage extra area in invite
  const manageIsopen = (id: string) => {
    setApplicantListForSearch((prevData: any) => {
      return prevData.map((applicant: any) => {
        if (applicant.email === id) {
          if (applicant.isOpen) {
            return { ...applicant, isOpen: false };
          } else {
            return { ...applicant, isOpen: true };
          }
        } else {
          return { ...applicant, isOpen: false };
        }
      });
    });
  };

  // resend invitation
  const reSendInvitation = async (emailId: string | number) => {
    const data = {
      assessmentId: assessmentIdParam,
      emailId,
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      if (emailId) {
        const sendInvitationRes = await resendInviteAnApplicant(data);
        if (sendInvitationRes?.data?.status === 200) {
          showToast(sendInvitationRes?.data?.customMessage, "success");
          getApplicantListForSearch();

          setReInviteLoader(null);
        } else {
          showToast(sendInvitationRes?.data?.customMessage, "error");
          setReInviteLoader(null);
        }
      } else {
        // Handle the case where sendInvitation is undefined
        console.error("sendInvitation function is not defined");
        setReInviteLoader(null);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setReInviteLoader(null);
    }
  };

  return (
    <div>
      <label className="inviteLabel" htmlFor="">
        {t("GENERAL.INVITE")}
      </label>
      <Formik
        initialValues={initialInviteData}
        validationSchema={inviteSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          sendInvitation(value?.inviteEmail, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <Form>
              <div className="applicantList">
                <div className="w-100">
                  <Field name="inviteEmail">
                    {() => (
                      <InputField
                        placeholder={t("GENERAL.EMAIL")}
                        onChange={(newValue) => {
                          setFieldValue(
                            "inviteEmail",
                            newValue.toString().trim(),
                          );
                        }}
                        value={values.inviteEmail}
                      />
                    )}
                  </Field>
                  {errors?.inviteEmail && touched?.inviteEmail ? (
                    <div className="errorMessage">{errors?.inviteEmail}</div>
                  ) : null}
                </div>
                <AssessmentButton type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <>
                      {t("GENERAL.LOADING")}
                      <Loader />
                    </>
                  ) : (
                    t("GENERAL.INVITE")
                  )}
                </AssessmentButton>
              </div>
            </Form>
          );
        }}
      </Formik>
      {applicantListForSearch.length > 0 && (
        <>
          <div className="totalInvite" ref={invitePaginaitonRef}>
            <span>{t("INVITE.INVITE_APPLICANTS")}</span>
            <h4>
              {t("INVITE.TOTAL_INVITED")} : {inviteAppTotalCount}
            </h4>
          </div>
          <div className="userAddSection">
            {applicantListForSearch.map((list: any) => (
              <>
                <div
                  className="userAddContentParent"
                  onClick={() => manageIsopen(list.email)}
                >
                  <div
                    className={`userAddContent
                     ${!list.isOpen ? "pb-3" : "extraHt"}
                     `}
                  >
                    <div className="d-flex gap-2 align-items-center w-100">
                      <AssessListUserIcon />
                      <h4>
                        {list.displayName ?? list.email} <InviteSelectIcon />
                      </h4>
                    </div>
                    <div className="userContentBtn">
                      <h6 title={moment(list?.invitedOn).format("LLL")}>
                        {getTimeDifference(
                          list?.invitedOn,
                          t("GENERAL.MIN(S)"),
                          t("GENERAL.HOUR(S)"),
                          t("GENERAL.DAY(S)"),
                          t("GENERAL.MONTH(S)"),
                          t("GENERAL.YEAR(S)"),
                          t("GENERAL.AGO"),
                          t("GENERAL.JUST_NOW"),
                        ) ?? "-"}
                      </h6>
                    </div>
                  </div>
                  <div className={list.isOpen ? "extraSec" : "extraSecRemove"}>
                    <div className="extraSecChild">
                      <h6>{list.email ?? "-"}</h6>
                    </div>

                    <div className="d-flex">
                      {reInviteLoader == list?.email ? (
                        <>
                          <Loader />
                        </>
                      ) : (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            reSendInvitation(list?.email);
                            setReInviteLoader(list?.email);
                          }}
                        >
                          <p className="resendBtn">
                            {t("GENERAL.RESEND")} <RecentEmailIcon />
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </>
      )}
      <div className="row">
        <div className="col-md-12">
          <Pagination
            className="pagination-bar"
            currentPage={inviteCurrentPage}
            totalCount={inviteAppTotalCount || 0}
            pageSize={invitePageSize}
            onPageChange={(page: number) => {
              setInviteCurrentPage(page);
              setManagePaginationRef(Math.random());
            }}
            ofLabel={t("GENERAL.OF")}
            resultLabel={t("GENERAL.RESULTS")}
            setPageSize={setInvitePageSize}
          />
        </div>
      </div>
    </div>
  );
};

export default InviteApplicant;
