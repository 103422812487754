import React from "react";

const CreditCoin = ({ width, height }: any) => {
  return (
    <div>
      <svg
        width={width}
        height={height}
        viewBox="0 0 370 370"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_11139_2)">
          <path
            d="M190 314.974C270.081 314.974 335 250.061 335 169.987C335 89.913 270.081 25 190 25C109.919 25 45 89.913 45 169.987C45 250.061 109.919 314.974 190 314.974Z"
            fill="#FFF176"
          />
          <path
            d="M190 302.058C262.947 302.058 322.082 242.928 322.082 169.987C322.082 97.0467 262.947 37.9166 190 37.9166C117.053 37.9166 57.9175 97.0467 57.9175 169.987C57.9175 242.928 117.053 302.058 190 302.058Z"
            fill="#F2BC1A"
          />
          <path
            d="M57.6642 175.043C56.7289 157.653 59.7371 140.009 65.804 123.479C72.1238 106.998 81.8056 91.7563 94.0153 78.8146C118.536 52.8807 154.129 37.8411 189.999 37.6641C225.87 37.8663 261.488 52.8807 285.958 78.8399C298.193 91.7563 307.85 107.023 314.17 123.479C320.262 140.035 323.27 157.653 322.309 175.043H321.804C321.754 157.695 318.319 140.523 311.692 124.49C305.054 108.475 295.36 93.9054 283.152 81.595C270.937 69.2968 256.421 59.5227 240.431 52.8301C224.446 46.1567 207.296 42.7205 189.974 42.7205C172.652 42.7205 155.502 46.1567 139.517 52.8301C123.527 59.5227 109.011 69.2968 96.796 81.595C84.5803 93.8987 74.8855 108.47 68.2561 124.49C61.6289 140.523 58.1942 157.695 58.1445 175.043H57.6642Z"
            fill="#E08F00"
          />
          <path
            d="M190 25.0258C109.916 25.0258 44.9998 89.9364 44.9998 170.013C44.9998 250.09 109.916 315 190 315C270.083 315 335 250.09 335 170.013C335 89.9364 270.083 25.0258 190 25.0258ZM190 302.084C117.045 302.084 57.9173 242.962 57.9173 170.013C57.9173 97.0391 117.045 37.9169 190 37.9169C262.955 37.9169 322.082 97.0391 322.082 169.988C322.082 242.936 262.955 302.084 190 302.084Z"
            fill="#FFE717"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M228.515 167.315C228.614 169.182 226.266 171.054 221.383 172.66C193.915 181.707 175.497 199.677 166.632 227.399C164.931 232.171 163.396 234.467 161.821 234.444C160.245 234.467 158.71 232.171 157.01 227.399C148.145 199.677 129.726 181.707 102.259 172.66C97.3752 171.054 95.0284 169.182 95.1267 167.315C95.0285 165.447 97.3752 163.576 102.259 161.97C129.726 152.921 148.145 134.952 157.01 107.231C158.71 102.458 160.245 100.162 161.821 100.185C163.396 100.162 164.931 102.458 166.632 107.231C175.497 134.952 193.915 152.921 221.383 161.97C226.266 163.576 228.614 165.447 228.515 167.315Z"
            fill="#BC6F00"
          />
          <path
            d="M193.229 116.549C190.045 116.549 187.463 119.145 187.463 122.343C187.463 125.543 190.045 128.138 193.229 128.138H268.371C271.555 128.138 274.136 125.543 274.136 122.343C274.136 119.145 271.555 116.549 268.371 116.549H193.229ZM193.229 206.489C190.045 206.489 187.463 209.084 187.463 212.284C187.463 215.482 190.045 218.078 193.229 218.078H268.371C271.555 218.078 274.136 215.482 274.136 212.284C274.136 209.084 271.555 206.489 268.371 206.489H193.229Z"
            fill="#BC6F00"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M233.429 161.944C233.529 163.811 231.158 165.684 226.226 167.289C198.483 176.337 179.88 194.307 170.927 222.029C169.209 226.801 167.659 229.096 166.068 229.074C164.477 229.096 162.926 226.801 161.209 222.029C152.255 194.307 133.653 176.337 105.91 167.289C100.978 165.684 98.6076 163.811 98.7069 161.944C98.6076 160.077 100.978 158.205 105.91 156.599C133.653 147.551 152.255 129.581 161.209 101.86C162.926 97.0879 164.477 94.7916 166.068 94.8149C167.659 94.7916 169.209 97.0879 170.927 101.86C179.88 129.581 198.483 147.551 226.226 156.599C231.158 158.205 233.529 160.077 233.429 161.944Z"
            fill="#FFFACE"
          />
          <path
            d="M197.79 111.178C194.575 111.178 191.967 113.774 191.967 116.973C191.967 120.173 194.575 122.767 197.79 122.767H273.684C276.899 122.767 279.507 120.173 279.507 116.973C279.507 113.774 276.899 111.178 273.684 111.178H197.79ZM197.79 201.119C194.575 201.119 191.967 203.713 191.967 206.913C191.967 210.112 194.575 212.708 197.79 212.708H273.684C276.899 212.708 279.507 210.112 279.507 206.913C279.507 203.713 276.899 201.119 273.684 201.119H197.79Z"
            fill="#FFFACE"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_11139_2"
            x="-0.000244141"
            y="0"
            width="370"
            height="370"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dx="-5" dy="15" />
            <feGaussianBlur stdDeviation="20" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_11139_2"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_11139_2"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </div>
  );
};

export default CreditCoin;
