import React from "react";
interface CircleCrossIcon {
  color?: string;
}
const CircleCrossIcon: React.FC<CircleCrossIcon> = ({ color }) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0556 7.94444L7.94444 19.0556M7.94446 7.94444L19.0556 19.0556M26 13.5C26 20.4036 20.4036 26 13.5 26C6.59644 26 1 20.4036 1 13.5C1 6.59644 6.59644 1 13.5 1C20.4036 1 26 6.59644 26 13.5Z"
        stroke={color ? color : "black"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCrossIcon;
