const RechargeSlabIcon = ({ color }: any) => {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.3229 23.5C35.3489 23.9867 34.7275 24.4748 33.4344 24.8932C26.1608 27.2521 21.2831 31.9367 18.9359 39.1633C18.4853 40.4073 18.0789 41.0059 17.6617 41C17.2446 41.0059 16.8381 40.4073 16.3878 39.1633C14.0403 31.9367 9.1629 27.2521 1.88927 24.8932C0.596201 24.4748 -0.0254827 23.9867 0.000799318 23.5C-0.0254824 23.0133 0.596201 22.5252 1.88927 22.1065C9.16287 19.7479 14.0403 15.0633 16.3878 7.83672C16.8381 6.59265 17.2446 5.99413 17.6617 6.00004C18.0789 5.99413 18.4853 6.59265 18.9359 7.83672C21.2831 15.0633 26.1608 19.7479 33.4344 22.1065C34.7275 22.5252 35.3489 23.0133 35.3229 23.5Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.9997 6C41.0086 6.16687 40.7975 6.33423 40.3582 6.47768C37.8872 7.28642 36.2302 8.89257 35.4328 11.3703C35.2797 11.7968 35.1417 12.002 35 12C34.8582 12.002 34.7202 11.7968 34.5672 11.3703C33.7697 8.89257 32.1128 7.28642 29.6418 6.47768C29.2025 6.33423 28.9913 6.16687 29.0003 6C28.9913 5.83313 29.2025 5.66577 29.6418 5.52224C32.1128 4.71357 33.7697 3.10743 34.5672 0.629733C34.7202 0.203195 34.8582 -0.00201135 35 1.48567e-05C35.1417 -0.00201126 35.2797 0.203195 35.4328 0.629733C36.2302 3.10743 37.8872 4.71358 40.3582 5.52224C40.7975 5.66577 41.0086 5.83313 40.9997 6Z"
        fill={color}
      ></path>
    </svg>
  );
};

export default RechargeSlabIcon;
