import { useTranslation } from "react-i18next";
import SmartAssessLogo from "../../../../utils/Images/SmartAssessLogo";
import ResponseApplicantIcon from "../../../../utils/Images/ResponseApplicantIcon";
import { AssessmentCheckboxRadio } from "ui-components";
interface SingleMultiTypesProps {
  answerData: any;
}
type Option = any;
const SingleMultiTypes: React.FC<SingleMultiTypesProps> = ({ answerData }) => {
  const { t } = useTranslation();
  const { correctOptions, response } = answerData;
  // function that checks, correct answers against all the answer available in answerOptions
  const isCheckedCorrectAnswer = (option: Option): boolean => {
    if (correctOptions && Array.isArray(correctOptions)) {
      return correctOptions.some(
        (correctOption: Option) => correctOption.option === option.option,
      );
    }
    return false;
  };
  // function that checks, user given answers against all the answer available in answerOptions
  const isCheckedUserAnswer = (option: Option): boolean => {
    return (
      response &&
      Array.isArray(response.answer) &&
      response.answer.some(
        (userOption: Option) => userOption.option === option.option,
      )
    );
  };
  return (
    <div className="mt-5">
      <div className="singleTypeQuestion">
        <div className="optionHeader">
          <span>{t("RESPONSE_LIST.ANSWER")} :</span>
          <div className="answerHeader ">
            <div className="logoSection">
              <SmartAssessLogo
                color="var(--light-black)"
                width="15"
                height="28"
              />
              <span className="spn">{t("RESPONSE_LIST.CORRECT_ANSWER")}</span>
            </div>
            <div className="logoSection">
              <ResponseApplicantIcon
                color="var(--light-black)"
                width="16"
                height="30"
              />
              <span className="spn">{t("RESPONSE_LIST.APPLICANT_ANSWER")}</span>
            </div>
          </div>
        </div>
        <div className="answerTypeLogo">
          <span>
            {" "}
            <SmartAssessLogo
              color="var(--light-black)"
              width="15"
              height="28"
            />
          </span>
          <span>
            {" "}
            <ResponseApplicantIcon
              color="var(--light-black)"
              width="16"
              height="30"
            />
          </span>
        </div>
        {(answerData?.answerOptions ?? []).map((item: any, ind: number) => (
          <div
            className={`${item?.option ? "d-flex gap-2 common" : "commonType"} mt-2 disableContainer`}
            key={ind}
          >
            <AssessmentCheckboxRadio
              type="checkbox"
              checked={isCheckedCorrectAnswer(item)}
              additionalProps={{
                disabled: true,
              }}
            />
            <AssessmentCheckboxRadio
              type="checkbox"
              checked={isCheckedUserAnswer(item)}
              additionalProps={{
                disabled: true,
              }}
            />
            <div>
              {item?.option && <p>{item?.option}</p>}
              {item?.attachment && (
                <div className="ImageAttParent mb-2">
                  <img
                    className="attachedImageQuestion"
                    src={item?.attachment}
                    alt="Attachment"
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SingleMultiTypes;
